import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import type { RequestOptions } from '@algolia/transporter';
import { useTranslation } from 'react-i18next';

import FilterTabType from '@design-system/components/FiltersV1/FilterTabType';
import {
  DefaultOptionProps,
  FilterProps,
  QSFilterProps,
  SettingProps,
} from '@design-system/components/FiltersV1/FilterProps';
import formatNumber, { getCustomLabel, getFormattedString } from '@design-system/utils/utils';
import {
  fetchMakeModelOptions,
  fetchOptionsFromAlgolia,
  fillMissingMakeModelOptions,
  getPayloads,
  mapFilterFromQSToCurrentFilterSettings,
  parseRouterQueryFromAsPath,
  syncCarroExclusiveIntoFilters,
  syncFacetsToFilterSettings,
  useBrandModelUtil,
} from '@design-system/utils/filters';

import { buildAlgoliaFilter, buildAlgoliaFilterCarsForSale } from '@source/services/Algolia';
import useAlgoliaService from '@source/hooks/useAlgoliaService';
import FilterActiveBar from '@design-system/components/FiltersV1/FilterActiveBar';

import useBrowseServices from '@source/hooks/useBrowseServices';

import { CONTENT } from '@source/pages/Home/components/Filters/configs/constants';
import FILTER_CONFIGS from '@source/pages/Home/components/Filters/configs';
import FILTER_CONFIGS_SG from '@source/pages/Home/components/Filters/configs/sg';
import DataQuery from '@source/services/DataQuery';

const StyledFilters = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  button {
    border: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
    box-shadow: none;
    width: 50%;
  }
`;

interface FiltersMobileContainerProps extends PropsWithChildren {
  countryCode: string;
  language: string;
  onFilterSave: (params: RequestOptions, filterSettings?: SettingProps[], isFilterComponent?: boolean) => Promise<void>;
  onClearAllFilters: () => void;
}

const FiltersMobileContainer: FC<FiltersMobileContainerProps> = ({
  countryCode,
  language,
  children,
  onFilterSave,
  onClearAllFilters,
}: FiltersMobileContainerProps) => {
  const router = useRouter();
  const { fetchAlgoliaData } = useAlgoliaService();
  const [payloadsHit, setPayloadsHit] = useState(0);
  const [promoLabel, setPromoLabel] = useState(undefined);
  const { t } = useTranslation();

  const { browseState, setCurrentFilterSettings, setSyncUpUrlToFilter, setInitialFilterSettings } = useBrowseServices();
  const { currentFilterSettings, syncUpUrlToFilter, initialFilterSettings } = browseState;

  const { fetchMakesModels } = useBrandModelUtil();

  const currentFilterConfig = FILTER_CONFIGS[countryCode] || FILTER_CONFIGS_SG;

  const [content, setContent] = useState({
    ...CONTENT,
    useStyleCustomButtonMobile: true,
    showIcon: true,
    showActiveBar: true,
    ignoreMakeCount: true,
  });

  const { mobileFilters } = currentFilterConfig;

  useEffect(() => {
    (async () => {
      const { facets } = await fetchOptionsFromAlgolia(currentFilterSettings, currentFilterConfig, fetchAlgoliaData);
      if (facets.promotion_name) {
        setPromoLabel(facets.promotion_name);
      }
    })();
  }, []);

  useEffect(() => {
    setContent({
      ...content,
      showHitsCarsText: getFormattedString(t(content.showHitsCars), {
        total: payloadsHit.toString(),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadsHit, language]);

  useEffect(() => {
    if (isEmpty(initialFilterSettings)) {
      setInitialFilterSettings(currentFilterConfig?.initialFilterSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  useEffect(() => {
    (async () => {
      // Router is ready to use
      if (router.isReady && router.asPath && syncUpUrlToFilter) {
        // Parse router query from as path because I got the issue when use 'th' lang.
        // router.query object is empty but as path has value
        const parseRouterQuery = parseRouterQueryFromAsPath(router.asPath, router.query);

        const { data: carroExclusiveData } = await DataQuery.getCarroExclusiveFilters(countryCode, {
          locale: language,
        });

        // convert query string into first draft of filters
        const payloads = getPayloads(parseRouterQuery, parseRouterQuery.bodyType, countryCode);
        // map above into current filter setting
        let settings = mapFilterFromQSToCurrentFilterSettings(currentFilterSettings, payloads);

        let defaultModels;
        if (parseRouterQuery.brand && parseRouterQuery.model) {
          defaultModels = await fetchMakeModelOptions(parseRouterQuery.brand as string, fetchMakesModels);
        }
        // add some options from algolia into filters which need to be fetched
        const { newFilterSettings, facetStats, facets } = await fetchOptionsFromAlgolia(
          settings,
          currentFilterConfig,
          fetchAlgoliaData,
          defaultModels,
        );

        const newFilterSettingsWithCarroExclusives = syncCarroExclusiveIntoFilters(
          newFilterSettings,
          carroExclusiveData.data,
          countryCode,
        );

        settings = mapFilterFromQSToCurrentFilterSettings(newFilterSettingsWithCarroExclusives, payloads);

        // pass facets to init filter data too.
        const newInitialFilterSettings = syncFacetsToFilterSettings(
          isEmpty(initialFilterSettings) ? currentFilterConfig?.initialFilterSettings : initialFilterSettings,
          facets,
          facetStats,
        );

        const newInitialFilterSettingsWithCarroExclusives = syncCarroExclusiveIntoFilters(
          newInitialFilterSettings,
          carroExclusiveData.data,
          countryCode,
        );

        setInitialFilterSettings(newInitialFilterSettingsWithCarroExclusives);

        const payloadSettingList = payloads?.map((p: QSFilterProps) => p.settingName);

        let newSettings =
          payloads?.length > 0
            ? newInitialFilterSettings.map((initFilter) => {
                if (payloadSettingList?.includes(initFilter.name)) {
                  return settings.find((s) => s.name === initFilter.name) || initFilter;
                }

                return initFilter;
              })
            : newInitialFilterSettings;

        // add missing makeModelOptions if only make on router and no makeModelOptions declared
        newSettings = await fillMissingMakeModelOptions(newSettings, fetchMakesModels);

        setCurrentFilterSettings(newSettings);

        const parsedFilter = buildAlgoliaFilter(newSettings);

        // Todo_Son: Refactor
        const algoliaParams = {
          page: 0, // since algolia Page numbers are zero-based
          facets: ['*'],
          filters: parsedFilter,
          hitsPerPage: currentFilterConfig.hitsPerPage,
          attributesToRetrieve: currentFilterConfig.attributesToRetrieve,
        };

        setSyncUpUrlToFilter(false);
        onFilterSave(algoliaParams, newSettings, true);
      }
    })();
  }, [router.asPath, router.isReady, countryCode]);

  const newMobileFilters = useMemo(
    () => (promoLabel && mobileFilters ? ['promotions', ...mobileFilters] : mobileFilters),
    [promoLabel],
  );

  const mobileFilterSettings =
    useMemo(
      () =>
        newMobileFilters
          ?.map((m) => currentFilterSettings.find((f) => f.name === m && f.activeMobile) || {})
          .filter((i) => !isEmpty(i)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [newMobileFilters, currentFilterSettings],
    ) || [];

  // const initMobileFilterSettings = useMemo(
  //   () => mapSettingData(mobileFilters, initialFilterSettings, false),
  //   [initialFilterSettings, mobileFilters],
  // );

  const activeFilterSetting = useMemo(
    () =>
      mobileFilterSettings?.filter((setting: SettingProps) =>
        setting?.filters?.some(
          (item: FilterProps) => (item?.selectedOptions && item?.selectedOptions?.length) || item?.selectedBoolean,
        ),
      ),
    [mobileFilterSettings, language],
  );

  const { filterText } = content;

  const fetchPayloadHitFromAlgolia = async (params: RequestOptions) => {
    const filtersToGetCarsForSale = [params.filters, buildAlgoliaFilterCarsForSale()]
      .filter((i) => !isEmpty(i))
      .join(' AND ');

    const customParamsCarsForSale = {
      ...params,
      filters: filtersToGetCarsForSale,
    };

    const { nbHits } = await fetchAlgoliaData(customParamsCarsForSale);

    setPayloadsHit(nbHits);
  };

  // Todo: Need to refactor
  const handleOnFilterSave = async (
    payloads: Record<string, SettingProps>,
    isSaveAction: boolean,
    type: string,
    isFetchHitCount: boolean,
    isCloseActiveFilter = false,
  ) => {
    const isPopoverType = type === 'popover';
    let newCurrentFilterSettings = [...(currentFilterSettings || [])];
    Object.keys(payloads).forEach((settingName: string) => {
      const filtersMap = payloads[settingName]?.filters;
      const newFiltersMap = filtersMap?.map((payload: FilterProps) => {
        const newPayload = { ...payload, displayType: type };
        if (newPayload.type === 'range_input') {
          const { facetStats, selectedMinInput, selectedMaxInput } = payload;
          if ((selectedMinInput !== null || selectedMaxInput !== null) && facetStats) {
            newPayload.selectedMinInput = parseFloat(
              (selectedMinInput === null ? facetStats.min : selectedMinInput)?.toString() || '',
            );
            newPayload.selectedMaxInput = parseFloat(
              (selectedMaxInput === null ? facetStats.max : selectedMaxInput)?.toString() || '',
            );
          }
        }

        // Todo: Temp fix first. Gonna refactor
        if (newPayload.type === 'number') {
          const { selectedNumber, name, selectedCustomLabel } = payload;

          if (selectedNumber) {
            newPayload.selectedOptions = isSaveAction
              ? [
                  {
                    label: selectedNumber,
                    value: selectedNumber,
                    name,
                    customLabel: getCustomLabel(t(selectedCustomLabel || '') || '', newPayload),
                  },
                ]
              : null;
          }
        }

        if (newPayload.type === 'button' && newPayload?.selectedCustomLabel) {
          const { selectedCustomLabel, selectedOptions } = newPayload;
          newPayload.selectedOptions = selectedOptions?.map((newOpt: DefaultOptionProps) => ({
            ...newOpt,
            customLabel: getCustomLabel(t(selectedCustomLabel || ''), newOpt),
          }));
        }
        if (isPopoverType) {
          if (newPayload.type === 'range_input') {
            const {
              facetStats,
              selectedMinInput,
              selectedMaxInput,
              name,
              selectedCustomLabel,
              requireCommaFormat,
              displayMultiplier,
            } = payload;

            const newSelectedMinInput = parseFloat(
              (selectedMinInput === null ? facetStats?.min : selectedMinInput)?.toString() || '',
            );
            const newSelectedMaxInput = parseFloat(
              (selectedMaxInput === null ? facetStats?.max : selectedMaxInput)?.toString() || '',
            );

            if (selectedMinInput === null && selectedMaxInput === null && facetStats) {
              newPayload.selectedMinInput = isSaveAction ? newSelectedMinInput : null;
              newPayload.selectedMaxInput = isSaveAction ? newSelectedMaxInput : null;
            }
            newPayload.selectedOptions = isSaveAction
              ? [
                  {
                    name,
                    label: `${newSelectedMinInput} - ${newSelectedMaxInput}`,
                    customLabel: getCustomLabel(t(selectedCustomLabel || '') || '', {
                      ...newPayload,
                      selectedMinInput: formatNumber(newSelectedMinInput, requireCommaFormat, displayMultiplier),
                      selectedMaxInput: formatNumber(newSelectedMaxInput, requireCommaFormat, displayMultiplier),
                    }),
                  },
                ]
              : null;
          }
        }

        return { ...payload, ...newPayload };
      });

      newCurrentFilterSettings = newCurrentFilterSettings.map((setting: SettingProps) => {
        if (setting.name === settingName) {
          return { ...setting, filters: newFiltersMap };
        }

        return { ...setting };
      });
    });

    if (!isFetchHitCount) {
      setCurrentFilterSettings(newCurrentFilterSettings);
    }
    const parsedFilter = buildAlgoliaFilter(newCurrentFilterSettings);

    // Todo_Son: Refactor
    const algoliaParams = {
      page: 0, // since algolia Page numbers are zero-based
      facets: ['*'],
      filters: parsedFilter,
      hitsPerPage: currentFilterConfig.hitsPerPage,
      attributesToRetrieve: currentFilterConfig.attributesToRetrieve,
    };

    if (isFetchHitCount) {
      fetchPayloadHitFromAlgolia(algoliaParams);
    } else {
      onFilterSave(algoliaParams, newCurrentFilterSettings, true);
    }

    // if (!isSaveAction) {
    //   fetchOptionsFromAlgolia();
    // }
  };

  return (
    <>
      <StyledFilters>
        {mobileFilterSettings && (
          <FilterTabType
            isMobile
            content={content}
            settings={mobileFilterSettings}
            initSettings={initialFilterSettings}
            onFormSave={handleOnFilterSave}
          >
            {t(filterText)}
          </FilterTabType>
        )}
        {children}
      </StyledFilters>
      <FilterActiveBar
        content={content}
        settings={activeFilterSetting}
        onFormSave={handleOnFilterSave}
        initSettings={initialFilterSettings}
        onClearAllFilters={onClearAllFilters}
      />
    </>
  );
};

export default React.memo(FiltersMobileContainer);
