import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import useBreakPoint from '@design-system/hooks/useBreakPoint';

interface CustomButtonProps extends PropsWithChildren {
  type?: string;
  shape?: string;
  size?: string;
  onClick?: () => void;
  disabled?: boolean;
  responsive?: boolean;
  hasCustomBG?: boolean;
  customStyles?: string;
}

interface StyledButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  shape?: string;
  size?: string;
  hasCustomBG?: boolean;
  customStyles?: string;
}

// type - primary, active, clear
// shape - rectangle
// children - button text
// size - sm, md, lg
/*
    Custom Styles example:
    const GlobalStyles = css`
      fontFamily: ${props => props.theme.font};
      fontSize: ${props => props.theme.fontSizeSubtitle};
    `
*/

const StyledButton = styled.button<StyledButtonProps>`
  font-size: 12px;
  outline: none;

  border-radius: ${(props) => {
    switch (props.shape) {
      case 'rectangle':
        return '12px';
      case 'rectangle-no-border':
        return '8px';
      case 'round':
      default:
        return '60px';
    }
  }};
  cursor: pointer;
  padding: 12px 16px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &.less-awesome-primary {
    height: 42px;
    background-color: ${(props) => (props.hasCustomBG ? 'white !important' : '')};
  }

  &.less-awesome-primary,
  &.less-awesome-outline,
  &.less-awesome-primary-outline {
    background-color: ${(props) => props.theme.color.surface};
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis}; //#6e6e6e;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.regular};

    &:hover,
    &:active,
    &:focus {
      border: ${(props) => props.theme.color.onBackgroundLowEmphasis};
      color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
      transition: all 0.4s ease;
    }
  }

  &.less-awesome-primary-outline {
    border: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis} !important;
    background-color: ${(props) => props.theme.color.background};
  }

  &.less-awesome-inactive {
    opacity: 0.3;
    background-color: ${(props) => props.theme.color.surface};
    color: ${(props) => props.theme.color.onBackgroundLowEmphasis}; //#6e6e6e;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.regular}

    &:hover,
    &:active,
    &:focus {
      border: ${(props) => props.theme.color.onBackgroundLowEmphasis};
      color: black;
      transition: all 0.4s ease;
      opacity: 1;
    }
  }

  &.less-awesome-outline-active {
    border: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
    background-color: ${(props) => props.theme.color.background}; //#ffffff
  }

  &.less-awesome-primary-outline-active {
    color: ${(props) => props.theme.color.background}; //#ffffff
    border: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
    background: ${(props) => props.theme.color.primaryBase};
    ${(props) => props.theme.typo.familyGoogle.semiBold}
  }

  &.less-awesome-active {
    background: ${(props) => props.theme.color.background}; //#ffffff
    color: ${(props) => props.theme.color.primarySubtle};
    border: 1px;
    ${(props) => props.theme.typo.familyGoogle.semiBold}
  }

  &.less-awesome-clear {
    background-color: ${(props) => props.theme.color.background}; //#ffffff
    text-decoration: underline;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.semiBold}

    &:hover {
      background-color: ${(props) => props.theme.color.surface}; //#f0f0f0
      color: black;
      transition: all 0.4s ease;
    }
  }

  ${(props) => {
    switch (props.size) {
      case 'sm':
        return `
          font-size: 10px;
          padding: 10px 14px;
        `;
      case 'lg':
        return `
          font-size: 12px;
          padding: 14px 18px;
        `;
      default:
        return ``;
    }
  }}
`;

const CustomButton = ({
  type,
  disabled,
  customStyles,
  size,
  shape,
  onClick,
  children,
  responsive,
  hasCustomBG,
}: CustomButtonProps) => {
  const isDesktopOrSmaller = useBreakPoint('xxl');

  const getSize = () => {
    if (isDesktopOrSmaller) {
      return 'md';
    }

    return 'lg';
  };

  return (
    <StyledButton
      hasCustomBG={hasCustomBG}
      disabled={disabled}
      customStyles={customStyles}
      size={responsive ? getSize() : size}
      className={`less-awesome-${type}`}
      shape={shape}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

CustomButton.defaultProps = {
  type: 'primary',
  size: 'sm',
  shape: 'rectangle',
  customStyles: '',
  onClick: () => {},
  responsive: false,
  hasCustomBG: false,
  disabled: false,
};

export default CustomButton;
