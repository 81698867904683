/* eslint-disable react/default-props-match-prop-types */
import React, { FC } from 'react';

import type { ShowcaseItemProps } from '@source/pages/Home/components/HomeSearch/ShowcaseItemProps';
import styled from 'styled-components';

const StyledWrapper = styled.div<Partial<ShowcaseItemProps>>`
  align-items: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 0 20px;
  justify-content: space-between;
  font-size: 14px;
  ${(props) => props.theme.typo.familyGoogle.regular}

  .showcase-image-wrapper {
    align-items: center;
    display: flex;
    height: ${(props) => props.imageHeight};
    flex-shrink: 0;

    img {
      border-radius: 8px;
    }
  }

  &:hover {
    .showcase-title-wrapper span {
      color: ${(props) => props.theme.color.primaryHover};
    }
  }
`;

const ShowcaseItem: FC<ShowcaseItemProps> = ({ direction, image, title, imageHeight, onClick }) => (
  <StyledWrapper imageHeight={imageHeight} direction={direction} onClick={onClick}>
    <div className="showcase-image-wrapper">{image}</div>
    <div className="showcase-title-wrapper">{title}</div>
  </StyledWrapper>
);

ShowcaseItem.defaultProps = {
  direction: 'column',
  imageHeight: '100%',
};

export default ShowcaseItem;
