/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import qs from 'qs';

import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import unionBy from 'lodash/unionBy';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import upperCase from 'lodash/upperCase';
import omit from 'lodash/omit';

import useLocalStorage from 'use-local-storage';
import { useTranslation } from 'react-i18next';

import useMobileView from '@design-system/hooks/useMobileView';
import useAlgoliaService from '@source/hooks/useAlgoliaService';
import useRegionConfig from '@source/hooks/useRegionConfig';
import {
  convertToLabelValuePairArray,
  getUniqSearchHistories,
  isSearchable,
  removeDuplicateSelectedOptions,
} from '@source/pages/Home/utils';
import formatNumber, { deepCopy, getCustomLabel, getStaticCDN } from '@design-system/utils/utils';
import { buildAlgoliaFilter } from '@source/services/Algolia';
import useBrowseServices from '@source/hooks/useBrowseServices';
import { syncFacetsToFilterSettings } from '@design-system/utils/filters';
import useGTMEvent from '@source/hooks/useGTMEvent';

// COMPONENTS
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Image from 'next/image';
import { Dropdown, Button as AntButton, Spin, InputRef } from 'antd';
import { ArrowLeftOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import ShowcaseItem from '@source/pages/Home/components/HomeSearch/ShowcaseItem';
import HomeSearchHistoryItems from '@source/pages/Home/components/HomeSearch/HomeSearchHistoryItem';
import OptionsPanel from '@source/pages/Home/components/HomeSearch/OptionPanel';
import { Button, MobileDrawer, Typography } from '@design-system/components';
import { FilterTag } from '@source/pages/Home/components/FilterTag';
import DefaultCarImage from '@design-system/assets/car/car-default-image.png';
import FireIcon from '@design-system/assets/generalIcons/fire.svg';

// TYPES
import type { HomeSearchProps } from '@source/pages/Home/components/HomeSearch/HomeSearchProps';
import {
  FilterProps,
  SettingProps,
  QuickFilterProps,
  DefaultOptionProps,
} from '@design-system/components/FiltersV1/FilterProps';
import { Option } from '@source/interface/common';

// STYLES
import {
  StyledInput,
  StyledMobileDrawerContent,
  StyledMobileWrapper,
  StyledOverlay,
  StyledPopularSearch,
  StyledWrapper,
} from '@source/pages/Home/components/HomeSearch/style';
import 'swiper/css';
import 'swiper/css/navigation';

// CONSTANTS
import {
  bodyTypeList,
  brandIcons,
  FILTER_LIST_BY_COUNTRY,
  POPULAR_BRAND_LIST_BY_COUNTRY,
  POPULAR_SEARCH_LIST_BY_COUNTRY,
} from '@source/pages/Home/constants';
import { TRegionKey } from '@source/interface';
import { getBuyUrlNameByLocalLanguage, getRegionLanguageInRouter } from '@source/utils/utils';
import { URL_CONFIG } from '@source/constants/urlConfig';
import FILTER_CONFIGS from '../Filters/configs';
import FILTER_CONFIGS_SG from '../Filters/configs/sg';
import HomeSearchPopularCars from './HomeSearchPopularCars';

const PopularSearchItem: FC<{ children: ReactNode; onClick: () => void }> = ({ children, onClick }) => (
  <StyledPopularSearch onClick={onClick}>{children}</StyledPopularSearch>
);

const HomeSearch: FC<HomeSearchProps> = ({ currentSortBy, onSearch, onFilterSave, className, onClearAll }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { pushGTMEvent } = useGTMEvent();
  const { fetchAlgoliaData, getAutoCompleteListingTitle } = useAlgoliaService();
  const isBelowTabletView = useMobileView(992);
  const {
    countryConfig: { country },
  } = useRegionConfig();
  const { browseState, setCurrentFilterSettings, setSelectedSearchFilters, setCurrentQuery, setSyncUpUrlToFilter } =
    useBrowseServices();
  const { currentFilterSettings, currentQuery, selectedSearchFilters, currentFilterConfigs, initialFilterSettings } =
    browseState;

  const [visible, setVisible] = useState(false);
  const [openSearchDrawerMobile, setOpenSearchDrawerMobile] = useState(false);
  const [suggestions, setSuggestions] = useState<Option<string>[]>([]);
  const [recommendations, setRecommendations] = useState<Option<string>[]>([]);
  const [query, setQuery] = useState(currentQuery || '');
  const [isButtonActive, setButtonActive] = useState(!!currentQuery);
  const [loading, setLoading] = useState(false);
  const [searchHistories, setSearchHistories] = useLocalStorage<string[]>('user-search-history', []);
  const [searchedMakes, setSearchedMakes] = useState<string[]>([]);
  const [inputFocused, setInputFocused] = useState(false);

  const mobileSearchInputRef = useRef<InputRef>(null);

  const currentCountry = country || 'sg';
  const quickFilterList = useMemo(() => FILTER_LIST_BY_COUNTRY[currentCountry], [currentCountry]);
  const popularBrandList = useMemo(
    () =>
      POPULAR_BRAND_LIST_BY_COUNTRY?.[currentCountry]?.filter((make) => {
        const isAlreadySelected = selectedSearchFilters.make.includes(make.value);

        if (searchedMakes.length > 0) {
          return !isAlreadySelected && searchedMakes.includes(make.value.toUpperCase());
        }

        return !isAlreadySelected;
      }),
    [currentCountry, searchedMakes, selectedSearchFilters.make],
  );
  const popularSearches = useMemo(
    () =>
      POPULAR_SEARCH_LIST_BY_COUNTRY?.[currentCountry]?.filter(
        (search) => !selectedSearchFilters.make_model.includes(search),
      ),
    [currentCountry, selectedSearchFilters.make_model],
  );

  const initialBodyTypeList = useMemo(() => {
    const bodyButtonSetting = initialFilterSettings.find((config) => config.name === 'body_button');
    const bodyButtonFilter = bodyButtonSetting?.filters as { options: Option<string>[] }[];
    const bodyButtonOptions = bodyButtonFilter?.[0].options || [];

    return bodyButtonOptions.map((option) => bodyTypeList.find((hOption) => hOption.value === option.value) || option);
  }, [initialFilterSettings]);

  const filteredBodyTypeList = useMemo(
    () =>
      initialBodyTypeList.filter(
        (bodyType) =>
          !selectedSearchFilters.body_type.find((selectedBodyType) => selectedBodyType.value === bodyType.value),
      ),
    [selectedSearchFilters.body_type, initialBodyTypeList],
  );
  const suggestionList = useMemo(
    () => removeDuplicateSelectedOptions(suggestions, selectedSearchFilters.make_model),
    [selectedSearchFilters.make_model, suggestions],
  );

  const defaultCategories = useMemo(
    () =>
      (FILTER_CONFIGS?.[country || 'sg'] || FILTER_CONFIGS_SG).initialFilterSettings.find(
        (config) => config.name === 'category',
      )?.filters?.[0].options,
    [country],
  );

  const recommendationList = useMemo(
    () => removeDuplicateSelectedOptions(recommendations, selectedSearchFilters.category),
    [recommendations, selectedSearchFilters.category],
  );

  const { region, language } = getRegionLanguageInRouter(router, currentCountry as TRegionKey);

  const handleOnFilterSave = useCallback(
    async (payloads: any, isSaveAction: boolean, type: string, isFetchHitCount: boolean) => {
      const isPopoverType = type === 'popover';
      let newCurrentFilterSettings = [...(currentFilterSettings || [])];
      Object.keys(payloads)
        .filter((i) => i)
        .forEach((settingName: string) => {
          const filtersMap = payloads[settingName]?.filters;
          const newFiltersMap = filtersMap.map((payload: FilterProps) => {
            const newPayload = { ...payload, displayType: type };
            if (newPayload.type === 'range_input') {
              const { facetStats, selectedMinInput, selectedMaxInput } = payload;
              if ((selectedMinInput !== null || selectedMaxInput !== null) && facetStats) {
                newPayload.selectedMinInput = parseFloat(
                  (selectedMinInput === null ? facetStats.min : selectedMinInput)?.toString() || '',
                );
                newPayload.selectedMaxInput = parseFloat(
                  (selectedMaxInput === null ? facetStats.max : selectedMaxInput)?.toString() || '',
                );
              }
            }
            if (isPopoverType) {
              if (newPayload.type === 'number') {
                const { defaultValue, selectedNumber, name, selectedCustomLabel } = payload;
                if (defaultValue && selectedNumber === null) {
                  newPayload.selectedNumber = isSaveAction ? defaultValue : null;
                }
                newPayload.selectedOptions = isSaveAction
                  ? [
                      {
                        label: defaultValue,
                        value: defaultValue,
                        name,
                        customLabel: getCustomLabel(t(selectedCustomLabel as string) || '', newPayload),
                      },
                    ]
                  : null;
              }
              if (newPayload.type === 'range_input') {
                const {
                  facetStats,
                  selectedMinInput,
                  selectedMaxInput,
                  name,
                  selectedCustomLabel,
                  requireCommaFormat,
                  displayMultiplier,
                } = payload;

                const newSelectedMinInput = parseFloat(
                  (selectedMinInput === null ? facetStats?.min : selectedMinInput)?.toString() || '',
                );
                const newSelectedMaxInput = parseFloat(
                  (selectedMaxInput === null ? facetStats?.max : selectedMaxInput)?.toString() || '',
                );

                if (selectedMinInput === null && selectedMaxInput === null && facetStats) {
                  newPayload.selectedMinInput = isSaveAction ? newSelectedMinInput : null;
                  newPayload.selectedMaxInput = isSaveAction ? newSelectedMaxInput : null;
                }
                newPayload.selectedOptions = isSaveAction
                  ? [
                      {
                        name,
                        label: `${newSelectedMinInput} - ${newSelectedMaxInput}`,
                        customLabel: getCustomLabel(t(selectedCustomLabel as string) || '', {
                          ...newPayload,
                          selectedMinInput: formatNumber(newSelectedMinInput, requireCommaFormat, displayMultiplier),
                          selectedMaxInput: formatNumber(newSelectedMaxInput, requireCommaFormat, displayMultiplier),
                        }),
                      },
                    ]
                  : null;
              }
            }

            return { ...payload, ...newPayload };
          });

          newCurrentFilterSettings = newCurrentFilterSettings.map((setting: SettingProps) => {
            if (setting.name === settingName) {
              return { ...setting, filters: newFiltersMap };
            }

            return { ...setting };
          });
        });

      // Todo_Son: temp fix:
      if (!isSaveAction) {
        const params = {
          page: 0, // since algolia Page numbers are zero-based
          facets: ['*'],
          filters: '',
          hitsPerPage: currentFilterConfigs.hitsPerPage,
          attributesToRetrieve: currentFilterConfigs.attributesToRetrieve,
        };

        // eslint-disable-next-line camelcase
        const { facets = {}, facets_stats = {} } = await fetchAlgoliaData(params);
        newCurrentFilterSettings = syncFacetsToFilterSettings(
          newCurrentFilterSettings,
          facets || {},
          // eslint-disable-next-line camelcase
          facets_stats || {},
        );
      }

      if (!isFetchHitCount) {
        setCurrentFilterSettings(newCurrentFilterSettings);
      }

      const parsedFilter = buildAlgoliaFilter(newCurrentFilterSettings);

      // Todo_Son: Refactor
      const algoliaParams = {
        page: 0, // since algolia Page numbers are zero-based
        facets: ['*'],
        filters: parsedFilter,
        hitsPerPage: currentFilterConfigs.hitsPerPage,
        attributesToRetrieve: currentFilterConfigs.attributesToRetrieve,
      };

      onFilterSave(algoliaParams, newCurrentFilterSettings);
    },
    [
      currentFilterConfigs.attributesToRetrieve,
      currentFilterConfigs.hitsPerPage,
      currentFilterSettings,
      fetchAlgoliaData,
      onFilterSave,
      setCurrentFilterSettings,
      t,
    ],
  );

  const resetStates = useCallback(() => {
    setQuery('');
    setSuggestions([]);
    setRecommendations([]);
    setSearchedMakes([]);
    setLoading(false);
  }, []);

  const triggerSearch = useRef(
    debounce(async (newQuery: string, sortBy) => {
      if (!newQuery) {
        resetStates();
      }

      if (!isSearchable(newQuery)) {
        setSearchedMakes([]);
        setLoading(false);
        return;
      }

      const results = await getAutoCompleteListingTitle(newQuery, sortBy, 5, [
        'inventory.make',
        'inventory.make_model',
        'inventory.car_type',
        'categories',
      ]);

      if (results) {
        setSuggestions(convertToLabelValuePairArray((results['inventory.make_model'] as string[]) || []));

        setRecommendations(
          convertToLabelValuePairArray((results.categories as string[]) || [], defaultCategories as Option<string>[]),
        );

        setSearchedMakes(((results['inventory.make'] as string[]) || []).map((item) => item.toUpperCase()));
      }

      setLoading(false);
    }, 500),
  );

  useEffect(() => {
    if (!inputFocused) {
      return;
    }
    const originalMakeModelSetting = currentFilterSettings?.find((f) => f.name === 'make_model');
    const originalMakeSetting = currentFilterSettings.find((f) => f.name === 'make');
    const originalBodyTypeSetting = currentFilterSettings.find((f) => f.name === 'body_button');

    const originalMakeModeFilter = originalMakeModelSetting?.filters?.[0] || {};
    const originalMakeFilter = originalMakeSetting?.filters?.[0] || {};
    const originalBodyTypeFilter = originalBodyTypeSetting?.filters?.[0] || {};

    const originalMakeModelSelectedOptions = originalMakeModeFilter?.selectedOptions;
    const originalMakeSelectedOptions = originalMakeFilter?.selectedOptions;
    const originalBodyTypeSelectedOptions = originalBodyTypeFilter?.selectedOptions;

    const selectedSearchOptions = {
      make_model: selectedSearchFilters.make_model.map((item) => {
        const words = item.split(' ');

        return {
          customLabel: item,
          label: item,
          make: words[0],
          value: item,
        };
      }),
      make: selectedSearchFilters.make.map((item) => ({
        customLabel: item,
        label: item,
        make: item,
        value: item,
      })),

      body_button: selectedSearchFilters.body_type.map((item) => ({
        label: item.label,
        customLabel: item.label,
        value: item.value,
      })),
    };

    const newMakeModelSelectedOptions =
      !isEmpty(originalMakeModelSelectedOptions) || !isEmpty(selectedSearchOptions.make_model)
        ? unionBy(originalMakeModelSelectedOptions, selectedSearchOptions.make_model, 'value')
        : null;

    const newMakeSelectedOptions =
      !isEmpty(originalMakeSelectedOptions) || !isEmpty(selectedSearchOptions.make)
        ? unionBy(originalMakeSelectedOptions, selectedSearchOptions.make, 'value')
        : null;

    const newBodyTypeSelectedOptions =
      !isEmpty(originalBodyTypeSelectedOptions) || !isEmpty(selectedSearchOptions.body_button)
        ? unionBy(originalBodyTypeSelectedOptions, selectedSearchOptions.body_button, 'value')
        : null;

    const makeModelPayload = {
      make_model: {
        ...originalMakeModelSetting,
        filters: [
          {
            ...originalMakeModeFilter,
            displayType: 'popover',
            type: 'make_model',
            selectedOptions: newMakeModelSelectedOptions,

            filterSelectedHeader: '[label]',
            name: 'inventory.make_model',
          },
        ],
        name: 'make_model',
      },
      make: {
        ...originalMakeFilter,
        filters: [
          {
            ...originalMakeFilter,
            displayType: '',
            type: 'button',
            filterSelectedHeader: '[label]',
            name: 'inventory.make',
            selectedOptions: newMakeSelectedOptions,
          },
        ],
        name: 'make',
      },
      body_button: {
        ...originalBodyTypeFilter,
        name: 'button',
        filters: [
          {
            ...originalBodyTypeFilter,
            filterSelectedHeader: '[label]',
            displayType: 'tabs',
            isNumeric: false,
            selectedOptions: newBodyTypeSelectedOptions,
          },
        ],
      },
    };

    handleOnFilterSave(makeModelPayload, true, 'popover', false);
    // Todo: @duong help me check later this dependencies later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFocused, selectedSearchFilters.body_type, selectedSearchFilters.make, selectedSearchFilters.make_model]);

  useEffect(() => {
    setQuery(currentQuery);
    setButtonActive(true);
  }, [currentQuery]);

  useEffect(() => {
    triggerSearch.current(currentQuery, currentSortBy);
  }, []);

  useEffect(() => {
    if (!openSearchDrawerMobile || !mobileSearchInputRef.current) return;
    setTimeout(() => {
      mobileSearchInputRef.current?.focus();
    }, 500);
  }, [mobileSearchInputRef, openSearchDrawerMobile]);

  const handleTriggerQueryClick = useCallback(
    (value: string) => {
      setVisible(false);
      setOpenSearchDrawerMobile(false);
      setQuery(value);
      onSearch(value, true);
      if (!isEmpty(value)) {
        setTimeout(() => setSearchHistories(uniq([...(searchHistories || []), value])), 500);
      }
    },

    [onSearch, searchHistories, setSearchHistories],
  );

  const handleQueryChange = useCallback(
    (newValue: string) => {
      setQuery(newValue);
      setLoading(true);
      setButtonActive(newValue.length > 0);

      triggerSearch.current(newValue, currentSortBy);
    },
    [currentSortBy],
  );

  const handleSubmitSearch = useCallback(() => {
    setVisible(false);
    onSearch(query);
    if (!isEmpty(query)) {
      setTimeout(() => setSearchHistories(uniq([...(searchHistories || []), query])), 500);
    }
  }, [onSearch, query, searchHistories, setSearchHistories]);

  const handleMobileSubmitSearch = useCallback(() => {
    setOpenSearchDrawerMobile(false);
    onSearch(query);
    if (!isEmpty(query)) {
      setTimeout(() => setSearchHistories(uniq([...(searchHistories || []), query])), 500);
    }
  }, [onSearch, query, searchHistories, setSearchHistories]);

  const handleFilterClick = useCallback(
    (type: 'make_model' | 'body_type' | 'make', value: any) => {
      setVisible(false);
      setOpenSearchDrawerMobile(false);
      setSyncUpUrlToFilter(true);
      setSelectedSearchFilters({
        ...selectedSearchFilters,
        [type]: [...selectedSearchFilters[type], value],
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSearchFilters, setSelectedSearchFilters],
  );

  const handleIconClick = useCallback((value: string) => {
    setQuery(value);
  }, []);

  const handleQuickFilterClick = useCallback(
    (url: string) => {
      if (url) {
        setSyncUpUrlToFilter(true);

        const parseOptions = {
          plainObjects: false,
          allowPrototypes: false,
          ignoreQueryPrefix: true,
          parseArrays: false,
          depth: 0,
        };
        const qsObject = qs.parse(url, parseOptions);
        let newRouterQuery = { ...router.query, ...qsObject };
        newRouterQuery = omit(newRouterQuery, 'lang');

        const configUrl =
          newRouterQuery && !isEmpty(newRouterQuery)
            ? URL_CONFIG({
                region: region as TRegionKey,
                lang: language,
                query: qs.stringify(newRouterQuery, { arrayFormat: 'comma', encode: false }),
                path: `${getBuyUrlNameByLocalLanguage(currentCountry, language)}`,
              }).formatted
            : `${window.location.pathname}`;

        router.push(configUrl, configUrl, { shallow: true });

        if (isBelowTabletView) {
          setOpenSearchDrawerMobile(false);
        } else {
          setVisible(false);
        }
      }
    },
    [isBelowTabletView, router],
  );

  const handleOnMouseLeave = useCallback(() => {
    setVisible(false);
  }, []);

  const handleFilterTagClick = useCallback(
    (filter: { origin: string; value: string | Option<string> }) => {
      const newSelectedSearchFilter = get(selectedSearchFilters, filter.origin).filter(
        (prevValue: any) => prevValue !== filter.value,
      );
      setSelectedSearchFilters({
        ...selectedSearchFilters,
        [filter.origin]: newSelectedSearchFilter,
      });

      if (filter.origin === 'body_type') {
        const oldBodyFilter = currentFilterSettings.find((oldFilter: SettingProps) => oldFilter.name === 'body_button');
        const newBodyFilter = deepCopy(oldBodyFilter);
        const valueToCompare = filter.value as Option<string>;

        if (newBodyFilter?.filters?.[0]?.selectedOptions) {
          newBodyFilter.filters[0].selectedOptions = newBodyFilter.filters[0].selectedOptions.filter(
            (option: { value: string }) => option.value !== valueToCompare.value,
          );
        }

        setCurrentFilterSettings(
          currentFilterSettings.map((filterGroup) =>
            filterGroup.name === 'body_button' ? newBodyFilter : filterGroup,
          ),
        );
      } else if (filter.origin === 'category') {
        const oldBodyFilter = currentFilterSettings.find((oldFilter: SettingProps) => oldFilter.name === 'category');
        const newBodyFilter = deepCopy(oldBodyFilter);
        const valueToCompare = filter.value as Option<string>;

        if (newBodyFilter?.filters?.[0]?.selectedOptions) {
          newBodyFilter.filters[0].selectedOptions = newBodyFilter.filters[0].selectedOptions.filter(
            (option: { value: string }) => option.value !== valueToCompare.value,
          );
        }

        setCurrentFilterSettings(
          currentFilterSettings.map((filterGroup) => (filterGroup.name === 'category' ? newBodyFilter : filterGroup)),
        );
      } else if (filter.origin === 'make') {
        // if de-select a make from selected filter
        // remove current make in current make settings
        // remove all related make_models relate to the make from make_model settings

        const newFilterSettings = deepCopy(currentFilterSettings);
        const makeSettings = deepCopy(currentFilterSettings.find((s: SettingProps) => s.name === 'make'));
        const makeSettingsIndex = currentFilterSettings.findIndex((s: SettingProps) => s.name === 'make');
        const makeModelSettings = deepCopy(currentFilterSettings.find((s: SettingProps) => s.name === 'make_model'));
        const makeModelSettingsIndex = currentFilterSettings.findIndex((s: SettingProps) => s.name === 'make_model');
        const makeOptions = get(makeSettings, 'filters.[0].selectedOptions') as DefaultOptionProps[];
        const makeModelOptions = get(makeModelSettings, 'filters.[0].selectedOptions') as DefaultOptionProps[];

        if (makeOptions && makeOptions.length > 0) {
          const newMakeOptions = makeOptions.filter((o) => o.value !== filter.value);
          const newMakeModelOptions = makeModelOptions.filter(
            (o) => upperCase(`${o.value}`).indexOf(`${filter.value}`) !== 0, // Filter make_model don't start with current make
          );
          set(newFilterSettings, `[${makeSettingsIndex}].filters.[0].selectedOptions`, newMakeOptions);
          set(newFilterSettings, `[${makeModelSettingsIndex}].filters.[0].selectedOptions`, newMakeModelOptions);
        }

        setCurrentFilterSettings(newFilterSettings);
      } else {
        const oldBodyFilter = currentFilterSettings.find((oldFilter) => oldFilter.name === filter.origin);
        const newFilter = deepCopy(oldBodyFilter);
        if (newFilter?.filters?.[0]?.selectedOptions) {
          newFilter.filters[0].selectedOptions = newFilter.filters[0].selectedOptions.filter(
            (option: { value: string }) => option.value !== filter.value,
          );
        }

        setCurrentFilterSettings(
          currentFilterSettings.map((filterGroup) => (filterGroup.name === filter.origin ? newFilter : filterGroup)),
        );
      }
    },

    [currentFilterSettings, selectedSearchFilters, setCurrentFilterSettings, setSelectedSearchFilters],
  );

  const handleRecommendationClick = (value: Option<string>) => {
    const newFilterSetting: SettingProps[] = deepCopy(currentFilterSettings);
    const categoryFilter = newFilterSetting.find((setting) => setting.name === 'category');

    if (categoryFilter?.filters?.[0]) {
      if (categoryFilter.filters[0].selectedOptions) {
        categoryFilter.filters[0].selectedOptions = [...categoryFilter.filters[0].selectedOptions, value];
      } else {
        categoryFilter.filters[0].selectedOptions = [value];
      }
    }

    setSelectedSearchFilters({ ...selectedSearchFilters, category: [...selectedSearchFilters.category, value] });
    setCurrentFilterSettings(newFilterSetting);
  };

  const handleClearAll = useCallback(() => {
    onSearch('');
    resetStates();
    setCurrentQuery('');
    setSelectedSearchFilters({ make: [], make_model: [], body_type: [], category: [] });
    setCurrentFilterSettings((FILTER_CONFIGS?.[country || 'sg'] || FILTER_CONFIGS_SG).initialFilterSettings);
    if (onClearAll) onClearAll();
  }, [country, onSearch, resetStates, setCurrentFilterSettings, setCurrentQuery, setSelectedSearchFilters]);

  const filterTagsInSearchBar = useMemo(() => {
    const allBodyTypeFilters = selectedSearchFilters.body_type.map((filter) => ({
      origin: 'body_type',
      value: filter,
    }));
    const allBrandFilter = selectedSearchFilters.make.map((filter) => ({
      origin: 'make',
      value: filter,
    }));
    const allMakeModelFilter = selectedSearchFilters.make_model.map((filter) => ({
      origin: 'make_model',
      value: filter,
    }));
    const allCategoryFilter = selectedSearchFilters.category.map((filter) => ({
      origin: 'category',
      value: filter,
    }));

    return [...allBodyTypeFilters, ...allBrandFilter, ...allMakeModelFilter, ...allCategoryFilter];
  }, [
    selectedSearchFilters.body_type,
    selectedSearchFilters.category,
    selectedSearchFilters.make,
    selectedSearchFilters.make_model,
  ]);

  const renderFilterTagLabel = (filter: { origin: string; value: string | Option<string> }) => {
    if (typeof filter.value === 'string') {
      return filter.value;
    }

    if (filter.origin === 'category' || filter.origin === 'body_type') {
      return t(filter.value.label);
    }

    return filter.value.label;
  };

  const renderFilterTag = () =>
    filterTagsInSearchBar.map((filter) => (
      <FilterTag
        key={typeof filter.value === 'string' ? filter.value : filter.value.value}
        label={renderFilterTagLabel(filter)}
        onClick={() => handleFilterTagClick(filter)}
      />
    ));

  const renderSearchStatus = () => {
    if (loading && isSearchable(query)) {
      return (
        <div style={{ marginLeft: '4px' }}>
          <Spin spinning={loading} />
        </div>
      );
    }

    if (suggestions.length === 0 && recommendations.length === 0 && isSearchable(query)) {
      return <Typography>{t('home.search.noResultsFound')}</Typography>;
    }

    return null;
  };

  const renderDropdownOverlay = () => {
    if (!inputFocused) {
      return <div />;
    }
    if (['jp', 'hk'].includes(currentCountry)) {
      if (isEmpty(searchHistories) && isEmpty(suggestionList) && isEmpty(recommendationList)) {
        return <div />;
      }

      return (
        <StyledOverlay isMobileView={isBelowTabletView}>
          {!isEmpty(searchHistories) && !isSearchable(query) && (
            <div className="search-overlay-section">
              <HomeSearchHistoryItems
                numberOfShownItems={10}
                header={t('home.search.searchHistory')}
                onClear={() => setSearchHistories([])}
                options={searchHistories || []}
                onSelected={handleTriggerQueryClick}
              />
            </div>
          )}

          {renderSearchStatus()}

          {!isEmpty(suggestionList) && isSearchable(query) && (
            <OptionsPanel
              suggestions={suggestionList}
              onSelected={handleTriggerQueryClick}
              onIconClick={handleIconClick}
            />
          )}

          {!isEmpty(recommendationList) && isSearchable(query) && (
            <div className="search-overlay-section">
              <div className="search-overlay-title">{t('home.search.recommendations')}</div>
              <div className="popular-search-item-wrapper">
                {recommendationList.map((recommendation) => (
                  <PopularSearchItem
                    key={recommendation.value}
                    onClick={() => handleRecommendationClick(recommendation)}
                  >
                    {t(recommendation.label)}
                  </PopularSearchItem>
                ))}
              </div>
            </div>
          )}
        </StyledOverlay>
      );
    }

    return (
      <StyledOverlay isMobileView={isBelowTabletView}>
        {!isEmpty(searchHistories) && !isSearchable(query) && (
          <div className="search-overlay-section">
            <HomeSearchHistoryItems
              numberOfShownItems={10}
              header={t('home.search.searchHistory')}
              onClear={() => setSearchHistories([])}
              options={searchHistories || []}
              onSelected={handleTriggerQueryClick}
            />
          </div>
        )}

        {renderSearchStatus()}

        {!isEmpty(suggestionList) && isSearchable(query) && (
          <OptionsPanel
            suggestions={suggestionList}
            onSelected={handleTriggerQueryClick}
            onIconClick={handleIconClick}
          />
        )}

        {!isSearchable(query) && (
          <div className="search-overlay-section">
            <div className="search-overlay-title">{t('home.search.quickFilter')}</div>
            <div className="search-overlay-filter-wrapper">
              <Swiper
                modules={[Navigation]}
                navigation={!isBelowTabletView}
                slidesPerGroupAuto
                slidesPerView="auto"
                spaceBetween={16}
              >
                {quickFilterList?.map(({ label, url }: QuickFilterProps) => (
                  <SwiperSlide key={label}>
                    <Button key={label} variant="outline" onClick={() => handleQuickFilterClick(url || '')}>
                      {t(label || '')}
                    </Button>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}

        {!isEmpty(popularBrandList) && (
          <div className="search-overlay-section">
            <div className="search-overlay-title">{t('home.search.popularBrands')}</div>
            <Swiper
              modules={[Navigation]}
              navigation={!isBelowTabletView}
              slidesPerGroupAuto
              slidesPerView="auto"
              spaceBetween={50}
            >
              {popularBrandList.map((make) => (
                <SwiperSlide key={make.value}>
                  <ShowcaseItem
                    imageHeight="40px"
                    image={<Image layout="intrinsic" src={brandIcons[make.label]} />}
                    title={<Typography>{make.label}</Typography>}
                    onClick={() => handleFilterClick('make', make.value)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {!isEmpty(recommendationList) && isSearchable(query) && (
          <div className="search-overlay-section">
            <div className="search-overlay-title">{t('home.search.recommendations')}</div>
            <div className="popular-search-item-wrapper">
              {recommendationList.map((recommendation) => (
                <PopularSearchItem key={recommendation.value} onClick={() => handleRecommendationClick(recommendation)}>
                  {t(recommendation.label)}
                </PopularSearchItem>
              ))}
            </div>
          </div>
        )}

        {!isSearchable(query) && filteredBodyTypeList.length > 0 && (
          <>
            <div className="search-overlay-section">
              <div className="search-overlay-title">{t('home.search.bodyTypes')}</div>
              <Swiper
                modules={[Navigation]}
                navigation={!isBelowTabletView}
                slidesPerGroupAuto
                slidesPerView="auto"
                spaceBetween={15}
              >
                {filteredBodyTypeList.map((bodyType) => (
                  <SwiperSlide key={String(bodyType.value)}>
                    <ShowcaseItem
                      image={
                        <Image
                          width={98}
                          height={70}
                          src={getStaticCDN(`/assets/shared/body-type-cars/${bodyType.value}.svg`)}
                        />
                      }
                      title={<Typography variant="captionRegular">{t(bodyType.label)}</Typography>}
                      onClick={() => handleFilterClick('body_type', bodyType)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="search-overlay-section">
              <div className="search-overlay-title popular-search">
                <Image src={FireIcon} /> {t('home.search.popularSearches')}
              </div>

              <div className="popular-search-item-wrapper">
                {popularSearches?.map((popularSearch) => (
                  <PopularSearchItem key={popularSearch} onClick={() => handleFilterClick('make_model', popularSearch)}>
                    {popularSearch}
                  </PopularSearchItem>
                ))}
              </div>
            </div>

            <HomeSearchPopularCars countryCode={country as TRegionKey} />
          </>
        )}
      </StyledOverlay>
    );
  };

  const mobileDrawerContent = (
    <StyledMobileDrawerContent>
      <Button className="back-to-home" variant="text" onClick={() => setOpenSearchDrawerMobile(false)}>
        <ArrowLeftOutlined /> {t('home.search.backToHome')}
      </Button>

      <div className="mobile-search-bar-wrapper">
        <StyledInput
          ref={mobileSearchInputRef}
          className={className}
          placeholder={t('home.search.findYourDreamCar')}
          value={query}
          onChange={(e) => handleQueryChange(e.target.value)}
          onPressEnter={handleMobileSubmitSearch}
          onClick={() => setOpenSearchDrawerMobile(true)}
        />

        <AntButton className="mobile-search-bar-button" onClick={handleMobileSubmitSearch} disabled={!isButtonActive}>
          <SearchOutlined />
        </AntButton>
      </div>

      {filterTagsInSearchBar.length > 0 && (
        <>
          <div className="clear-all">
            <Typography variant="captionRegular">{t('home.search.clearTheActiveSearches')}</Typography>
            <Button onClick={handleClearAll} variant="text">
              {t('home.search.clearAllResults')}
            </Button>
          </div>

          <div className="mobile-filter-tag-container">{renderFilterTag()}</div>
        </>
      )}

      {renderDropdownOverlay()}
    </StyledMobileDrawerContent>
  );

  if (isBelowTabletView) {
    return (
      <StyledMobileWrapper>
        <StyledInput
          className={className}
          placeholder={t('home.search.findYourDreamCar')}
          prefix={<SearchOutlined />}
          value={query}
          suffix={query && <CloseCircleOutlined onClick={handleClearAll} />}
          onChange={(e) => handleQueryChange(e.target.value)}
          onPressEnter={handleSubmitSearch}
          onClick={() => setOpenSearchDrawerMobile(true)}
          onFocus={() => {
            if (!inputFocused) {
              setInputFocused(true);
            }
          }}
        />
        <MobileDrawer
          height="100%"
          visible={openSearchDrawerMobile}
          customFooter={() => null}
          customHeader={() => null}
          onClose={() => setOpenSearchDrawerMobile(false)}
        >
          {openSearchDrawerMobile ? mobileDrawerContent : null}
        </MobileDrawer>
      </StyledMobileWrapper>
    );
  }

  return (
    <StyledWrapper onMouseLeave={handleOnMouseLeave} isSearchWindowOpen={visible}>
      <Dropdown
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        overlay={renderDropdownOverlay()}
        onOpenChange={setVisible}
        open={visible}
        trigger={['click']}
      >
        <StyledInput
          onFocus={() => {
            if (!inputFocused) {
              setInputFocused(true);
            }
          }}
          className={className}
          placeholder={t('home.search.whatCarAreYouSearchingFor')}
          prefix={
            <>
              <SearchOutlined />

              {visible && renderFilterTag()}
            </>
          }
          value={query}
          suffix={
            query.length > 0 && (
              <div
                className="clear-all-btn-in-search"
                role="presentation"
                onClick={handleClearAll}
                onKeyDown={handleClearAll}
              >
                {t('home.search.clearAllResults')}
              </div>
            )
          }
          onChange={(e) => handleQueryChange(e.target.value)}
          onPressEnter={handleSubmitSearch}
        />
      </Dropdown>
    </StyledWrapper>
  );
};

export default React.memo(HomeSearch);
