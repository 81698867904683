import { FC } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { Modal } from 'antd';

import { THEME } from '@source/constants/theme';
import { BannerJSONStyle, BannerJsonModal } from '@source/pages/Home/components/BannersCarousel/types';
import { StringToJSX } from '@source/pages/Home/components/BannersCarousel/StringToJSX';

const StyleBannerModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    width: 343px;
    height: 600px;
    padding: 16px 12px;
    padding-right: 0;
  }

  .ant-modal-close {
    top: 16px;
    right: 18px;
  }

  .ant-modal-close-x {
    width: 16px;
    line-height: 16px;
  }

  @media screen and (min-width: 576px) {
    .ant-modal-body {
      width: 385px;
    }
  }

  @media screen and (min-width: 768px) {
    .ant-modal-content {
      top: 100px;
      margin-bottom: 132px;
    }
  }

  .ant-modal-footer {
    .ant-btn-primary {
      background: ${(props) => props.theme.color.primaryBase};
      border-color: ${(props) => props.theme.color.primaryBase};
    }
  }
`;

const StyledBannerModalContent = styled.div`
  ${(props) => props.theme.typo.familyGoogle.regular};
  ${(props: { customStyle: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}

  height: 555px;
  overflow: auto;
  margin-top: 22px;
  padding-right: 5px;

  & div:nth-child(2) {
    & div:nth-child(1) {
      margin: 24px 0px 8px 0px !important;
      font-size: 16px;
      ${(props) => props.theme.typo.familyGoogle.bold};
    }

    ol {
      padding-left: 20px;
      ${(props) => props.theme.typo.familyGoogle.regular};
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

const StyledBannerModalContentImage = styled.img`
  height: auto !important;
  max-width: 100% !important;
`;

export const transformStyledMediaQuery = (style?: BannerJSONStyle): CSSObject => {
  if (!style || Object.keys(style).length === 0) return {};

  const result = Object.entries(style).reduce((transformed, original) => {
    const [breakpoint, styleValue] = original;
    let customTransformed = transformed;
    if (breakpoint === 'default') {
      customTransformed = { ...transformed, ...styleValue };
    } else {
      customTransformed = {
        ...transformed,
        [`@media${THEME.metrics.mediaQueries[breakpoint as Breakpoint]}`]: styleValue,
      };
    }
    return customTransformed;
  }, {});

  return result;
};

export interface BannerModalProps {
  banner: BannerJsonModal;
  isShowModal: boolean;
  onClose: () => void;
  onOk: () => void;
}

const BannerModal: FC<BannerModalProps> = ({ banner, isShowModal, onClose, onOk }: BannerModalProps) => {
  const { title, style, image, detail } = banner || {};

  return (
    <StyleBannerModal
      closable
      centered
      maskClosable
      title={title}
      visible={isShowModal}
      width="auto"
      footer={false}
      onOk={onOk}
      onCancel={onClose}
    >
      <StyledBannerModalContent customStyle={transformStyledMediaQuery(style)}>
        {image && <StyledBannerModalContentImage alt="banner modal" src={image} />}
        {typeof detail === 'string' ? <StringToJSX domString={detail} /> : detail}
      </StyledBannerModalContent>
    </StyleBannerModal>
  );
};

export default BannerModal;
