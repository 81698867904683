import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import FilterCustomButton from '../FilterCustomButton';
import { FilterProps, SettingProps } from '../FilterProps';

const CustomButtonStyles = css`
  margin: 0 4px;
`;

type Props = {
  path?: string;
  filter?: SettingProps;
  payload?: FilterProps[];
  onSelect?: (value: FilterProps[], path?: string) => void;
};

const FilterBoolean = ({ filter, onSelect, payload, path }: Props) => {
  const hasSelectedOptions = filter?.filters?.some((item: FilterProps) => item?.selectedBoolean);
  const { t } = useTranslation();

  const handleClick = () => {
    const newPayloads =
      payload?.map((item: FilterProps) => ({
        ...item,
        selectedBoolean: !hasSelectedOptions,
      })) || [];

    if (onSelect) onSelect(newPayloads, path);
  };

  return (
    <FilterCustomButton onClick={handleClick} isActive={hasSelectedOptions} customStyles={CustomButtonStyles}>
      {t(filter?.header || '')}
    </FilterCustomButton>
  );
};

FilterBoolean.defaultProps = {
  filter: {},
  payload: {},
  onSelect: () => {},
  path: '',
};

export default FilterBoolean;
