import { TRegionIds } from '@source/constants/common';
import { ALGOLIA_INDEXES_COUNTRIES } from '@source/constants/algolia';
import findKey from 'lodash/findKey';

import SORT_LIST from '@source/pages/Home/components/SortV1.0/constant';
import {
  TSortBy,
  TSortItem,
  TSortOptionItem,
  TSortOptionItemSelected,
} from '@source/pages/Home/components/SortV1.0/types';

export const getOptionTitle = (sortItem: TSortItem, option: TSortOptionItem): string => {
  let result = '';

  if (sortItem.titleOnly) {
    result = sortItem?.title;
  } else if (sortItem.titleOptionOnly) {
    result = option.title;
  } else {
    result = `${sortItem?.title}:${option.title}`;
  }

  return result;
};

export const getOptionValue = (option: TSortOptionItemSelected): string => `${option?.name}:${option?.value}`;

export const getSelectedOption = (sortItem: TSortItem, option: TSortOptionItem): TSortOptionItemSelected => ({
  title: getOptionTitle(sortItem, option),
  name: sortItem.name,
  value: option.value,
});

export const getActiveSortByIndex = (algoliaIndexKey?: string, countryCode?: string) => {
  const result = {
    field: '',
    order: '',
  };

  if (!algoliaIndexKey || !countryCode) {
    return result;
  }

  const indexes = ALGOLIA_INDEXES_COUNTRIES[countryCode as keyof TRegionIds];

  let order = '';
  const field = findKey(indexes, (orderObject) => {
    const findedOrder = findKey(orderObject, (orderKey) => orderKey === algoliaIndexKey);

    if (findedOrder) {
      order = findedOrder;
    }

    return findedOrder;
  });

  return {
    field: field || '',
    order: order || '',
  };
};

export const getActiveSortOption = ({ field, order }: TSortBy, countryCode: string) => {
  if (!field || !order || !countryCode) {
    return null;
  }
  const sortList = SORT_LIST[countryCode as keyof TRegionIds];
  const sortItem = sortList.find((item) => item.name === field);
  const option = sortItem?.sortOptions?.find((item) => item.value === order);

  if (sortItem && option) {
    return getSelectedOption(sortItem, option);
  }

  return null;
};
