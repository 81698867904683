import { CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import useBreakPoint from '@design-system/hooks/useBreakPoint';
import CONTENT from '@source/pages/Home/components/CarroCertified/constant';

// TODO: @cris convert to use styled-component
require('@source/pages/Home/components/CarroCertified/CarroCertified.less');

type DetailIcon = {
  desktop: JSX.Element | string;
  mobile: JSX.Element | string;
};

const StyledModalContent = styled.div`
  .ccpo-carousel-banner-modal {
    .item-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 24px;
    }

    .item-title,
    .item {
      margin-top: 17px;
      margin-right: 14px;
    }

    .item-text {
      font-weight: 500;
      line-height: 140%;
    }

    .item {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
    }

    .item-icon {
      margin-bottom: 8px;

      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  .bg-image {
    border-radius: 30px 30px 0px 0px;
    background: ${(props) => props.theme.color.primaryBase};
    width: 100%;
    height: 82px;
  }

  .ccpo-container {
    img {
      mix-blend-mode: multiply;
      border-radius: 30px 30px 0px 0px;
    }
  }

  --modal-padding-x: 25px;
  background-color: ${(props) => props.theme.color.background};
  color: ${(props) => props.theme.color.onSurface};
  width: 300px;
  margin: auto;
  border-radius: 40px;

  .anticon-check-circle {
    svg {
      width: 20px;
      height: 20px;
      color: ${(props) => props.theme.color.primaryBase};
      background: ${(props) => props.theme.color.background};
    }
  }

  .ccpo-container {
    position: relative;
  }

  .centered {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-padding {
    padding-left: var(--modal-padding-x);
    padding-right: var(--modal-padding-x);
  }

  .img-text-title {
    color: ${(props) => props.theme.color.onPrimary};
    font-weight: 400;
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-size: 28px;

    .header {
      font-weight: 600;
      font-size: 28px;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }
  }

  .close-icon {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: ${(props) => props.theme.color.primaryBase};
  }

  .modal-title {
    font-weight: 600;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: 16px;
    padding-top: 16px;
    padding-left: var(--modal-padding-x);
    padding-right: var(--modal-padding-x);
    width: 100%;
  }

  .desc {
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    padding: 10px var(--modal-padding-x) 12px var(--modal-padding-x);

    .detail-item {
      padding: 6px 0 6px 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &:not(:first-child) {
        margin-top: 12px;
      }

      .detail {
        text-transform: capitalize;
        font-size: 14px;
        margin-top: 1px;
        margin-left: 12px;
      }
    }
  }

  .footer {
    text-align: center;
    padding-bottom: 29px;
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  }

  @media (min-width: 375px) and (max-width: 992px) {
    font-size: 12px;
    width: 320px;

    .desc {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .desc {
      .detail-item {
        align-items: center;
      }
    }
  }

  @media screen and (min-width: 991px) and (max-width: 1279px) {
    .desc {
      .detail-item {
        align-items: center;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    width: 400px;
    .desc {
      .detail-item {
        align-items: center;
      }
    }
  }
`;

const StyledImageWrapper = styled.div`
  max-width: 70px;
  display: flex;
  justify-content: center;
  max-width: 47px;
`;

interface CarroCertifiedContentProps {
  content?: {
    background?: string;
    header: {
      name: string;
      title: string;
    };
    detail: {
      text: string;
      icon: DetailIcon;
    }[];
    footer: string;
  };
}

const CarroCertifiedContent: FC<CarroCertifiedContentProps> = ({ content }) => {
  const isDesktop = useBreakPoint('lg');
  const { t } = useTranslation();

  const { background, header, detail, footer } = content || CONTENT || {};

  const renderIcon = (icon: JSX.Element | string) =>
    typeof icon === 'string' ? (
      <StyledImageWrapper>
        <Image width="100%" height="100%" src={icon} alt="carro certified" />
      </StyledImageWrapper>
    ) : (
      icon
    );

  const renderDetailIcon = (icon: DetailIcon) => (isDesktop ? renderIcon(icon?.desktop) : renderIcon(icon?.mobile));

  return (
    <StyledModalContent className="ccpo-modal-popup">
      <div className="ccpo-container">
        <div className="bg-image">
          <Image layout="fill" src={background || ''} alt="assure" className="ccpo-banner-img" />
        </div>
        <div className="centered modal-padding">
          <span className="img-text-title">
            <strong>{t(header?.name || '')} </strong>
            {t(header?.title || '')}
          </span>
        </div>
      </div>
      <div className="detail">
        <ul className="desc">
          {detail &&
            detail?.map((info) => (
              <li className="detail-item" key={info?.text}>
                {info?.icon ? renderDetailIcon(info.icon) : <CheckCircleFilled className="check-icon" />}
                <span className="detail">{t(info?.text)}</span>
              </li>
            ))}
        </ul>
      </div>

      <div className="footer">{t(footer)}</div>
    </StyledModalContent>
  );
};

CarroCertifiedContent.defaultProps = {
  content: undefined,
};

export default CarroCertifiedContent;
