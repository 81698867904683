import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Spin } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { Typography } from '@design-system/components';
import { IMAGE_ASPECT_RATIO } from '@design-system/constants';
import { getTrimedS3Url } from '@design-system/utils';
import DefaultCarImage from '@design-system/assets/car/car-default-image.png';
import { TRegionKey } from '@source/interface';
import { carURLInfo, getCarDetailsUrlFormat, getImageKitURLFixedRatioByWidth } from '@source/utils/utils';
import useBrowseServices from '@source/hooks/useBrowseServices';
import useAlgoliaService from '@source/hooks/useAlgoliaService';
import ShowcaseItem from '@source/pages/Home/components/HomeSearch/ShowcaseItem';
import { URL_CONFIG } from '@source/constants/urlConfig';

type HomeSearchPopularCarsProps = {
  countryCode: TRegionKey;
};

// Popular Cars for HomeSearch component
const HomeSearchPopularCars = ({ countryCode }: HomeSearchPopularCarsProps) => {
  // Store popularCars into redux state
  const { browseState, setPopularCars } = useBrowseServices();
  const { popularCars } = browseState || {};
  const { data, isLoading, isLoaded } = popularCars || {};

  const router = useRouter();
  const { t } = useTranslation();

  const { fetchAlgoliaPopularCars } = useAlgoliaService();

  // Fetch popular cars
  const fetchPopularCars = useCallback(
    async (region: TRegionKey) => {
      if (!isLoaded) {
        setPopularCars({ ...popularCars, isLoading: true });
        const res = await fetchAlgoliaPopularCars(region);
        setPopularCars({ ...popularCars, data: res.hits.slice(0, 3), isLoading: false, isLoaded: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchAlgoliaPopularCars, isLoaded, countryCode],
  );

  // When load comp => fetch popular cars
  useEffect(() => {
    fetchPopularCars(countryCode as TRegionKey);
  }, [countryCode]);

  // Render
  const renderPopularCarsContent = () => {
    if (isLoading) {
      return <Spin size="large" />;
    }

    return data?.map((popularCar) => {
      const title = get(popularCar, 'title', get(popularCar, 'listing.title'));
      const image = get(popularCar, 'listing.photos.[0]');
      const trimmedImageUrl = getTrimedS3Url(image || '');
      const imageUrlPrefix = getImageKitURLFixedRatioByWidth(IMAGE_ASPECT_RATIO.width, IMAGE_ASPECT_RATIO.height, 100);
      const imageUrl = `${imageUrlPrefix || '/'}${trimmedImageUrl}`;
      const urlPath = getCarDetailsUrlFormat(carURLInfo(popularCar));

      return (
        <ShowcaseItem
          key={get(popularCar, 'id')}
          direction="row"
          image={<Image width={100} height={55} src={image ? imageUrl : DefaultCarImage} />}
          title={<Typography>{title}</Typography>}
          onClick={() =>
            router.push(URL_CONFIG({ region: countryCode, lang: i18next.language, path: urlPath }).formatted)
          }
        />
      );
    });
  };

  return (
    <div className="search-overlay-section">
      <div className="search-overlay-title">{t('home.search.popularCars')}</div>
      <div className="popular-car-wrapper">{renderPopularCarsContent()}</div>
    </div>
  );
};

export default HomeSearchPopularCars;
