import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Row, Col } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import { useTranslation } from 'react-i18next';

import useBreakPoint from '@design-system/hooks/useBreakPoint';
import { getCustomLabel, isDotNotation } from '@design-system/utils/utils';
import { DefaultOptionProps, FilterButtonProps } from '@design-system/components/FiltersV1/FilterProps';
import CustomButton from '@design-system/components/FiltersV1/elements/CustomButton';

import { ANTD_ICONS_KEYS } from '@source/pages/Home/components/Filters/configs/constants';
import { bodyTypeList } from '@source/pages/Home/constants';

interface StyledButtonProps extends BaseButtonProps {
  hasImage?: boolean;
  isSelected?: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  color: ${(props) => props.theme.color.onSurface};
  ${(props) => props.theme.typo.familyGoogle.medium};

  .image-btn-wrapper {
    height: 42px;
    display: inline-flex;
    align-items: flex-end;

    .btn-color {
      width: 35px;
      height: 35px;
      display: inline-block;
      border-radius: 100%;
      border: ${(props) => `1px solid ${props.theme.color.onBackgroundMediumEmphasis}`};
    }
  }

  &.ant-btn {
    &:hover {
      color: ${(props) => props.theme.color.primaryBase};
    }
    &:focus {
      color: ${(props) => props.theme.color.onSurface};
    }
  }

  &.ant-btn {
    border: 1px solid transparent;
  }

  &.ant-btn-primary {
    color: ${(props) => props.theme.color.primaryBase}!important;
    border-color: ${(props) => props.theme.color.primaryBase}!important;
  }

  ${(props) =>
    props.hasImage &&
    css`
      width: 100%;
      height: auto;
      padding: 5px 0 0 0 !important;
      border: ${props.isSelected ? `1px solid ${props.theme.color.primaryBase}` : 'none'};
      box-shadow: none;
    `}
`;

const StyledButtonContent = styled.div<{ label?: string; name?: string }>`
  ${(props) => props.theme.typo.familyGoogle.regular};
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
`;

const StyledCol = styled(Col)``;

const StyledButtonImage = styled.img`
  height: 40px;

  @media screen and (max-width: 575px) {
    height: 30px;
  }
`;

type ButtonStylesProps = {
  padding?: number;
};

const StyledSecondaryButtonWrapper = styled.div<{
  buttonStyles?: ButtonStylesProps;
}>`
  height: 42px;
  padding: ${(props) => (props.buttonStyles ? props?.buttonStyles?.padding : '0')};

  .less-awesome-active {
    background: ${(props) => props.theme.color.primarySubtle} !important;
    color: ${(props) => props.theme.color.primaryBase} !important;
  }

  .less-awesome-primary,
  .less-awesome-active {
    display: table;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`;

const CustomButtonStyles = css`
  width: 100%;
  box-shadow: none;
  ${(props) => props.theme.typo.familyGoogle.regular};
`;

type Props = {
  label?: string;
  header?: string;
  filter: FilterButtonProps;
  onSelect: (value: any, path: string) => void;
  type: string;
  payload: FilterButtonProps;
  path: string;
};

const FilterButton = ({ label, filter, header, onSelect, type = 'primary', payload, path }: Props) => {
  const isScreenSmaller1200 = !useBreakPoint('xl');
  const isDesktop = useBreakPoint('xl');
  const selectedOptions = payload?.selectedOptions || filter?.selectedOptions || [];
  const { t } = useTranslation();

  const {
    options = [],
    name,
    imagePath,
    imageStyle = {},
    rowGutter = [16, 16],
    colResponsive = { xs: 24, sm: 12 },
    selectedCustomLabel,
    canSelectOneOnly,
    buttonWrapperStyles,
    buttonShape,
    hasCustomBG = false,
    isShowHeader = true,
  } = filter || {};

  const isColorListing = name === 'inventory.color' || name === 'inventory.base_color_key';
  const customOptions = name === 'inventory.car_type' ? options?.map((option) => bodyTypeList.find((hOption) => hOption.value === option.value) || option) : options;

  const onButtonClick = (item: DefaultOptionProps) => {
    const newSelectedCustomLabel =
      selectedCustomLabel && isDotNotation(selectedCustomLabel) ? t(selectedCustomLabel) : selectedCustomLabel;
    const customItem = {
      ...item,
      customLabel: selectedCustomLabel ? getCustomLabel(newSelectedCustomLabel, item) : item.label?.toString(),
    };

    let newSelectedOptions: DefaultOptionProps[] = [];

    if (canSelectOneOnly) {
      newSelectedOptions = [customItem];
    } else {
      const hasSelected = selectedOptions?.some((opt) => opt.value === customItem.value);

      if (hasSelected) {
        newSelectedOptions = selectedOptions.filter((opt) => opt.value !== customItem.value);
      } else {
        newSelectedOptions = selectedOptions.concat(customItem);
      }
    }

    const newPayload = {
      ...(payload || {}),
      header: header || filter?.header,
      name,
      selectedOptions: newSelectedOptions,
    };

    onSelect(newPayload, path);
  };

  const renderColor = (item: any) => {
    const { imgName, value, colorCode, colorImgSrc } = item || {};
    return (
      <span
        className={`btn-color btn-color-${colorCode || name || imgName || value}`}
        style={ item.name === 'others' ? {backgroundImage: `url(${colorImgSrc})`, backgroundSize: 'cover'} : { backgroundColor: colorCode || item.name || imgName || value }}
      />
    );
  };

  const renderImage = (item: any) => {
    const { imgName, value } = item || {};
    const image = imagePath?.replace('{0}', `${imgName || value}`);
    // return isCDN ? getStaticCDN(image) : image;
    return image;
  };

  const getSecondaryLabel = (item: any, width: any, height: any) => {
    const { value, antdIcon } = item || {};
    if (item.imagePath) {
      return <StyledButtonImage height={height} width={width} src={imagePath} alt={value} />;
    }

    if (antdIcon) {
      return (
        <div>
          {ANTD_ICONS_KEYS?.[antdIcon as keyof typeof ANTD_ICONS_KEYS]} {t(item.label)}
        </div>
      );
    }

    return t(item.label);
  };

  const getSecondaryButtonType = (item: any, isSelected: boolean, isNotMultiSelect: boolean) => {
    if (item.buttonType) {
      return isSelected ? `${item.buttonType}-active` : item.buttonType;
    }

    if (item?.canSelectOneOnly && isNotMultiSelect) {
      return isSelected ? 'active' : 'inactive';
    }

    return isSelected ? 'active' : 'primary';
  };

  const renderPrimaryButton = (item: DefaultOptionProps, isSelected: boolean) => {
    const { className } = item || {};

    return (
      <StyledButton
        ghost={!!(isSelected && imagePath)}
        onClick={() => onButtonClick(item)}
        type={isSelected ? 'primary' : 'default'}
        name={name}
        hasImage={!!imagePath}
        isSelected={isSelected}
        className={className}
      >
        <div className="image-btn-wrapper">
          {isColorListing && renderColor(item)}
          {imagePath && !isColorListing && (
            // Lets use original image element first.
            // eslint-disable-next-line @next/next/no-img-element
            <img className="image-btn" src={renderImage(item)} alt="button-icon" style={imageStyle} />
          )}
        </div>
        <StyledButtonContent label={t(item.label?.toString() || '')} name={name}>
          {t(item.label?.toString() || '')}
        </StyledButtonContent>
      </StyledButton>
    );
  };

  const renderSecondaryButton = (item: any, isSelected: boolean, isNotMultiSelect: boolean) => {
    const { imageHeight, imageWidth } = item || {};

    return (
      <StyledSecondaryButtonWrapper buttonStyles={buttonWrapperStyles}>
        <CustomButton
          disabled={false}
          type={getSecondaryButtonType(filter, isSelected, isNotMultiSelect)}
          size={isScreenSmaller1200 ? 'md' : 'lg'}
          customStyles={CustomButtonStyles.toString()}
          shape={buttonShape || 'round'}
          onClick={() => onButtonClick(item)}
          hasCustomBG={hasCustomBG}
        >
          {getSecondaryLabel(item, imageHeight, imageWidth)}
        </CustomButton>
      </StyledSecondaryButtonWrapper>
    );
  };

  return (
    <>
      <Row>
        {isShowHeader && (
          <div className="filter-header">
            <div className="filter-header-text">{t(label || '')}</div>
          </div>
        )}
      </Row>

      <Row gutter={rowGutter}>
        {customOptions?.map((item: DefaultOptionProps) => {
          const { showInDesktop, value } = item;
          if (isDesktop && showInDesktop === false) {
            return null;
          }

          const isNotMultiSelect = !!selectedOptions?.find((e) => e.isMultiSelect === false);

          const isSelected = !!selectedOptions?.find((opt) => opt.value === value);

          return (
            <StyledCol key={item.name} {...colResponsive} span={8}>
              {type === 'primary' && renderPrimaryButton(item, isSelected)}
              {type === 'secondary' && renderSecondaryButton(item, isSelected, isNotMultiSelect)}
            </StyledCol>
          );
        })}
      </Row>
    </>
  );
};

FilterButton.defaultProps = {
  label: '',
  header: '',
};

export default FilterButton;
