import { SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import { getFilterSelectedOptionsV2 } from '@design-system/utils/filters';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { TRegionKey } from '@source/interface';
import { Option } from '@source/interface/common';
import { getBuyUrlNameByLocalLanguage, getRegionLanguageInRouter } from '@source/utils/utils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { NextRouter } from 'next/router';
import qs from 'qs';
import { mapFilterNameFromQS } from './components/Filters/configs/constants';

export const getUniqSearchHistories = (histories: string[], value: string) =>
  histories.find((item) => value?.toLowerCase() === item?.toLowerCase()) ? histories : [value, ...histories];

export const removeDuplicateSelectedOptions = (
  newOptions: Option<string>[],
  currentOptions: (Option<string> | string)[],
) =>
  newOptions.filter((newOption) => {
    const existingValue = currentOptions.find((currentOption) => {
      if (typeof currentOption === 'string') {
        return currentOption === newOption.value;
      }

      return currentOption.value === newOption.value;
    });
    return !existingValue;
  });

//  if options are provided, get value from options and if no label is found, remove from suggestions
export const convertToLabelValuePairArray = (options: string[], optionsKeyValuesArray: Option<string>[] = []) => {
  const hasOptions = optionsKeyValuesArray.length > 0;

  const getLabel = (value: string) => {
    const label = optionsKeyValuesArray.find((option) => option.value === value)?.label;
    if (hasOptions && !label) {
      return '';
    }
    return label || value;
  };

  return options.map((value) => ({ label: getLabel(value), value })).filter((option) => option.label);
};

export const isSearchable = (query: string) => query.length >= 3;

export const isSingleBrandOrModel = (data: string | undefined) => (data ? data.split(',')?.length === 1 : false);

export const areModelsTheSameBrandFromQS = (brand: string | undefined, models: string | undefined) => {
  if (brand) {
    if (models) {
      const diffBrand = models?.split(',').find((model) => !model.toLowerCase()?.includes(brand?.toLowerCase()));
      return !diffBrand;
    }
    return true;
  }
  return false;
};

export const removeUnnescessaryModelsInQS = (brands: string | undefined, models: string | undefined) => {
  if (!brands) return models;
  const brandNames = brands.toLowerCase().split(',');
  if (models) {
    const modelNames = models.toLowerCase().split(',');
    let result: Array<string> = [];
    result = modelNames.filter((model) => !brandNames.includes(model.split(' ')?.[0]));
    return result.join(',');
  }
  return undefined;
};

export const redirectBaseOnUrl = (
  router: NextRouter,
  countryCode: TRegionKey,
  filterSettings: SettingProps[],
  currentQuery: string,
  otherQSObj?: { [key: string]: string | number },
  isFilterComponent?: boolean,
): string => {
  const selectedFilters = getFilterSelectedOptionsV2(filterSettings, countryCode);
  let configUrl = '';
  if (selectedFilters) {
    const routerQuery = qs.parse(qs.stringify(router.query));
    if (routerQuery.query) routerQuery.query = encodeURIComponent(routerQuery.query as string);

    // keep other query params that are not filter params
    const allUrlFilterName = Object.keys(mapFilterNameFromQS(countryCode));
    const newRouterQuery = Object.keys(routerQuery)
      .filter((item) => ![...allUrlFilterName, 'brand', 'model'].includes(item))
      .reduce((obj, key: string) => {
        set(obj, key, routerQuery[key]);
        return obj;
      }, {});

    const { region, language } = getRegionLanguageInRouter(router, countryCode as TRegionKey);
    let qsObject = { ...newRouterQuery, ...selectedFilters, ...(otherQSObj || {}) };
    if (isEmpty(currentQuery)) qsObject = omit(qsObject, 'query');
    qsObject = omit(qsObject, 'lang');

    const selectedBrands = get(selectedFilters, 'brands');
    const selectedModels = get(selectedFilters, 'models');

    const buyUrlName = getBuyUrlNameByLocalLanguage(countryCode, language);

    // when only 1 brand is selected
    if (
      selectedBrands &&
      isSingleBrandOrModel(selectedBrands) &&
      areModelsTheSameBrandFromQS(selectedBrands, selectedModels)
    ) {
      qsObject = omit(qsObject, 'brands');
      qsObject = omit(qsObject, 'models');
      configUrl = URL_CONFIG({
        region: region as TRegionKey,
        lang: language,
        query: qs.stringify(qsObject, { arrayFormat: 'comma', encode: false }),
        path: `${buyUrlName}/${(selectedBrands as string).toLowerCase()}`,
      }).formatted;
      // router.push(configUrl, configUrl, { shallow: true });
      return configUrl;
    }

    // when only 1 model is selected
    if (selectedModels && isSingleBrandOrModel(selectedModels)) {
      qsObject = omit(qsObject, 'models');
      const brandAndModel = (selectedModels as string).split(' ');
      const brandName = brandAndModel?.[0];
      const modelName = brandAndModel?.slice(1)?.join('-');
      configUrl = URL_CONFIG({
        region: region as TRegionKey,
        lang: language,
        query: qs.stringify(qsObject, { arrayFormat: 'comma', encode: false }),
        path: `${buyUrlName}/${brandName.toLowerCase()}/${modelName.toLowerCase()}`,
      }).formatted;
      return configUrl;
    }

    // when there is no filter selected
    if (isEmpty(selectedFilters)) {
      configUrl =
        isFilterComponent || !isEmpty(qsObject)
          ? URL_CONFIG({
              region: region as TRegionKey,
              lang: language,
              query: qs.stringify(qsObject, { arrayFormat: 'comma', encode: false }),
            }).home
          : '';

      return configUrl;
    }

    // remove model names in url query under the selected brands
    if (selectedModels) {
      const finalModelsInQS = removeUnnescessaryModelsInQS(selectedBrands, selectedModels);
      set(qsObject, 'models', finalModelsInQS || undefined);
    }
    configUrl = URL_CONFIG({
      region: region as TRegionKey,
      lang: language,
      query: qs.stringify(qsObject, { arrayFormat: 'comma', encode: false }),
      path: `${buyUrlName}`,
    }).formatted;
    return configUrl;
  }
  return configUrl;
};
