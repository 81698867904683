import { useEffect, useState } from 'react';

const useListenEventFromMobileApp = ({ eventName }: { eventName: string }) => {
  const [isActionFromMobileApp, setIsActionFromMobileApp] = useState(false);

  const handleEventListener = () => {
    setIsActionFromMobileApp(true);
  };

  useEffect(() => {
    (window as any)[eventName] = new Event(eventName);
    window.addEventListener(eventName, handleEventListener, false);

    return () => {
      window.removeEventListener(eventName, handleEventListener);
    };
  }, []);

  return {
    isActionFromMobileApp,
    removeIsActionFromMobileApp: () => setIsActionFromMobileApp(false),
  };
};

export default useListenEventFromMobileApp;
