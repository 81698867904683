import React, { FC, ReactElement, useEffect } from 'react';
import styled, { CSSProp } from 'styled-components';

import toNumber from 'lodash/toNumber';
import { useTranslation } from 'react-i18next';

import type { FilterProps } from '@design-system/components/FiltersV1/FilterProps';
import { getCustomLabel } from '@design-system/utils/utils';
import MinusIcon from '@design-system/components/FiltersV1/assets/minus-icon';
import PlusIcon from '@design-system/components/FiltersV1/assets/plus-icon';

type FilterNumberProps = {
  path: string;
  label?: string;
  onChange?: (payload: FilterProps, path: string) => void;
  filter: FilterProps;
  payload: FilterProps;
};

const StyledFilterWrapper = styled.div.withConfig({
  componentId: 'filterNumberWrapper',
})`
  .header-text {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    ${(props) => props.theme.typo.style.mainButtonOrLink};
    margin-bottom: 15px;
  }

  .styledNumberInput {
    max-width: 364px;
  }
`;

const FilterNumber = ({ label, onChange, filter, payload, path }: FilterNumberProps) => {
  const { selectedNumber, defaultValue, selectedCustomLabel, name } = payload || {};
  const value = toNumber(selectedNumber || defaultValue || 0);
  const minValueToActiveMinusButton = name?.includes('inventory.number_of_owners') && defaultValue === 0 ? 0 : 1;
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedNumber === null) {
      return;
    }

    const newSelectedOption = {
      name,
      label: value,
      customLabel: getCustomLabel(t(selectedCustomLabel || ''), filter || {}),
      value,
    };

    // set default values
    const newPayload = {
      ...filter,
      selectedNumber: value,
      selectedOptions: [newSelectedOption],
    };
    if (onChange) onChange(newPayload, path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.selectedNumber]); //

  const handleChange = (val?: number) => {
    const parsedValue = parseInt(`${val}`, 10) || 0;
    const newPayload = {
      ...filter,
      selectedNumber: parsedValue,
      selectedOptions: [
        {
          label: defaultValue,
          value: defaultValue,
          name,
          customLabel: getCustomLabel(t(selectedCustomLabel || '') || '', {
            ...filter,
            selectedNumber: val,
          }),
        },
      ],
    };
    if (onChange) onChange(newPayload, path);
  };

  return (
    <StyledFilterWrapper className="filter-number">
      <div className="filter-header">
        <div className="filter-header-text">{t(label || '')}</div>
      </div>
      <FilterNumber.NumberRange
        name="selectedNumber"
        min={0}
        value={value}
        onChange={handleChange}
        minValueToActiveMinusButton={minValueToActiveMinusButton}
      />
    </StyledFilterWrapper>
  );
};

FilterNumber.defaultProps = {
  label: '',
  onChange: () => {},
};

type NumberRangeProps = {
  name: string;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  onChange?: (value?: number) => void;
  customStyles?: CSSProp | Record<string, unknown> | string | ReactElement;
  minValueToActiveMinusButton?: number;
};

const StyledInputWrapper = styled.div.withConfig({
  componentId: 'styledNumberInput',
})<{
  name?: string;
  customStyles: any;
}>`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  justify-content: space-between;

  .value {
    width: 42px;
    height: 42px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`};
  }

  ${(props) => props.customStyles}
`;

const StyledIcon = styled.span<{ inactive: boolean }>`
  width: 32px;
  height: 32px;
  display: inline-flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.surfaceLight};
  pointer-events: none;

  path {
    fill: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.color.onBackgroundLowEmphasis};
  }

  &.active {
    pointer-events: all;
    background-color: ${(props) => props.theme.color.primaryBase};
    box-shadow: 0px 4px 4px rgba(161, 161, 161, 0.25);
    path {
      fill: ${(props) => props.theme.color.background};
    }
  }
`;

const NumberRange: FC<NumberRangeProps> = ({
  name,
  min = 0,
  max,
  step = 1,
  value = 0,
  onChange,
  customStyles,
  minValueToActiveMinusButton = 1,
}) => {
  const handleIncrement = () => {
    if (!max) {
      if (onChange) onChange(value + step);
    }

    if (max && value + step <= max) {
      if (onChange) onChange(value + step);
    }
  };

  const handleDecrement = () => {
    if (value - step >= min) {
      if (onChange) onChange(value - step);
    }
  };

  const activeButtonMinusClass = value > minValueToActiveMinusButton ? 'active' : '';

  return (
    <StyledInputWrapper name={name} customStyles={customStyles}>
      <StyledIcon
        inactive={value === min}
        onClick={handleDecrement}
        className={`icon-button minus-icon-button ${activeButtonMinusClass}`}
      >
        <MinusIcon className="icon" />
      </StyledIcon>
      <span className="value">{value}</span>
      <StyledIcon inactive={value === max} onClick={handleIncrement} className="icon-button plus-icon-button active">
        <PlusIcon className="icon" />
      </StyledIcon>
    </StyledInputWrapper>
  );
};

NumberRange.defaultProps = {
  min: 0,
  max: undefined,
  step: undefined,
  value: undefined,
  onChange: () => {},
  customStyles: {},
};

FilterNumber.NumberRange = NumberRange;

export default FilterNumber;
