/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useCallback, useState, useEffect, useMemo, useRef, Fragment } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Col, Row } from 'antd';
import qs from 'qs';
import useLocalStorage from 'use-local-storage';

import type { RequestOptions } from '@algolia/transporter';
import type { Hit } from '@algolia/client-search';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import jsCookie from 'js-cookie';

import { isServer } from '@design-system/utils';

import Layout from '@source/components/Layout';
import useAlgoliaService from '@source/hooks/useAlgoliaService';
import useBrowseServices from '@source/hooks/useBrowseServices';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import useStoreFilterHistory from '@source/hooks/useStoreSearchFilterHistory';
import useSearchFilterServices from '@source/hooks/useSearchFilterServices';
import { buildAlgoliaFilter, buildAlgoliaFilterCarsForSale, buildLocationAlgoliaQuery } from '@source/services/Algolia';
import { TLanguageKey, TRegionKey } from '@source/interface';
import { HomeSearch } from '@source/pages/Home/components/HomeSearch';
import { BrowseMoreButton } from '@source/pages/Home/components/BrowseMoreButton';
import { TSort, TRegionIds, LOCAL_STORAGE_KEYS, COOKIE_KEYS } from '@source/constants/common';
import useUTM from '@source/hooks/useUTM';
import useScreenSize from '@source/hooks/useScreenSize';
import StyledContainer from '@design-system/styled/Container';

import FILTER_CONFIGS from '@source/pages/Home/components/Filters/configs';
import useGTMEvent from '@source/hooks/useGTMEvent';

import { TSortBy, TSortOptionItemSelected } from '@source/pages/Home/components/SortV1.0/types';
import SortByMobileContainer from '@source/pages/Home/components/SortV1.0/SortByMobileContainer';
import BannersCarouselContainer from '@source/pages/Home/components/BannersCarousel/BannersCarouselContainer';
import HeadMeta from '@source/pages/Home/components/HeadMeta';
import FilterLocationButton from '@source/pages/Home/components/FilterLocationButton';
import { LocationOptionProps } from '@source/pages/Home/components/FilterLocationButton/FilterButton';
import { FILTER_CITY_PARAM, HIT_PER_PAGE } from '@source/pages/Home/constants';
import { carURLInfo, getCarDetailsUrlFormat, getRegionLanguageInRouter } from '@source/utils/utils';
import { URL_CONFIG } from '@source/constants/urlConfig';
import HitCarResult from '@source/pages/Home/components/HitsCarResult/HitCarResult';
import { getActiveSortByIndex, getActiveSortOption } from '@source/pages/Home/components/SortV1.0/utils';
import SORT_LIST from '@source/pages/Home/components/SortV1.0/constant';
import { CARRO_CERTIFIED_CARD_CONTENT } from '@source/components/CarroCertifiedCard/content';

import FiltersDesktopContainer from '@source/pages/Home/components/Filters/FiltersDesktopContainer';
import FiltersMobileContainer from '@source/pages/Home/components/Filters/FilterMobileContainer';
import useWindowResize from '@source/hooks/useWindowResize';
import { FilterProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import CarCardSkeleton from '@source/pages/Home/components/CarCardSkeleton/CarCardSkeleton';
import { BannerJSON } from '@source/pages/Home/components/BannersCarousel/types';
import { useTranslation } from 'react-i18next';
import {
  List as _List,
  AutoSizer as _AutoSizer,
  WindowScroller as _WindowScroller,
  CellMeasurer as _CellMeasurer,
} from 'react-virtualized';

// const FiltersDesktopContainer = dynamic(() => import('@source/pages/Home/components/Filters/FiltersDesktopContainer'));
// const FiltersMobileContainer = dynamic(() => import('@source/pages/Home/components/Filters/FilterMobileContainer'));
import BrowsingCard from '@design-system/components/BrowsingCard/BrowsingCard';
import HowToPurchase from '@source/pages/Home/components/HowToPurchase/HowToPurchase';
import CarroCertifiedCard from '@source/components/CarroCertifiedCard';
import BuyHuntContainer from '@source/pages/Home/components/BuyHunt/BuyHuntContainer';
import LazyRenderCard from '@source/pages/Home/components/LazyRenderCard';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { mapFilterNameFromQS } from '@source/pages/Home/components/Filters/configs/constants';
import { redirectBaseOnUrl } from '@source/pages/Home/utils';
import { usePopupService } from '@source/pages/Home/hooks/usePopupService';
import { AVAILABLE_COUNTRIES } from '@source/constants/config';
import useHeadMeta from '@source/pages/Home/hooks/useHeadMeta';
import ANNOUNCEMENT_CONTENT from '@source/components/Layout/constants';

const StyleWrapper = styled.div`
  margin-bottom: 32px;
`;

const StyledHomeSearch = styled.div`
  padding-bottom: 12px;
`;

const StyledStickySearchBar = styled.div<{
  country: TRegionKey;
  isStickTemporaryBanner: boolean;
  stickyTopPosition: number;
}>`
  margin-top: 32px;
  margin-bottom: 24px;
  min-height: 85px;

  @media only screen and (min-width: 992px) {
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 1280px) {
    margin-bottom: 0;
    min-height: 95px;
  }

  &.sticky-filter-desktop {
    &.sticky {
      box-shadow: 0px 4px 15px rgb(33 33 33 / 15%);
      transform: translateY(-100%);
      transition: none;
    }
  }
  &.sticky-filter-mobile {
    &.sticky {
      transform: translateY(0);
      transition: none;
      box-shadow: 0px 6px 6px rgba(161, 161, 161, 0.25);
    }
  }
  &.sticky {
    position: sticky;
    padding-block: 16px;
    top: ${(props) => `${props.stickyTopPosition}px`};
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: ${(props) => props.theme.color.background};
    &.sticky-show {
      transition: all 0.5s;
      transform: translateY(0);
    }
    &.sticky-hide {
      transition: all 0.5s;
      transform: translateY(-100%);
    }
    &.sticky-down {
      top: ${(props) =>
        ['my', 'hk'].includes(props.country)
          ? '85px'
          : '50px'}; // if remove the TemporaryBannerForMY, change it to 50px
    }
    &.no-navbar,
    &.sticky-down .no-navbar {
      top: ${(props) => (props.isStickTemporaryBanner ? '35px' : '0')};
    }
  }

  &.no-navbar {
    margin-top: 0;
  }
`;

const StyledFilterContainer = styled.div``;

const StyledFilterBottomContainer = styled.div`
  margin-bottom: 12px;
  min-height: 36px;
  @media only screen and (min-width: 992px) {
    margin-bottom: 16px;
  }
  @media only screen and (min-width: 1280px) {
    min-height: 73px;
  }
`;

const StyledSortFilterBarMobile = styled.div`
  display: block;
  padding: 0;
`;

const StyledSortFilterBarDesktop = styled.div``;

const StyledSortByDesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0 0 0;
  @media only screen and (min-width: 1440px) {
    padding: 32px 0 0 0;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1279px) {
    display: block;
    justify-content: space-between;
  }
`;

const ResultLocationWrapperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 36px;
`;

const StyledCarsContainer = styled.div`
  margin-top: 30px;

  .lazy-load-image-background {
    img {
      position: absolute;
      box-sizing: border-box;
      padding: 0px;
      border: none;
      margin: auto;
      display: block;
      /* border-top-right-radius: 5px;
      border-top-left-radius: 5px; */
      object-position: center center;
      object-fit: cover;
    }
  }
`;
const CarListWrapper = styled.div`
  overflow: initial;
  > div {
    overflow: initial !important;
    > div {
      overflow: initial !important;
    }
  }
`;

const OverridePopoverStyle = createGlobalStyle<{ isFilterSticky?: boolean; popOverTopValue: number | undefined }>`
  .filter-popover {
    ${(props) =>
      props.isFilterSticky &&
      props.popOverTopValue &&
      css`
        top: ${props.popOverTopValue}px !important;
        transition: all 0.5s;
      `};
  }
`;

let prevScrollPos = typeof window !== 'undefined' ? window.scrollY : null;

export interface HomeProps {
  banners: BannerJSON[];
  isFromMobileApp: boolean;
  region: TRegionKey;
  onPageReady: () => void;
  deviceHint: 'desktop' | 'tablet' | 'mobile';
}

const Home: NextPage<HomeProps> = ({ banners, isFromMobileApp, region, onPageReady, deviceHint }: HomeProps) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();
  const [currentAlgoliaParams, setCurrentAlgoliaParams] = useState<RequestOptions>({
    facets: ['*'],
    attributesToRetrieve: FILTER_CONFIGS?.[countryCode as keyof typeof FILTER_CONFIGS]?.attributesToRetrieve || ['*'],
    filters: '',
    hitsPerPage: HIT_PER_PAGE,
    page: 0,
  });

  const [isNoRecordHistory, setIsNoRecordHistory] = useLocalStorage('no-record-search-history', false);

  // Current lang state
  const [currentLang, setCurrentLang] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => {
    setCurrentLang(lng);
  });

  const { setSearchedFiltered } = useStoreFilterHistory();
  const { uploadSearchFilterHistory } = useSearchFilterServices();
  const { isAuthenticated } = useUserProfileServices();
  const isAuth = isAuthenticated();

  // Todo_Son: Might bring all of them to redux store
  const [currentSortBy, setCurrentSortBy] = useState<TSortBy | null>(null);

  // Location Filter Options
  // Todo: Might need to find out better way.
  const [selectedLocations, setSelectedLocations] = useState<LocationOptionProps[]>([]);

  const [cars, setCars] = useState<Hit<object>[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalCarsForSale, setTotalCarsForSale] = useState(0);
  const [isBrowseMoreLoading, setIsBrowseMoreLoading] = useState(false);
  const [isCarAlreadyFetched, setIsCarAlreadyFetched] = useState(false);
  const [isFetchingCars, setIsFetchingCars] = useState(true);

  // Sticky Filter
  const contentRef = useRef<any>(null);
  const filterRef = useRef<any>(null);

  const [isFilterStickyClass, setFilterStickyClass] = useState('');
  const [isFilterMobileStickyClass, setFilterMobileStickyClass] = useState('');
  const [stickyControlClass, setStickyControlClass] = useState('');
  const [stickyControlMobileClass, setStickyControlMobileClass] = useState('');

  const [filterOffsetBottom, setFilterOffsetBottom] = useState(0);
  const [filterOffsetTop, setFilterOffsetTop] = useState(0);

  const [searchFilterGTMData, setSearchFilterGTMData] = useState<any>(null);
  const [popOverTopValue, setPopOverTopValue] = useState<number | undefined>();

  const [isStickTemporaryBanner, setIsStickTemporaryBanner] = useState<boolean>(false);
  const [isTemporaryBannerToTop, setTemporaryBannerToTop] = useState<boolean>(false);
  const [stickyTopPosition, setStickyTopPosition] = useState<number>(0);
  const [unStickTemporaryBanner, setUnStickTemporaryBanner] = useState<boolean>(false);

  // const isLgBreakpoint = useBreakPoint('xl');
  const screenSize = useScreenSize();

  // Use useWindowResize of useBreakPoint or useMobileView
  // because those are returning boolean false at first
  // => mobileFilter is rendered first although in desktop
  // => re-render redundant stuffs
  // useWindowSize return undefined first and return width size
  // i can check condition that width is valid first
  const [width] = useWindowResize() as number[];
  const isDesktopView = width && width >= 1280;
  const isMobileView = width && width < 1280;

  const hasBanner = banners.length > 0;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isShowTemporaryBanner } = ANNOUNCEMENT_CONTENT[countryCode] || {};

  // to init algolia service with region
  const { fetchAlgoliaData, getAlgoliaIndexes } = useAlgoliaService();

  const { pushGTMEvent } = useGTMEvent();

  const { browseState, setCurrentFilterSettings, setCurrentQuery, setCurrentFilterConfigs, setSyncUpUrlToFilter } =
    useBrowseServices();
  const { currentFilterSettings, currentQuery } = browseState;

  const activeFilterSetting = useMemo(
    () =>
      currentFilterSettings?.filter((setting: SettingProps) =>
        setting?.filters?.some(
          (item: FilterProps) => (item?.selectedOptions && item?.selectedOptions?.length) || item?.selectedBoolean,
        ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilterSettings, currentLang],
  );

  const { metaData } = useHeadMeta({
    filterSettings: currentFilterSettings,
    countryCode: (countryCode || region) as TRegionKey,
  });

  const { openLocationPopup } = usePopupService();
  // Init current filters settings/configs base on the country
  useEffect(() => {
    const currentConfigs = FILTER_CONFIGS?.[countryCode as keyof typeof FILTER_CONFIGS] || FILTER_CONFIGS.sg;
    setCurrentFilterSettings(currentConfigs.initialFilterSettings);
    setCurrentFilterConfigs(currentConfigs);
    setSyncUpUrlToFilter(true);
  }, [countryCode]);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setSyncUpUrlToFilter(true);
    });
    return () => {
      router.events.off('routeChangeComplete', () => {
        setSyncUpUrlToFilter(false);
      });
    };
  }, [router.asPath, router.isReady, countryCode, router.events]);

  // set current query from router's query string
  useEffect(() => {
    setCurrentQuery((router.query?.query || '') as string);
  }, [router]);

  const carCardGutters: any = useMemo(() => {
    const gutters: { [key: string]: any } = {
      xs: [0, 24],
      sm: [0, 24],
      md: [16, 24],
      lg: [16, 24],
      xl: [16, 24],
      xxl: [16, 24],
    };

    return gutters[screenSize];
  }, [screenSize]);

  const scrollToTopListCard = useCallback(() => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > filterOffsetTop) {
      window?.scrollTo({
        top: filterOffsetTop,
        behavior: 'smooth',
      });
    }
  }, [filterOffsetBottom]);

  // save UTM referrer to cookie
  useUTM();

  const onGTMSearchFilter = (hits: any) => {
    const gtmSlugs = hits?.slice(0, 10)?.map(({ listing }: any) => listing?.slug);
    if (searchFilterGTMData) {
      const gtmPayload = {
        ...searchFilterGTMData?.gtm,
        slugs: gtmSlugs,
      };
      pushGTMEvent(gtmPayload, searchFilterGTMData?.configUrl);
    }
  };

  const fetchHits = useCallback(
    async (
      {
        params,
        sortBy,
        query,
      }: {
        params?: RequestOptions;
        sortBy?: TSortBy;
        query?: string;
      },
      isSubmitSearch?: boolean,
    ) => {
      setIsFetchingCars(true);
      const customParams = {
        ...currentAlgoliaParams,
        ...(params || {}),
      };
      const customSortBy = sortBy || currentSortBy;

      const customQuery = query || currentQuery;

      const { hits, nbHits } = await fetchAlgoliaData(customParams, customSortBy, customQuery);

      setIsCarAlreadyFetched(true);
      setIsFetchingCars(false);
      setTotal(nbHits);
      setCars(hits);
      if (hits) {
        setTimeout(() => {
          scrollToTopListCard();
        }, 350);
      }

      const filtersToGetCarsForSale = [customParams.filters, buildAlgoliaFilterCarsForSale()]
        .filter((i) => !isEmpty(i))
        .join(' AND ');

      const customParamsCarsForSale = {
        ...customParams,
        filters: filtersToGetCarsForSale,
      };

      const { nbHits: nbCarsForSale } = await fetchAlgoliaData(customParamsCarsForSale, customSortBy, customQuery);
      setTotalCarsForSale(nbCarsForSale);

      if (isSubmitSearch) {
        onGTMSearchFilter(hits);
      }
    },
    [fetchAlgoliaData, currentAlgoliaParams, currentSortBy, currentQuery],
  );

  useEffect(() => {
    const locationFilter = buildLocationAlgoliaQuery(
      selectedLocations,
      FILTER_CITY_PARAM?.[countryCode as keyof typeof FILTER_CITY_PARAM],
      countryCode,
    );
    const filterQuery = buildAlgoliaFilter(currentFilterSettings);
    const newFilters = [filterQuery, locationFilter].filter((i) => !isEmpty(i)).join(' AND ');
    const params = {
      facets: ['*'],
      filters: newFilters,
      hitsPerPage: HIT_PER_PAGE,
      attributesToRetrieve: FILTER_CONFIGS?.[countryCode as keyof typeof FILTER_CONFIGS]?.attributesToRetrieve || ['*'],
      page: page - 1,
    };

    if (countryCode && currentSortBy) {
      const isSubmitSearchOrFilter =
        typeof window !== 'undefined' && !!localStorage?.getItem(LOCAL_STORAGE_KEYS.SUBMIT_SEARCH_FILTER);

      // fetchHits fn is calling inside handleOnFilterSave as well => Algolia fetching twice that need to be refactored later
      fetchHits({ params, query: currentQuery, sortBy: currentSortBy || undefined }, isSubmitSearchOrFilter);
    }
    // remove search & filter submitted indicator
    if (typeof window !== 'undefined' && localStorage?.getItem(LOCAL_STORAGE_KEYS.SUBMIT_SEARCH_FILTER)) {
      localStorage?.removeItem(LOCAL_STORAGE_KEYS.SUBMIT_SEARCH_FILTER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countryCode,
    JSON.stringify(currentSortBy),
    JSON.stringify(activeFilterSetting),
    currentQuery,
    JSON.stringify(selectedLocations),
  ]);
  const handleOnSearchSubmit = useCallback(
    (queryString: string, isSuggested?: boolean) => {
      if (queryString) {
        setCurrentQuery(queryString);
        const routerQuery = qs.parse(qs.stringify(router.query));
        let qsObject = { ...routerQuery, query: encodeURIComponent(queryString) };
        qsObject = omit(qsObject, 'lang');
        const { region, language } = getRegionLanguageInRouter(router, countryCode as TRegionKey);
        const configUrl = URL_CONFIG({
          region: region as TRegionKey,
          lang: language,
          query: qs.stringify(qsObject, { arrayFormat: 'comma', encode: false }),
        }).home;
        router.push(configUrl, configUrl, { shallow: true });

        if (isAuth) {
          uploadSearchFilterHistory({
            data: { query: queryString, filters: currentAlgoliaParams.filters },
            country: region,
            isAuthenticated: true,
          });
        } else {
          setSearchedFiltered({ query: queryString, filters: currentAlgoliaParams.filters, region });
        }

        // store submit search in gtm state
        setSearchFilterGTMData({
          gtm: {
            event: isSuggested ? 'clicked_buycar_search_listing' : 'selected_homepage_buycar_search_listing',
            suggested_term: queryString,
          },
          configUrl,
        });
        if (typeof window !== 'undefined') {
          localStorage.setItem(LOCAL_STORAGE_KEYS.SUBMIT_SEARCH_FILTER, 'true');
        }
      }
    },
    [router],
  );

  const handleOnConfirm = (selectedOptions: any): void => {
    setSelectedLocations(selectedOptions);
  };

  const handleOnCitiFilterClose = (): void => {};

  const handleFilteredRedirect = (configUrl: string) => {
    if (configUrl) router.push(configUrl, configUrl, { shallow: true });
  };

  // Temp on filter saving
  const handleOnFilterSave = useCallback(
    async (algoliaParams: Record<string, any>, latestFilterSettings?: any, isFilterComponent?: boolean) => {
      setPage(1);
      const locationFilter = buildLocationAlgoliaQuery(
        selectedLocations,
        FILTER_CITY_PARAM?.[countryCode as keyof typeof FILTER_CITY_PARAM],
        countryCode,
      );

      const newFilters = [algoliaParams.filters, locationFilter].filter((i) => !isEmpty(i)).join(' AND ');
      const newAlgoliaParams = { ...algoliaParams, page: 0, filters: newFilters }; // set page to 0 when apply any filter
      setCurrentAlgoliaParams({ ...currentAlgoliaParams, ...newAlgoliaParams });

      // debounce(async () => {
      // await fetchHits({ params: newAlgoliaParams });
      // }, 300);

      const fnDebounce = debounce(fetchHits, 300);

      await fnDebounce({ params: newAlgoliaParams });

      const configUrl = redirectBaseOnUrl(
        router,
        countryCode as TRegionKey,
        latestFilterSettings || currentFilterSettings,
        currentQuery,
        undefined,
        isFilterComponent,
      );

      handleFilteredRedirect(configUrl);

      if (configUrl && newFilters) {
        if (typeof window !== 'undefined') {
          localStorage.setItem(LOCAL_STORAGE_KEYS.SUBMIT_SEARCH_FILTER, 'true');
        }
        // store selected filter in gtm state
        setSearchFilterGTMData({
          gtm: {
            event: 'clicked_buycar_filter_listing',
            filters: newFilters,
          },
          configUrl,
        });
        if (isAuth) {
          if (isNoRecordHistory) {
            setIsNoRecordHistory(false);
          } else {
            uploadSearchFilterHistory({
              data: { query: currentQuery, filters: newFilters },
              country: countryCode as TRegionKey,
              isAuthenticated: true,
            });
          }
        } else {
          setSearchedFiltered({ query: currentQuery, filters: newFilters, region: countryCode });
        }
      }
    },
    [currentAlgoliaParams, currentFilterSettings, fetchHits, router, selectedLocations],
  );

  const handleChangeSortBy = (selectedOption: TSortOptionItemSelected) => {
    const { name, value } = selectedOption || { name: '', value: '' };
    const sortBy = { field: name, order: value };
    setCurrentSortBy(sortBy);
    setPage(1);

    if (!isServer()) {
      const algoliaIndexes = getAlgoliaIndexes(countryCode, name);
      const indexName = algoliaIndexes?.[(value as keyof TSort) || 'asc'];
      const configUrl = redirectBaseOnUrl(
        router,
        countryCode as TRegionKey,
        currentFilterSettings,
        currentQuery,
        {
          sortBy: indexName,
        },
        false,
      );

      handleFilteredRedirect(configUrl);

      pushGTMEvent(
        {
          event: 'selected_buycar_sort_listing',
          sort: sortBy,
        },
        configUrl,
      );
    }
  };

  const handleBrowseMoreButton = async () => {
    setPage(page + 1);
    setIsBrowseMoreLoading(true);

    const locationFilter = buildLocationAlgoliaQuery(
      selectedLocations,
      FILTER_CITY_PARAM?.[countryCode as keyof typeof FILTER_CITY_PARAM],
      countryCode,
    );
    const filterQuery = buildAlgoliaFilter(currentFilterSettings);
    const newFilters = [filterQuery, locationFilter].filter((i) => !isEmpty(i)).join(' AND ');

    const algoliaParams = {
      page,
      hitsPerPage: HIT_PER_PAGE,
      facets: ['*'],
      filters: newFilters,
    };

    const { hits, nbHits } = await fetchAlgoliaData(algoliaParams, currentSortBy, currentQuery);
    setTotal(nbHits);
    setCars((prevHits) => [...prevHits, ...hits]);

    const filtersToGetCarsForSale = [algoliaParams.filters, buildAlgoliaFilterCarsForSale()]
      .filter((i) => !isEmpty(i))
      .join(' AND ');

    const customParamsCarsForSale = {
      ...algoliaParams,
      filters: filtersToGetCarsForSale,
    };

    const { nbHits: nbCarsForSale } = await fetchAlgoliaData(customParamsCarsForSale, currentSortBy, currentQuery);
    setIsBrowseMoreLoading(false);
    setTotalCarsForSale(nbCarsForSale);
  };

  const { linkUrl } =
    CARRO_CERTIFIED_CARD_CONTENT(currentLang as TLanguageKey)[countryCode || 'sg'] ||
    CARRO_CERTIFIED_CARD_CONTENT(currentLang as TLanguageKey).sg;

  useEffect(() => {
    if (countryCode) {
      let activeSortBy: TSortBy;

      if (router?.query?.sortBy && typeof router.query.sortBy === 'string') {
        activeSortBy = getActiveSortByIndex(router.query.sortBy, countryCode);
      } else {
        const sortList = SORT_LIST[countryCode as keyof TRegionIds];
        activeSortBy = {
          field: sortList?.[0]?.name || '',
          order: 'asc',
        };
      }

      if (activeSortBy) {
        const activeSortByOptions = getActiveSortOption(activeSortBy, countryCode);
        const { name, value } = activeSortByOptions || { name: '', value: '' };
        const sortBy = { field: name, order: value };
        setCurrentSortBy(sortBy);
      }
    }
  }, [router?.query, countryCode]);

  const renderCarCards = useCallback(
    (car: any, preloadImage: boolean) => {
      const carObj = carURLInfo(car);
      const urlPath = getCarDetailsUrlFormat(carObj, countryCode, i18n.language as TLanguageKey);
      return (
        <BrowsingCard
          isImagePreload={preloadImage}
          car={car}
          region={countryCode as TRegionKey}
          href={URL_CONFIG({ region: countryCode as TRegionKey, lang: i18n.language, path: urlPath }).formatted}
          language={currentLang}
        />
      );
    },
    [countryCode],
  );

  const renderAdditionalCards = (index: number) => {
    const cardComponents = [
      <HowToPurchase countryCode={countryCode as TRegionKey} />,
      <CarroCertifiedCard
        content={CARRO_CERTIFIED_CARD_CONTENT(currentLang as TLanguageKey)[countryCode || 'sg']}
        href={linkUrl}
      />,
    ];

    let cardPosition = 3; // after each 3 car rows
    if (screenSize === 'md' || screenSize === 'lg') {
      cardPosition = 4;
    }
    if (screenSize === 'xl' || screenSize === 'xxl') {
      cardPosition = 6;
    }

    if ((index + 1) % cardPosition === 0) {
      const cardIndex = (index + 1) / cardPosition - 1;
      const card = <Col xs={{ span: 24 }}>{cardComponents[cardIndex % cardComponents.length]}</Col>;
      return card;
    }
    return null;
  };

  // Sticky Filter Logic
  const SCROLL_CONFIGS = {
    addidtionalOffset: 100,
    filterMobileMarginBottom: 20,
    topNavMobileHeight: 50,
  };

  useEffect(() => {
    const { filterMobileMarginBottom, addidtionalOffset } = SCROLL_CONFIGS;
    if (contentRef?.current && filterRef?.current) {
      setFilterOffsetBottom(contentRef?.current?.offsetTop + addidtionalOffset);
      setFilterOffsetTop(contentRef?.current?.offsetTop - filterRef?.current?.offsetHeight - filterMobileMarginBottom);
    }
  }, [contentRef?.current?.offsetTop, filterRef?.current?.offsetHeight, width]);

  const onInitLocationPopupFinished = () => {
    const cookieUserRegion = jsCookie.get(COOKIE_KEYS.USER_REGION);
    const cookieFirstTimeAccess = jsCookie.get(COOKIE_KEYS.FIRST_TIME_ACCESS);
    if (
      !['sg', 'jp', 'hk']?.includes(countryCode as TRegionKey) &&
      (AVAILABLE_COUNTRIES.includes(cookieUserRegion as TRegionKey) ||
        (!AVAILABLE_COUNTRIES.includes(cookieUserRegion as TRegionKey) &&
          (!cookieFirstTimeAccess || cookieFirstTimeAccess !== 'true')))
    ) {
      const isPopupPreviously = window?.sessionStorage?.getItem('isFilterLocationPopupBefore') as string;
      if (isPopupPreviously !== 'true') {
        openLocationPopup();
      }
    }
    if (
      cookieFirstTimeAccess &&
      cookieFirstTimeAccess === 'true' &&
      AVAILABLE_COUNTRIES.includes(cookieUserRegion as TRegionKey)
    ) {
      jsCookie.set(COOKIE_KEYS.FIRST_TIME_ACCESS, 'false', { expires: 365 });
    }
  };

  const handleScrollDesktop = useCallback(() => {
    const currentScrollPos = window.scrollY;
    setIsStickTemporaryBanner(currentScrollPos > 64);
    if (currentScrollPos > filterOffsetBottom) {
      setFilterStickyClass('sticky');
      setTemporaryBannerToTop(true);
      if (prevScrollPos && prevScrollPos > currentScrollPos) {
        setStickyControlClass('sticky-show');
        setPopOverTopValue(window.pageYOffset + 113);
      } else if (stickyControlClass === 'sticky-show') {
        setStickyControlClass('sticky-hide');
      }
    } else {
      setFilterStickyClass('');
      setStickyControlClass('');
      setTemporaryBannerToTop(false);
    }
    prevScrollPos = currentScrollPos;
  }, [width, filterOffsetBottom, stickyControlClass]);

  const handleScrollMobile = useCallback(() => {
    const { topNavMobileHeight } = SCROLL_CONFIGS;
    const currentScrollPos = window.scrollY;
    setIsStickTemporaryBanner(currentScrollPos > 50);
    const calculateFilterOffsetTop = filterOffsetTop - topNavMobileHeight;
    if (currentScrollPos > calculateFilterOffsetTop) {
      setFilterMobileStickyClass('sticky');
      if (prevScrollPos && prevScrollPos > currentScrollPos) {
        setStickyControlMobileClass('sticky-down');
        setTemporaryBannerToTop(false);
      } else {
        setStickyControlMobileClass('');
        setTemporaryBannerToTop(true);
      }
    } else {
      setFilterMobileStickyClass('');
      setStickyControlMobileClass('');
      setTemporaryBannerToTop(false);
    }
    prevScrollPos = currentScrollPos;
  }, [width, filterOffsetTop]);

  useEffect(() => {
    window.addEventListener('scroll', isDesktopView ? handleScrollDesktop : handleScrollMobile);
    return () => {
      window.removeEventListener('scroll', isDesktopView ? handleScrollDesktop : handleScrollMobile);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOffsetBottom, filterOffsetTop, stickyControlClass, width]);

  useEffect(() => {
    if (!hasBanner && cars.length) {
      onPageReady();
    }
  }, [onPageReady, hasBanner, cars]);

  useEffect(() => {
    if (isStickTemporaryBanner && !unStickTemporaryBanner) {
      if (isTemporaryBannerToTop) {
        setStickyTopPosition(isShowTemporaryBanner ? 35 : 0);
      } else {
        setStickyTopPosition(isShowTemporaryBanner ? 85 : 50);
      }
    } else {
      setStickyTopPosition(0);
    }
  }, [
    isStickTemporaryBanner,
    isTemporaryBannerToTop,
    unStickTemporaryBanner,
    isDesktopView,
    countryCode,
    isShowTemporaryBanner,
  ]);

  const handleClearAllFilters = useCallback(() => {
    const routerQuery = qs.parse(qs.stringify(router.query));
    if (routerQuery.query) routerQuery.query = encodeURIComponent(routerQuery.query as string);

    // keep other query params that are not filter params
    const allUrlFilterName = Object.keys(mapFilterNameFromQS(countryCode as TRegionKey));
    let newRouterQuery = Object.keys(routerQuery)
      .filter((item) => ![...allUrlFilterName, 'brand', 'model'].includes(item))
      .reduce((obj, key: string) => {
        set(obj, key, routerQuery[key]);
        return obj;
      }, {});

    newRouterQuery = omit(newRouterQuery, 'lang');
    newRouterQuery = omit(newRouterQuery, 'query');

    const configUrl = URL_CONFIG({
      region: countryCode as TRegionKey,
      lang: currentLang,
      query: qs.stringify(newRouterQuery, { arrayFormat: 'comma', encode: false }),
    }).home;

    router.push(configUrl, configUrl, { shallow: true });
  }, [currentAlgoliaParams, currentFilterSettings, fetchHits, router.query, selectedLocations, countryCode]);

  const resetSticky = () => {
    setIsStickTemporaryBanner(false);
    setUnStickTemporaryBanner(true);
  };

  return (
    <Layout
      bannerContent={<BannersCarouselContainer deviceHint={deviceHint} banners={banners} onImageLoaded={onPageReady} />}
      hideHeader={isFromMobileApp}
      hideFooter={isFromMobileApp || !isCarAlreadyFetched}
      hideSocialChat={!width}
      hideBanner={isFromMobileApp}
      isStickTemporaryBanner={isStickTemporaryBanner}
      unStickTemporaryBanner={resetSticky}
      isTemporaryBannerToTop={isTemporaryBannerToTop}
    >
      <HeadMeta metaKey="home" region={(countryCode || region) as TRegionKey} metaData={metaData} />
      <StyleWrapper>
        <StyledStickySearchBar
          className={
            isDesktopView
              ? `sticky-filter-desktop ${isFilterStickyClass} ${stickyControlClass}`
              : `sticky-filter-mobile ${isFilterMobileStickyClass} ${stickyControlMobileClass} ${
                  isFromMobileApp ? 'no-navbar' : ''
                }`
          }
          ref={filterRef}
          country={countryCode}
          isStickTemporaryBanner={isStickTemporaryBanner}
          stickyTopPosition={stickyTopPosition}
        >
          <StyledContainer>
            <StyledHomeSearch id="search-experience">
              {width && (
                <HomeSearch
                  currentSortBy={currentSortBy}
                  onFilterSave={handleOnFilterSave}
                  onSearch={handleOnSearchSubmit}
                  onClearAll={handleClearAllFilters}
                />
              )}
            </StyledHomeSearch>

            <StyledFilterContainer id="filter-wrapper">
              {countryCode && (
                <Row>
                  <Col span={24}>
                    {isDesktopView && (
                      <StyledSortFilterBarDesktop>
                        <FiltersDesktopContainer
                          language={currentLang}
                          countryCode={countryCode}
                          onFilterSave={handleOnFilterSave}
                          onClearAllFilters={handleClearAllFilters}
                        />
                        <OverridePopoverStyle
                          popOverTopValue={popOverTopValue}
                          isFilterSticky={isFilterStickyClass === 'sticky' && stickyControlClass === 'sticky-show'}
                        />
                      </StyledSortFilterBarDesktop>
                    )}
                    {isMobileView && (
                      <StyledSortFilterBarMobile>
                        <FiltersMobileContainer
                          language={currentLang}
                          countryCode={countryCode}
                          onFilterSave={handleOnFilterSave}
                          onClearAllFilters={handleClearAllFilters}
                        >
                          <SortByMobileContainer
                            hasBorder
                            hasShadow={false}
                            countryCode={countryCode}
                            currentSortBy={currentSortBy}
                            onConfirm={handleChangeSortBy}
                          />
                        </FiltersMobileContainer>
                      </StyledSortFilterBarMobile>
                    )}
                  </Col>
                </Row>
              )}
            </StyledFilterContainer>
          </StyledContainer>
        </StyledStickySearchBar>

        <StyledContainer ref={contentRef}>
          <StyledFilterBottomContainer>
            {countryCode && (
              <Row>
                <Col span={24}>
                  {isDesktopView && (
                    <StyledSortByDesktopWrapper>
                      <ResultLocationWrapperContainer>
                        <HitCarResult language={currentLang} total={totalCarsForSale} />
                        {/* SG, TW, JP doesn't have filter location */}
                        {!['sg', 'jp', 'hk'].includes(countryCode) && (
                          <FilterLocationButton
                            onClose={handleOnCitiFilterClose}
                            onConfirm={handleOnConfirm}
                            countryCode={countryCode}
                            onInitFinished={onInitLocationPopupFinished}
                          />
                        )}
                      </ResultLocationWrapperContainer>
                      <SortByMobileContainer
                        countryCode={countryCode}
                        currentSortBy={currentSortBy}
                        onConfirm={handleChangeSortBy}
                        hasBorder
                        hasShadow={false}
                      />
                    </StyledSortByDesktopWrapper>
                  )}
                  {isMobileView && (
                    <ResultLocationWrapperContainer>
                      <HitCarResult language={currentLang} total={totalCarsForSale} />
                      {!['sg', 'jp', 'hk'].includes(countryCode) && (
                        <FilterLocationButton
                          onClose={handleOnCitiFilterClose}
                          onConfirm={handleOnConfirm}
                          countryCode={countryCode}
                          onInitFinished={onInitLocationPopupFinished}
                        />
                      )}
                    </ResultLocationWrapperContainer>
                  )}
                </Col>
              </Row>
            )}
          </StyledFilterBottomContainer>
          <Row gutter={carCardGutters}>
            {!isFetchingCars ? (
              cars.map((car: any, index) => (
                <Fragment key={car?.listing?.slug}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }}>
                    <LazyRenderCard defaultRender={hasBanner ? index < 3 : index < 6}>
                      {renderCarCards(car, hasBanner ? index < 3 : index < 6)}
                    </LazyRenderCard>
                  </Col>
                  {renderAdditionalCards(index)}
                </Fragment>
              ))
            ) : (
              <CarCardSkeleton />
            )}
          </Row>

          <StyledCarsContainer>
            {cars.length > 0 && (
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <BrowseMoreButton
                    onBrowseMoreClick={handleBrowseMoreButton}
                    isLoading={isBrowseMoreLoading}
                    total={total}
                    currentShowingItemsCount={page * HIT_PER_PAGE > total ? total : page * HIT_PER_PAGE}
                    hasBrowseMoreButton={page * HIT_PER_PAGE < total}
                  />
                </Col>
              </Row>
            )}
            {isCarAlreadyFetched && <BuyHuntContainer lang={currentLang} />}
          </StyledCarsContainer>
        </StyledContainer>
      </StyleWrapper>
    </Layout>
  );
};

(Home as any).pageReady = false;

export default Home;
