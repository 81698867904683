import { Col, Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

import useWindowResize from '@source/hooks/useWindowResize';

const StyledSkeletonButtonWrapper = styled.div`
  background: ${(props) => props.theme.color.surfaceLight};
  height: 462px;
  border: 1px solid rgb(237, 237, 237);
  box-shadow: rgb(33 33 33 / 15%) 0px 4px 15px;
  border-radius: 12px;

  .ant-skeleton-button {
    &.image-skeleton {
      height: 193px;
    }
  }
`;

const NUMBER_OF_CAR_CARDS_SKELETON = 6;
const NUMBER_OF_CAR_CARDS_MOBILE_SKELETON = 2;

const CarCardSkeleton = () => {
  const [width] = useWindowResize();
  let numberOfCards = 0;

  if (width && width >= 1280) {
    numberOfCards = NUMBER_OF_CAR_CARDS_SKELETON;
  } else if (width && width < 1280) {
    numberOfCards = NUMBER_OF_CAR_CARDS_MOBILE_SKELETON;
  }

  const skeletonCards = Array.from(Array(numberOfCards).keys());

  return (
    <>
      {skeletonCards.map((item) => (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
          key={item}
        >
          <StyledSkeletonButtonWrapper>
            <Skeleton.Button className="image-skeleton mb-20" size="large" shape="default" block active />
            <Skeleton className="paragraph-skeleton pl-8 pr-8" paragraph={{ rows: 4 }} active />
          </StyledSkeletonButtonWrapper>
        </Col>
      ))}
    </>
  );
};

export default CarCardSkeleton;
