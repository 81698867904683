import { CheckCircleFilled } from '@ant-design/icons';
import { getStaticCDN } from '@design-system/utils/utils';
// import type { Content } from '@source/components/CarroCertifiedCard/CarroCertifiedCardProps';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { TLanguageKey } from '@source/interface';

// interface ObjectKey {
//   [key: string]: Content;
// }

const backgroundImages = {
  bgImage1280: getStaticCDN('/assets/shared/browse/carro-certified/carro-certified-bg-1280-1920-v3.png'),
  bgImage992: getStaticCDN('/assets/shared/browse/carro-certified/carro-certified-bg-992-v3.png'),
  bgImage768: getStaticCDN('/assets/shared/browse/carro-certified/carro-certified-bg-768-v3.png'),
  bgImage576: getStaticCDN('/assets/shared/browse/carro-certified/carro-certified-bg-576-v3.png'),
  bgImage375: getStaticCDN('/assets/shared/browse/carro-certified/carro-certified-bg-375-v3.png'),
};

// eslint-disable-next-line import/prefer-default-export
export const CARRO_CERTIFIED_CARD_CONTENT = (lang: TLanguageKey) => ({
  sg: {
    backgroundImages,
    title: 'shared.certified_section.certified',
    isTitleLine: true,
    body: [
      {
        key: 'inspection',
        text: {
          desktop: 'shared.certified_section.pointInspectionDesktop',
          mobile: 'shared.certified_section.pointInspectionMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/inspection-160-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'guarantee',
        text: {
          desktop: 'shared.certified_section.moneyBackGuaranteeDesktop',
          mobile: 'shared.certified_section.moneyBackGuaranteeMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/guarantee-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'warranty',
        text: {
          desktop: 'shared.certified_section.warrantyEngineGearboxDesktop',
          mobile: 'shared.certified_section.warrantyEngineGearboxMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/warranty-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'mileage',
        text: {
          desktop: 'shared.certified_section.noMileageTamperingDesktop',
          mobile: 'shared.certified_section.noMileageTamperingMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/mileage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'accidents',
        text: {
          desktop: 'shared.certified_section.noMajorAccidentDesktop',
          mobile: 'shared.certified_section.noMajorAccidentMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/damage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
    ],
    linkUrl: URL_CONFIG({ region: 'sg', lang, path: 'carro-certified' }).formatted,
  },
  id: {
    backgroundImages,
    title: 'shared.certified_section.certified',
    isTitleLine: true,
    body: [
      {
        key: 'inspeksi',
        text: {
          desktop: 'shared.certified_section.pointInspectionDesktop',
          mobile: 'shared.certified_section.pointInspectionMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/inspection-160.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'garansi',
        text: {
          desktop: 'shared.certified_section.moneyBackGuaranteeDesktop',
          mobile: 'shared.certified_section.moneyBackGuaranteeMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/guarantee.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'tahun',
        text: {
          desktop: 'shared.certified_section.warrantyEngineGearboxDesktop',
          mobile: 'shared.certified_section.warrantyEngineGearboxMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/warranty.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'manipulasi',
        text: {
          desktop: 'shared.certified_section.noMileageTamperingDesktop',
          mobile: 'shared.certified_section.noMileageTamperingMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/mileage.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'banjir',
        text: {
          desktop: 'shared.certified_section.noMajorAccidentDesktop',
          mobile: 'shared.certified_section.noMajorAccidentMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/damage.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
    ],
    linkUrl: URL_CONFIG({ region: 'id', lang, path: 'carro-certified' }).formatted,
  },
  th: {
    backgroundImages,
    title: 'shared.certified_section.certified',
    isTitleLine: true,
    body: [
      {
        key: 'inspection',
        text: {
          desktop: 'shared.certified_section.pointInspectionDesktop',
          mobile: 'shared.certified_section.pointInspectionMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/inspection-160.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'guarantee',
        text: {
          desktop: 'shared.certified_section.moneyBackGuaranteeDesktop',
          mobile: 'shared.certified_section.moneyBackGuaranteeMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/my/browse/carro-certified/guarantee.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'warranty',
        text: {
          desktop: 'shared.certified_section.warrantyEngineGearboxDesktop',
          mobile: 'shared.certified_section.warrantyEngineGearboxMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/my/browse/carro-certified/warranty.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'mileage',
        text: {
          desktop: 'shared.certified_section.noMileageTamperingDesktop',
          mobile: 'shared.certified_section.noMileageTamperingMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/my/browse/carro-certified/mileage.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'damage',
        text: {
          desktop: 'shared.certified_section.noMajorAccidentDesktop',
          mobile: 'shared.certified_section.noMajorAccidentMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/my/browse/carro-certified/damage.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
    ],
    linkUrl: URL_CONFIG({ region: 'th', lang, path: 'carro-certified' }).formatted,
  },
  my: {
    backgroundImages,
    title: 'shared.certified_section.myCertified',
    isTitleLine: true,
    body: [
      {
        key: 'inspection',
        text: {
          desktop: 'shared.certified_section.pointInspectionDesktop',
          mobile: 'shared.certified_section.pointInspectionMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/inspection-160-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'guarantee',
        text: {
          desktop: 'shared.certified_section.moneyBackGuaranteeDesktop',
          mobile: 'shared.certified_section.moneyBackGuaranteeMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/guarantee-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'warranty',
        text: {
          desktop: 'shared.certified_section.warrantyEngineGearboxDesktop',
          mobile: 'shared.certified_section.warrantyEngineGearboxMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/warranty-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'mileage',
        text: {
          desktop: 'shared.certified_section.noMileageTamperingDesktop',
          mobile: 'shared.certified_section.noMileageTamperingMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/mileage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'damage',
        text: {
          desktop: 'shared.certified_section.noMajorAccidentDesktop',
          mobile: 'shared.certified_section.noMajorAccidentMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/damage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
    ],
    linkUrl: URL_CONFIG({ region: 'my', lang, path: 'carro-certified' }).formatted,
  },
  jp: {
    backgroundImages,
    title: 'shared.certified_section.certified',
    isTitleLine: true,
    body: [
      {
        key: 'inspection',
        text: {
          desktop: 'shared.certified_section.pointInspectionDesktop',
          mobile: 'shared.certified_section.pointInspectionMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/inspection-160-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'guarantee',
        text: {
          desktop: 'shared.certified_section.moneyBackGuaranteeDesktop',
          mobile: 'shared.certified_section.moneyBackGuaranteeMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/guarantee-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'warranty',
        text: {
          desktop: 'shared.certified_section.warrantyEngineGearboxDesktop',
          mobile: 'shared.certified_section.warrantyEngineGearboxMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/warranty-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'mileage',
        text: {
          desktop: 'shared.certified_section.noMileageTamperingDesktop',
          mobile: 'shared.certified_section.noMileageTamperingMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/mileage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'damage',
        text: {
          desktop: 'shared.certified_section.noMajorAccidentDesktop',
          mobile: 'shared.certified_section.noMajorAccidentMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/damage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
    ],
    linkUrl: URL_CONFIG({ region: 'jp', lang, path: 'carro-certified' }).formatted,
  },
  hk: {
    backgroundImages,
    title: 'shared.certified_section.certified',
    isTitleLine: true,
    body: [
      {
        key: 'inspection',
        text: {
          desktop: 'shared.certified_section.pointInspectionDesktop',
          mobile: 'shared.certified_section.pointInspectionMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/inspection-160-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'guarantee',
        text: {
          desktop: 'shared.certified_section.returnPolicyDesktop',
          mobile: 'shared.certified_section.returnPolicyMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/guarantee-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'warranty',
        text: {
          desktop: 'shared.certified_section.warrantyEngineGearboxDesktop',
          mobile: 'shared.certified_section.warrantyEngineGearboxMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/warranty-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'mileage',
        text: {
          desktop: 'shared.certified_section.noMileageTamperingDesktop',
          mobile: 'shared.certified_section.noMileageTamperingMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/mileage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
      {
        key: 'accidents',
        text: {
          desktop: 'shared.certified_section.noMajorAccidentDesktop',
          mobile: 'shared.certified_section.noMajorAccidentMobile',
        },
        icon: {
          desktop: getStaticCDN('/assets/shared/browse/carro-certified/damage-v2.svg'),
          mobile: <CheckCircleFilled />,
        },
      },
    ],
    linkUrl: URL_CONFIG({ region: 'hk', lang, path: 'carro-certified' }).formatted,
  },
});
