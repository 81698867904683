/* eslint-disable react/require-default-props */
import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';
import difference from 'lodash/difference';
import { CheckboxGroupProps, CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';

import type { DefaultOptionProps, FilterProps } from '@design-system/components/FiltersV1/FilterProps';

const CheckboxGroup = Checkbox.Group;

type FilterCheckBoxProps = {
  path?: string;
  filter?: FilterProps;
  payload?: FilterProps;
  onSelect?: (value: Record<string, unknown>, path: string) => void;
  childRef?: any;
};

interface SCCheckboxGroupProps extends CheckboxGroupProps {
  isValueSelected: boolean;
}

const StyledCheckboxGroup = styled(CheckboxGroup)<SCCheckboxGroupProps>`
  display: block;

  .ant-checkbox-group-item {
    margin-bottom: 8px;
  }

  .ant-checkbox-wrapper-checked {
    opacity: 1;
  }

  .ant-checkbox-group-item {
    display: flex;
    ${(props) =>
      props.isValueSelected &&
      css`
        opacity: 0.3;
      `}

    &:hover {
      opacity: 1;
    }

    .ant-checkbox {
      margin-right: 8px;
      &-inner {
        border-radius: 5px;
      }
    }
  }

  .ant-checkbox-checked {
    &::after {
      border-color: ${(props) => props.theme.color.primaryBase};
      border-radius: 5px;
    }
    .ant-checkbox-inner {
      border-color: ${(props) => props.theme.color.primaryBase};
      background-color: ${(props) => props.theme.color.primaryBase};
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.color.primaryBase};
  }
`;

const FilterCheckbox = ({ path, filter, payload, onSelect, childRef }: FilterCheckBoxProps) => {
  const selectedOptions = filter?.selectedOptions || [];
  const options = payload?.options || [];
  const newOptions = options as Array<CheckboxOptionType | string | number>;

  const isNumeric = filter?.isNumeric;

  const formatSelectedOptions = (opts: DefaultOptionProps[]) =>
    opts?.map((opt) => (isNumeric ? parseInt(`${opt.value || ''}`, 10) : opt.value)) as Array<
      CheckboxValueType | string | number
    >;

  const [selectValue, setSelectValue] = useState(formatSelectedOptions(selectedOptions) || []);
  const isValueSelected = filter?.canSelectOneOnly && selectValue.length >= 1;

  useEffect(() => {
    setSelectValue(formatSelectedOptions(selectedOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useImperativeHandle(childRef, () => ({
    clearSelectValue() {
      setSelectValue([]);
    },
  }));

  const onCheckboxGroupChange = (values: CheckboxValueType[]) => {
    let newSelectValues = values;

    if (filter?.canSelectOneOnly) {
      newSelectValues = difference(values, selectValue);
    }

    const newSelectedOptions = newSelectValues.map((v: CheckboxValueType) =>
      options.find((opt: DefaultOptionProps) => opt.value === v),
    );

    const newPayload = {
      ...payload,
      selectedOptions: newSelectedOptions,
    };

    setSelectValue(newSelectValues);
    if (onSelect) onSelect(newPayload, path || 'fake_path');
  };

  return (
    <div>
      <StyledCheckboxGroup
        isValueSelected={!!isValueSelected}
        options={newOptions}
        value={selectValue}
        onChange={onCheckboxGroupChange}
      />
    </div>
  );
};

export default forwardRef(FilterCheckbox);
