import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

const StyledCarousel = styled.div``;

const StyledCarouselItem = styled.div``;

interface CarouselItemProps extends PropsWithChildren {
  onClickCapture: React.MouseEventHandler<HTMLDivElement>;
}

export const CarouselItem: FC<CarouselItemProps> = ({ children, onClickCapture }) => (
  <StyledCarouselItem onClickCapture={onClickCapture}>{children}</StyledCarouselItem>
);

const Carousel: FC<PropsWithChildren> = ({ children }) => {
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, []);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, []);

  const handleOnItemClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (dragging) {
        e.stopPropagation();
      }
    },
    [dragging],
  );

  return (
    <StyledCarousel>
      <Slider
        dots
        arrows={false}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={8000}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
      >
        {React.Children.map(children, (child) => (
          <CarouselItem onClickCapture={handleOnItemClick}>{child}</CarouselItem>
        ))}
      </Slider>
    </StyledCarousel>
  );
};

export default Carousel;
