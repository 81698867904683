import Icon, { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import React, { FC, ReactNode } from 'react';

const getNodes = (str: string) => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!window.DOMParser) {
    return null;
  }

  const parser = new DOMParser();

  return parser.parseFromString(str, 'text/html').body.childNodes;
};

const mappedIcons: { [key: string]: typeof Icon } = {
  CheckCircleFilled,
  PlusOutlined,
};

export const createJSX = (nodeArray: ChildNode[]) =>
  nodeArray.map((node): ReactNode => {
    const attributeObj: { [key: string]: any } = {};
    const { attributes, localName, childNodes, nodeValue } = node as HTMLElement;

    let iconElement;

    if (attributes) {
      Array.from(attributes).forEach((attribute) => {
        if (attribute.name === 'style') {
          const styleAttributes = attribute.nodeValue?.split(';');
          const styleObj: { [key: string]: string } = {};
          styleAttributes?.forEach((styleAttribute) => {
            const [key, value] = styleAttribute.split(':');
            styleObj[key] = value;
          });
          attributeObj[attribute.name] = styleObj;
        } else {
          attributeObj[attribute.name] = attribute.nodeValue;
        }
      });
    }

    if (localName === 'icon') {
      iconElement = mappedIcons[attributeObj['data-id']];
    }

    if (localName === 'br') {
      return React.createElement(localName, null);
    }

    return localName
      ? React.createElement(
          iconElement || localName,
          attributeObj,
          childNodes && Array.isArray(Array.from(childNodes)) ? createJSX(Array.from(childNodes)) : [],
        )
      : nodeValue;
  });

type StringToJSXProps = { domString: string };
export const StringToJSX: FC<StringToJSXProps> = ({ domString }) => {
  const nodes = getNodes(domString);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!nodes) return <></>;

  return <>{createJSX(Array.from(nodes))}</>;
};
