import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import type { FilterCustomButtonProps } from 'design-system/components/FiltersV1/FilterCustomButtonProps.d';

const StyledButton = styled(Button)<FilterCustomButtonProps>`
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  ${(props) => props.theme.typo.style.body};
  align-items: center;
  background-color: ${(props) => props.theme.color.background};
  border-radius: 12px;
  border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`};
  color: ${(props) => props.theme.color.onSurface};
  display: flex;
  height: ${(props) => (props.size === 'large' ? '42px' : '32px')};
  justify-content: center;
  padding-inline: ${(props) => (props.size === 'large' ? '16px' : '10px')};

  &:hover,
  &:focus {
    border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`};
    box-shadow: none;
    color: ${(props) => props.theme.color.onSurface};
  }

  &:disabled {
    background-color: ${(props) => props.theme.color.onBackgroundLowEmphasis}!important;
    border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`}!important;
    color: ${(props) => props.theme.color.background}!important;
  }

  &.active {
    background-color: ${(props) => props.theme.color.primaryBase};
    border: 1px solid ${(props) => props.theme.color.primaryBase};
    box-shadow: none;
    color: ${(props) => props.theme.color.background};
  }
  ${(props) => props?.customStyles}
`;

const FilterCustomButton = ({
  className,
  type,
  children,
  disabled,
  size,
  variant,
  isActive,
  customStyles,
  onClick,
}: FilterCustomButtonProps) => (
  <StyledButton
    size={size}
    disabled={disabled}
    onClick={onClick}
    type={type}
    customStyles={customStyles}
    className={`${className} ${isActive && 'active'}`}
    variant={variant}
  >
    {children}
  </StyledButton>
);

FilterCustomButton.defaultProps = {
  isActive: false,
  size: 'large',
  variant: 'outline',
  disabled: false,
  className: '',
  onClick: () => {}, // eslint-disable-line
  children: <div />,
};

export default FilterCustomButton;
