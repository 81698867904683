import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import i18next from 'i18next';
import styled from 'styled-components';
import Image from 'next/image';
import { Modal, Drawer } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import useAlgoliaService from '@source/hooks/useAlgoliaService';
import useBrowseServices from '@source/hooks/useBrowseServices';

import { buildAlgoliaFilter, buildAlgoliaFilterCarsForSale, buildLocationAlgoliaQuery } from '@source/services/Algolia';

import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEYS } from '@source/constants/common';
import locationIcon from '@source/pages/Home/assets/images/location.svg';

import useWindowResize from '@source/hooks/useWindowResize';
import FilterButton, { LocationOptionProps } from '@source/pages/Home/components/FilterLocationButton/FilterButton';
import FormFooter from '@source/pages/Home/components/FormFooter';
import CustomButton from '@source/components/CustomButton/CustomButton';
import { FILTER_CITY_PARAM } from '@source/pages/Home/constants';
import { TRegionKey } from '@source/interface';
import { getOptionsInFacets } from '@design-system/utils/filters';
import { useAppSelector } from '@source/hooks/useStateHook';
import { shallowEqual } from 'react-redux';
import { usePopupService } from '@source/pages/Home/hooks/usePopupService';

// const Drawer = dynamic(() => import('antd/lib/drawer'));
// const Modal = dynamic(() => import('antd/lib/modal'));

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
  }

  .ant-modal-body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
`;

const StyledFilterBtn = styled(CustomButton)`
  border: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
  background: transparent;
  font-style: normal;
  color: ${(props) => props.theme.color.primaryBase};
  text-transform: capitalize !important;

  .filter-location-text {
    padding-left: 8px;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.1);
  padding: 22px;

  .header-title {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;

    .clear-btn {
      font-weight: 700;
      font-size: 14px;
      color: ${(props) => props.theme.color.primaryBase};
      border: none;
      box-shadow: none;
      text-transform: capitalize;
      height: unset;
      justify-self: flex-start;
      padding: 0;
    }

    .modal-title-text {
      grid-column-start: 2;
    }
  }
`;

const StyledContent = styled.div`
  height: calc(100% - 154px);
  padding: 24px;
  overflow: auto;
  margin-bottom: 60px;

  .content-header-wrapper {
    .title {
      font-weight: 700;
      font-size: 38px;
      color: ${(props) => props.theme.color.primaryBase};
      ${(props) => props.theme.typo.familyGoogle.bold};
      line-height: 46px;
      font-style: normal;
    }

    .desc {
      width: 293px;
      font-weight: 400;
      font-size: 14px;
      color: #a1a1a1;
      ${(props) => props.theme.typo.familyGoogle.regular};
      line-height: 22px;
      font-style: normal;
    }
  }

  @media only screen and (min-width: 576px) {
    padding: 24px 32px;
  }

  @media only screen and (min-width: 768px) {
    padding: 24px 40px;

    .content-header-wrapper {
      .desc {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.xl}) {
    height: calc(80vh - 154px);
  }
`;

const StyledCloseIcon = styled(CloseCircleOutlined)`
  font-size: 21px;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;
export interface FilterLocationButtonProps {
  headerTitle?: string;
  contentTitle?: string;
  contentDesc?: string;
  countryCode: string;
  onConfirm: (hits: any) => void;
  onClose: () => void;
  onInitFinished?: () => void;
}

const FilterLocationButton: FC<FilterLocationButtonProps> = (props) => {
  const { headerTitle, contentTitle, contentDesc, countryCode, onConfirm, onClose, onInitFinished } = props;
  const { t } = useTranslation();
  const { fetchAlgoliaData } = useAlgoliaService();
  const currentLang = i18next.language;
  const router = useRouter();
  const { closeLocationPopup } = usePopupService();
  const modalConfig = useAppSelector((state) => state.modal, shallowEqual);

  // Use useWindowResize instead because we want to prevent render redundant mobile render.
  // useBreakPoint returns false at first run (although its in desktop)
  const [width] = useWindowResize();
  const isLargerThan1280 = width && width >= 1280;
  const isSmallerThan1280 = width && width < 1280;

  const { browseState, setFilterLocationOptions } = useBrowseServices();
  const { currentFilterSettings, filterLocationOptions } = browseState;

  // Is show drawer/modal
  const [showDrawer, setShowDrawer] = useState(false);

  // Selected filter location
  const [selectedOptions, setSelectedOptions] = useState<LocationOptionProps[]>([]);

  // for showing number of cars in filter button
  const [totalHit, setTotalHit] = useState(0);

  // Fetch algolia to get all location options
  const fetchLocationFilterOptions = useCallback(async () => {
    const locationFacetName = FILTER_CITY_PARAM?.[countryCode as keyof typeof FILTER_CITY_PARAM];
    const params = {
      facets: [locationFacetName],
      filters: ``,
      hitsPerPage: 0,
    };
    const { facets } = await fetchAlgoliaData(params);
    return getOptionsInFacets(facets, locationFacetName);
  }, [fetchAlgoliaData]);

  useEffect(() => {
    // Todo_Son: just temporary. May be although its fetched but be return no data.
    // So i assume it has data first
    if (isEmpty(filterLocationOptions)) {
      const getLocations = async () => {
        const resOptions = await fetchLocationFilterOptions();
        setFilterLocationOptions(resOptions);
      };

      getLocations();
    }
  }, []);

  const onCheckOpenLocationPopup = () => {
    if (!['sg']?.includes(countryCode)) {
      const isPopupPreviously = window?.sessionStorage?.getItem('isFilterLocationPopupBefore') as string;
      if (isPopupPreviously !== 'true') {
        setShowDrawer(true);
        window?.sessionStorage?.setItem('isFilterLocationPopupBefore', 'true');
      }
    }
  };
  useEffect(() => {
    // Set selected location from string
    const locationParams = router.query.locationIds as string;
    const selectedLocationString = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATION);

    const searchParams = new URLSearchParams(window.location.search);

    if (locationParams) {
      const locationIds = locationParams.split(',');
      const locationsFromParams = filterLocationOptions.filter((option) => locationIds.includes(option.value));
      localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATION, JSON.stringify(locationsFromParams));
      setSelectedOptions(locationsFromParams);
      onConfirm(locationsFromParams);
      setShowDrawer(false);
      return;
    }

    if (selectedLocationString) {
      const parsedSelectedLocation = JSON.parse(selectedLocationString) as LocationOptionProps[];
      setSelectedOptions(parsedSelectedLocation);
      if (parsedSelectedLocation.length > 0) {
        searchParams.set('locationIds', parsedSelectedLocation.map((location) => location.value).join(','));

        router.replace({ pathname: window.location.pathname, search: decodeURIComponent(searchParams.toString()) });
      }
      onConfirm(parsedSelectedLocation);
      setShowDrawer(false);
      return;
    }

    if (onInitFinished) {
      onInitFinished();
    }
    // if (!['sg']?.includes(countryCode)) {
    //   const isPopupPreviously = window?.sessionStorage?.getItem('isFilterLocationPopupBefore') as string;
    //   if (isPopupPreviously !== 'true') {
    //     setShowDrawer(true);
    //     window?.sessionStorage?.setItem('isFilterLocationPopupBefore', 'true');
    //   }
    // }
  }, [countryCode, currentLang, filterLocationOptions, router]);
  // check location popup after closing FirstTimeAccessPopup in home page
  useEffect(() => {
    if (modalConfig && modalConfig?.isOpenLocationModal === true) {
      // onCheckOpenLocationPopup();
      setShowDrawer(true);
    }
  }, [modalConfig]);

  // Get new location query which includes
  // - query from outside filter
  // - query for current selected filters
  // - query for getting available cars for
  const newLocationQuery = useMemo(() => {
    const locationFilterQuery = buildLocationAlgoliaQuery(
      selectedOptions,
      get(FILTER_CITY_PARAM, countryCode || 'sg'),
      countryCode as TRegionKey,
    );
    const filterQuery = buildAlgoliaFilter(currentFilterSettings);
    const newFilters = [filterQuery, locationFilterQuery, buildAlgoliaFilterCarsForSale()]
      .filter((i) => !isEmpty(i))
      .join(' AND ');

    return newFilters;
  }, [currentFilterSettings, countryCode, selectedOptions]);

  // Fetch hits for showing number of cars in filter button (Notes: The nbHits for sale not all hits)
  const fetchHitsByLocation = useCallback(
    async (page = 1, hitsPerPage = 24) => {
      const params = {
        page: page - 1, // since algolia Page numbers are zero-based
        facets: undefined,
        filters: newLocationQuery,
        hitsPerPage,
        attributesToRetrieve: undefined, // Only need to fetch nbHits
      };

      const { nbHits } = await fetchAlgoliaData(params, { field: 'latest', order: 'asc' });
      setTotalHit(nbHits);

      return { nbHits };
    },
    [newLocationQuery],
  );

  // If change selectedOption or country => fetch data
  // useEffect dependency still understands the empty array has changed so I convert it to string to avoid redundant calls
  useEffect(() => {
    fetchHitsByLocation();
  }, [JSON.stringify(selectedOptions), countryCode]);

  const handleClose = () => {
    if (selectedOptions.length === 0) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATION, JSON.stringify([]));
    }
    setShowDrawer(false);
    closeLocationPopup();
  };

  const handleShow = () => {
    setShowDrawer(true);
  };

  // Handle on clicking confirm button in modal/drawer
  const handleConfirm = async () => {
    handleClose();
    onConfirm(selectedOptions);
    const locationParamsToInsert = selectedOptions.map((location) => location.value).join(',');
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('locationIds');

    if (locationParamsToInsert) {
      searchParams.set('locationIds', locationParamsToInsert);
    }

    router.replace({ pathname: window.location.pathname, search: decodeURIComponent(searchParams.toString()) });

    window.scrollTo(0, 0);
  };

  const handleFilterBtnClick = () => {
    handleShow();
  };

  const handleCloseBtnClick = () => {
    handleClose();
    onClose();
  };

  const handleOnButtonSelect = async (selected: LocationOptionProps[]) => {
    setSelectedOptions(selected);
  };

  const getTranslateText = (label: string) => {
    switch (label) {
      case 'don-mueang':
        return t('home.location_filter.locationTranslationText.donMueang');
      case 'kaset-nawamin':
        return t('home.location_filter.locationTranslationText.kasetNawamin');
      case 'suanluang-rama-9-/-srinakarin':
      case 'carro-automall---sy3-สวนหลวง':
        return t('home.location_filter.locationTranslationText.suanluang');
      case 'ชลบุรี':
        return t('home.location_filter.locationTranslationText.chonburi');
      case 'carro-automall---am-ks':
        return t('home.location_filter.locationTranslationText.kaset');
      case 'อยุธยา':
        return t('home.location_filter.locationTranslationText.ayutthaya');
      case 'carro-automall---tiwanon':
        return t('home.location_filter.locationTranslationText.tiwanon');
      case 'carro-automall---king-keaw-road':
        return t('home.location_filter.locationTranslationText.kingKeawRoad');
      default:
        return label;
    }
  };
  const renderFilters = () => (
    <>
      <StyledHeader>
        <div className="header-title">
          <span className="modal-title-text">{headerTitle || t('home.location_filter.locationHeader')}</span>
          <StyledCloseIcon onClick={handleCloseBtnClick} />
        </div>
      </StyledHeader>
      <StyledContent>
        <div className="content-header-wrapper">
          <h2 className="title">{contentTitle || t('home.location_filter.locationContentTitle')}</h2>
          <p className="desc">{contentDesc || t('home.location_filter.locationContentDesc')}</p>
        </div>
        <FilterButton
          filter={{ options: filterLocationOptions, selectedOptions }}
          colResponsive={{
            xs: 12,
          }}
          countryCode={countryCode}
          onSelect={handleOnButtonSelect}
          getTranslateText={getTranslateText}
        />
      </StyledContent>
      <FormFooter
        confirmText={t('home.location_filter.locationConfirmButtonTitle', { totalHit })}
        onConfirm={handleConfirm}
        customButtonCss={{ width: '100%' }}
      />
    </>
  );

  return (
    <>
      <StyledFilterBtn onClick={handleFilterBtnClick} size="small" variant="outline">
        <Image src={locationIcon} width={16} height={16} alt="" />
        <span className="filter-location-text">
          {selectedOptions?.length === 0 && t('home.location_filter.allLocation')}
          {selectedOptions?.length === 1 &&
            getTranslateText(countryCode === 'th' ? selectedOptions[0].value : selectedOptions[0].label)}
          {selectedOptions?.length > 1 && `${selectedOptions?.length} ${t('home.location_filter.locationText')}`}
        </span>
      </StyledFilterBtn>

      {isLargerThan1280 && showDrawer && (
        <StyledModal
          bodyStyle={{
            maxHeight: 'calc(0.8*100vh)',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            position: 'relative',
            padding: 0,
          }}
          visible={showDrawer}
          footer={null}
          closable={false}
          width={768}
          onCancel={handleClose}
        >
          {showDrawer && renderFilters()}
        </StyledModal>
      )}

      {isSmallerThan1280 && showDrawer && (
        <Drawer
          bodyStyle={{ padding: 0 }}
          height="100%"
          placement="bottom"
          closable={false}
          onClose={handleClose}
          visible={showDrawer}
          closeIcon={<CloseCircleOutlined />}
        >
          {showDrawer && renderFilters()}
        </Drawer>
      )}
    </>
  );
};

FilterLocationButton.defaultProps = {
  headerTitle: '',
  contentTitle: '',
  contentDesc: '',
};

export default FilterLocationButton;
