import React, { FC } from 'react';
import styled from 'styled-components';
import CustomButton from '@source/components/CustomButton/CustomButton';

export interface LessAwesomeButtonProps {
  type: string;
  shape: string;
  size: string;
  customStyles: object;
  onClick: () => void;
  children: any;
  variant?: string;
}

const StyledButton = styled(CustomButton)<any>`
  font-size: 12px;
  outline: none;
  margin: 5px;
  border-radius: 65px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    box-shadow: unset;
  }

  &.less-awesome-primary,
  &.less-awesome-outline,
  &.less-awesome-primary-outline {
    background-color: #f7f7f7;
    color: #6e6e6e;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.regular};

    &:hover,
    &:active,
    &:focus {
      border: #d4d4d4;
      color: black;
      transition: all 0.4s ease;
    }
  }

  &.less-awesome-primary-outline {
    border: 1px solid #d4d4d4 !important;
    background-color: #fff;
  }

  &.less-awesome-inactive {
    opacity: 0.3;
    background-color: #f7f7f7;
    color: #6e6e6e;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.regular};

    &:hover,
    &:active,
    &:focus {
      border: #d4d4d4;
      color: black;
      transition: all 0.4s ease;
      opacity: 1;
    }
  }

  &.less-awesome-outline-active {
    border: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
    background-color: #ffffff;
  }

  &.less-awesome-primary-outline-active {
    color: #fff;
    border: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
    background: ${(props) => props.theme.color.primaryBase};
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }

  &.less-awesome-active {
    background: #fff2eb;
    color: #ff5722;
    border: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }

  &.less-awesome-clear {
    background-color: #ffffff;
    text-decoration: underline;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.semiBold};

    &:hover {
      background-color: #f0f0f0;
      color: black;
      transition: all 0.4s ease;
    }
  }

  ${(props) => props.customStyles}

  .text-one-line {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// type - primary, active, clear
// shape - rectangle
// children - button text
// size - sm, md, lg
/*
    Custom Styles example:
    const GlobalStyles = css`
      fontFamily: ${props => props.theme.font};
      fontSize: ${props => props.theme.fontSizeSubtitle};
    `
*/
const LessAwesomeButton: FC<LessAwesomeButtonProps> = ({
  type,
  customStyles,
  size,
  shape,
  onClick,
  variant,
  children,
}) => (
  <StyledButton
    customStyles={customStyles}
    size={size}
    className={`less-awesome-${type}`}
    shape={shape}
    onClick={onClick}
    variant={variant}
  >
    {children}
  </StyledButton>
);

LessAwesomeButton.defaultProps = {
  variant: 'contained',
};

export default LessAwesomeButton;
