import React, { useMemo } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { EXCLUSIVE_LABELS } from '@source/pages/Home/components/ExclusiveLabelsBanner/constants';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useMobileView from '@design-system/hooks/useMobileView';

const StyledWrapper = styled.div`
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px rgba(33, 33, 33, 0.15);

  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 30px;
    gap: 20px;
    flex-direction: column;
    text-align: center;

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.md}) {
      flex-direction: row;
      padding-block: 40px;
      text-align: unset;
      gap: 38px;
    }

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
      padding-block: 33px;
      gap: 27px;
    }

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.xl}) {
      padding-block: 38px;
      gap: 108px;
    }

    .title {
      color: ${(props) => props.theme.color.lightOrange};
      ${(props) => props.theme.typo.familyGoogle.bold};
      font-size: 20px;
      line-height: 28px;

      @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.md}) {
        font-size: 32px;
        margin-bottom: 20px;
      }

      @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
        font-size: 36px;
        line-height: 22px;
        margin-bottom: 23px;
      }
    }

    .subTitle {
      font-family: Poppins;
      ${(props) => props.theme.typo.familyGoogle.medium};
      font-size: 12px;
      line-height: 20px;

      @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.md}) {
        font-size: 14px;
        line-height: 22px;
      }

      @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.lg}) {
        font-size: 16px;
        line-height: 22px;
      }

      .infoIcon {
        color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
        margin-left: 5px;
      }
    }
  }

  .content {
    padding: 20px 16px 24px;
    background-color: #ffede5;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.sm}) {
      padding: 20px 32px 32px;
    }

    @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.md}) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 20px 24px 32px;
      gap: 20px 16px;
    }

    .item {
      @media only screen and (min-width: ${(props) => props.theme.metrics.breakpoints.md}) {
        flex: 1 1 31%;
      }

      .name {
        ${(props) => props.theme.typo.familyGoogle.semiBold};
        margin-bottom: 10px;
        display: flex;
        gap: 4px;
        color: ${(props) => props.theme.color.primaryBase};
        align-items: center;
      }

      .description {
        ${(props) => props.theme.typo.familyGoogle.regular};
      }
    }
  }
`;

const StyledToolTipContent = styled.div`
  width: 204px;
  color: ${(props) => props.theme.color.onSurface};
  font-size: 10px;
  ${(props) => props.theme.typo.familyGoogle.medium};
  line-height: 14px;
  border-radius: 10px;
`;

const ExclusiveLabelsBanner = () => {
  const { t } = useTranslation();

  const isMobile = useMobileView(767, true);
  const isTablet = useMobileView(992, true);

  const imageSizes = useMemo(() => {
    if (isMobile) {
      return {
        width: 241,
        height: 114,
      };
    }

    if (isTablet) {
      return {
        width: 220,
        height: 98,
      };
    }

    return {
      width: 300,
      height: 134,
    };
  }, [isMobile, isTablet]);

  return (
    <StyledWrapper>
      <div className="hero">
        <Image src={EXCLUSIVE_LABELS.mainImage} alt="carro car" width={imageSizes.width} height={imageSizes.height} />

        <div className="heroText">
          <div className="title">{t(EXCLUSIVE_LABELS.title)}</div>
          <div className="subTitle">
            {t(EXCLUSIVE_LABELS.subTitle)}

            <Popover
              placement="bottom"
              content={<StyledToolTipContent>{t(EXCLUSIVE_LABELS.tooltip)}</StyledToolTipContent>}
              overlayInnerStyle={{ borderRadius: 10 }}
            >
              <InfoCircleOutlined className="infoIcon" />
            </Popover>
          </div>
        </div>
      </div>

      <div className="content">
        {EXCLUSIVE_LABELS.content.map((item) => (
          <div className="item">
            <div className="name">
              <Image src={item.image} alt={item.name} width={20} height={20} />
              {t(item.name)}
            </div>
            <div className="description">{t(item.description)}</div>
          </div>
        ))}
      </div>
    </StyledWrapper>
  );
};

export default ExclusiveLabelsBanner;
