import React, { FC } from 'react';
import { ArrowUpOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

// TYPES
import { Option } from '@source/interface/common';
import { Typography } from '@design-system/components';

// STYLES
const StyledWrapper = styled.div`
  align-items: center;
  color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  .ant-typography {
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    margin-left: 16px;
  }

  .anticon-arrow-up {
    rotate: -45deg;
  }
`;

export interface HomeSearchSuggestionItemProps {
  content: Option<string>;
  onClick: (id: string) => void;
  onIconClick: (value: string) => void;
}

const HomeSearchSuggestionItem: FC<HomeSearchSuggestionItemProps> = ({ content, onClick, onIconClick }) => {
  const handleOnClick = () => onClick(content.value);

  const handleOnIconClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    onIconClick(content.label);
  };

  return (
    <StyledWrapper onClick={handleOnClick}>
      <div>
        <SearchOutlined /> <Typography>{content.label}</Typography>
      </div>
      <ArrowUpOutlined onClick={handleOnIconClick} />
    </StyledWrapper>
  );
};

export default HomeSearchSuggestionItem;
