import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// COMPONENTS
import HomeSearchSuggestionItem from '@source/pages/Home/components/HomeSearch/HomeSearchSuggestionItem';

// TYPES
import { Option } from '@source/interface/common';

const StyledSeeMoreBtn = styled.button`
  background-color: ${(props) => props.theme.color.background};
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  cursor: pointer;
  margin-top: 16px;
  padding: 12px 16px;
  text-align: center;
  width: 100%;

  ${(props) => props.theme.typo.style.smallButtonOrLink}
`;

interface OptionsPanelProps {
  suggestions: Option<string>[];
  onSelected: (value: string) => void;
  onIconClick: (value: string) => void;
}

const DEFAULT_SHOWED_SUGGESTION_QUANTITY = 6;

const OptionsPanel: FC<OptionsPanelProps> = ({ suggestions, onSelected, onIconClick }) => {
  const { t } = useTranslation();
  const [displayOptions, setDisplayOptions] = useState<Option<string>[]>([]);
  const [isShowSeeMore, setIsShowSeeMore] = useState(suggestions.length > DEFAULT_SHOWED_SUGGESTION_QUANTITY);

  useEffect(() => {
    if (isShowSeeMore && suggestions.length > DEFAULT_SHOWED_SUGGESTION_QUANTITY) {
      setDisplayOptions(suggestions.slice(0, DEFAULT_SHOWED_SUGGESTION_QUANTITY));
    } else {
      setDisplayOptions(suggestions);
      setIsShowSeeMore(false);
    }
  }, [isShowSeeMore, suggestions]);

  const handleClickSeeMore = () => {
    setDisplayOptions(suggestions);
    setIsShowSeeMore(false);
  };

  return (
    <div className="search-overlay-section">
      <div className="search-overlay-title">{t('home.search.wouldYouBeInterestedInThese')}</div>
      <div className="search-suggestion">
        {displayOptions.map((suggestion) => (
          <HomeSearchSuggestionItem
            content={suggestion}
            onClick={(value) => onSelected(value)}
            onIconClick={onIconClick}
          />
        ))}
      </div>
      {isShowSeeMore && <StyledSeeMoreBtn onClick={handleClickSeeMore}>{t('home.search.seeMore')}</StyledSeeMoreBtn>}
    </div>
  );
};

export default OptionsPanel;
