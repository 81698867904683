import { SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import { TRegionKey } from '@source/interface';
import { MetaItemProps } from '@source/pages/Home/components/HeadMeta/HeadMetaProps';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANY_NAME } from '../constants';

interface useHeadMetaProps {
  filterSettings: SettingProps[];
  countryCode: TRegionKey;
}

const useHeadMeta = ({ filterSettings, countryCode }: useHeadMetaProps) => {
  const { t } = useTranslation();

  const [metaData, setMetaData] = useState<MetaItemProps>();

  const unsetMetaData = () => setMetaData(undefined);

  useEffect(() => {
    if (!filterSettings) {
      unsetMetaData();
      return;
    }

    const selectedFilter = filterSettings
      .filter((setting) => setting.name === 'make' || setting.name === 'make_model')
      .filter((setting) => setting.filters?.length === 1 && setting.filters[0].selectedOptions?.length === 1);

    if (!selectedFilter.length) {
      unsetMetaData();
      return;
    }

    const { filters = [] } = selectedFilter[0];
    const { selectedOptions = [] } = filters[0] || {};

    if (!selectedOptions) {
      unsetMetaData();
      return;
    }

    const { value: brandModel } = selectedOptions[0];

    const { title, metaTitle, description } = {
      title: t('home.headMeta.title', {
        brandModelName: brandModel,
        countryName: t(`home.countries.${countryCode}`),
        companyName: COMPANY_NAME[countryCode],
      }),
      metaTitle: t('home.headMeta.metaTitle', {
        brandModelName: brandModel,
        countryName: t(`home.countries.${countryCode}`),
        companyName: COMPANY_NAME[countryCode],
      }),
      description: t('home.headMeta.description', {
        brandModelName: brandModel,
        countryName: t(`home.countries.${countryCode}`),
      }),
    } as MetaItemProps;

    setMetaData({
      title,
      metaTitle,
      description,
    });
  }, [filterSettings, countryCode, t]);

  return { metaData };
};

export default useHeadMeta;
