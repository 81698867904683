import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HIT_CAR_RESULT_CONTENT } from './constants';

const StyledResultContainer = styled.div`
  .text-bold {
    ${(props) => props.theme.typo.style.mainButtonOrLink};
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }
  .text-base {
    ${(props) => props.theme.typo.style.mainButtonOrLink};
    font-weight: 400;
    ${(props) => props.theme.typo.familyGoogle.regular};
  }
  margin-right: 20px;
`;

type HitCarResultProps = {
  language: string;
  total?: number;
};

const HitCarResult = ({ language, total }: HitCarResultProps) => {
  const [content, setContent] = useState(HIT_CAR_RESULT_CONTENT[language]);

  useEffect(() => {
    setContent({
      ...HIT_CAR_RESULT_CONTENT[language],
      showResultText: HIT_CAR_RESULT_CONTENT[language]?.getResultText(total),
    });
  }, [total, language]);

  return <StyledResultContainer>{content?.showResultText}</StyledResultContainer>;
};

HitCarResult.defaultProps = {
  total: 0,
};

export default HitCarResult;
