import React, { useState, useEffect, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Drawer } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Image from 'next/image';
import dynamic from 'next/dynamic';

import useBreakPoint from '@design-system/hooks/useBreakPoint';
import useListenEventFromMobileApp from '@design-system/hooks/useListenEventFromMobileApp';
import FilterCustomButton from '@design-system/components/FiltersV1/FilterCustomButton';
// import FilterForm from '@design-system/components/FiltersV1/FilterForm';
import { getSelectedFiltersCount } from '@design-system/utils/filters';
import type { ContentProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import { getStaticCDN } from '@design-system/utils/utils';

const FilterForm = dynamic(() => import('@design-system/components/FiltersV1/FilterForm'));

const CustomButtonStyles = css`
  margin: 0 4px;
`;

const CustomButtonMobileStyle = css`
  margin: 0;
  border-radius: 8px;
  padding: 5px 12px;
  border: none;
  height: auto;
  color: ${(props) => props.theme.color.onSurface}!important;
  box-shadow: ${(props) => props.theme.color.boxShadow.defaultBoxshadow};
  background-color: ${(props) => props.theme.color.background}!important;
  ${(props) => props.theme.typo.familyGoogle.regular};
  ${(props) => props.theme.typo.style.smallButtonOrLink};
  width: 100%;
`;

const StyledLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  .tab-label__icon {
    margin-right: 15px;
    display: flex;
    justify-content: center;

    .icon__number {
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: inline-block;
      ${(props) => props.theme.typo.style.captionRegular};
      color: ${(props) => props.theme.color.background};
      background-color: ${(props) => props.theme.color.primaryBase};
    }
  }
`;

export interface FilterTabTypeProps extends PropsWithChildren {
  content?: ContentProps;
  settings?: SettingProps[] | [];
  initSettings?: SettingProps[];
  onFormSave?: (payloads: any, isSaveAction: boolean, type: string, isFetchHitCount: boolean) => void;
  isMobile?: boolean;
}

const CUSTOM_EVENT_MOBILE_APP = 'customFilterDrawerOpen';

// children = string / title
const FilterTabType = ({
  content,
  settings = [],
  initSettings = [],
  children,
  onFormSave,
  isMobile,
}: FilterTabTypeProps) => {
  const isLgBreakpoint = useBreakPoint('lg');
  const [showDrawer, setShowDrawer] = useState(false);

  const { useStyleCustomButtonMobile, showIcon, showActiveBar, ignoreMakeCount = false } = content || {};

  const { isActionFromMobileApp, removeIsActionFromMobileApp } = useListenEventFromMobileApp({
    eventName: CUSTOM_EVENT_MOBILE_APP,
  });

  useEffect(() => {
    if (isActionFromMobileApp) {
      setShowDrawer(true);
      removeIsActionFromMobileApp();
    }
  }, [isActionFromMobileApp, removeIsActionFromMobileApp]);

  const handleToggle = () => {
    // setIsFromNotifyMe(false);
    setShowDrawer(!showDrawer);
  };

  const handleOnFilterSave = ({
    payloads,
    isSaveAction = true,
    isFetchHitCount = false,
    isShowDrawer = false,
  }: {
    payloads: Record<string, SettingProps> | null | undefined;
    isSaveAction?: boolean;
    isFetchHitCount?: boolean;
    isShowDrawer?: boolean;
  }) => {
    // setIsFromNotifyMe(false);
    if (isShowDrawer === false) {
      setShowDrawer(false);
    }

    if (onFormSave) onFormSave(payloads, isSaveAction, 'tabs', isFetchHitCount);
  };

  const renderFilterForm = () => (
    <FilterForm
      isMobile={isMobile}
      content={content}
      title={children}
      filters={settings}
      initSettings={initSettings}
      type="tabs"
      isShowActiveBar={showActiveBar}
      onClose={handleToggle}
      onFormSave={handleOnFilterSave}
    />
  );

  const renderDrawer = () => (
    <Drawer
      bodyStyle={{ padding: 0 }}
      height="100%"
      width="600px"
      placement={isLgBreakpoint ? 'right' : 'bottom'}
      closable={false}
      onClose={handleToggle}
      visible={showDrawer}
      closeIcon={<CloseCircleOutlined />}
      className="filter-drawer"
      destroyOnClose
    >
      {renderFilterForm()}
    </Drawer>
  );

  const selectedFiltersCount = getSelectedFiltersCount(settings, ignoreMakeCount);

  const renderLabel = () => {
    if (showIcon) {
      return (
        <StyledLabel className="tab-label">
          <span className="tab-label__icon">
            {selectedFiltersCount > 0 ? (
              <span className="icon__number">{selectedFiltersCount}</span>
            ) : (
              <Image alt="filter" src={getStaticCDN('/static/img/browse/icons/filter.svg')} width={14} height={14} />
            )}
          </span>
          {children}
        </StyledLabel>
      );
    }

    return selectedFiltersCount > 0 ? `${children} • ${selectedFiltersCount}` : children;
  };

  return (
    <>
      {renderDrawer()}
      <FilterCustomButton
        isActive={selectedFiltersCount > 0}
        onClick={handleToggle}
        customStyles={useStyleCustomButtonMobile ? CustomButtonMobileStyle : CustomButtonStyles}
      >
        {renderLabel()}
      </FilterCustomButton>
    </>
  );
};

FilterTabType.defaultProps = {
  settings: [],
  initSettings: [],
  onFormSave: () => {},
  content: {
    useStyleCustomButtonMobile: 'Clear',
    showIcon: 'Save',
  },
  isMobile: false,
};

export default FilterTabType;
