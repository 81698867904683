import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Custom Styles
const StyledWrapper = styled.div.withConfig({
  componentId: 'filterInputWrapper',
})<{ showPlaceHolder: boolean }>`
  position: relative;
  /* font-size: 15px; */
  width: 100%;

  input {
    font-size: 14px;
    line-height: 22px;
  }

  .floating-label {
    position: absolute;
    left: 10px;
    font-size: 11px;
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    top: 5px;
  }

  .floating-suffix {
    position: absolute;
    right: 8px;
    font-size: 14px;
    line-height: 22px;
    color: black;
    bottom: ${(props) => (props.showPlaceHolder ? '7px' : '10px')};
  }
  .floating-prefix {
    position: absolute;
    left: 10px;
    font-size: 14px;
    line-height: 22px;
    bottom: ${(props) => (props.showPlaceHolder ? '7px' : '10px')};
  }
`;
const StyledInput = styled.input<{ prefixPadding: string; suffix: string; showPlaceHolder: boolean }>`
  height: ${(props) => (props.showPlaceHolder ? '50px' : '42px')};
  width: 100%;
  font-size: 14px;
  padding-top: ${(props) => (props.showPlaceHolder ? '17px' : '5px')};
  padding-left: ${(props) => (props.prefix ? props.prefixPadding : '10px')};
  padding-right: ${(props) => (props.suffix ? '28px' : '10px')};
  padding-bottom: ${(props) => (props.showPlaceHolder ? '1.5px' : '5px')};
  border: 0.5px solid #d4d4d4;
  border-radius: 8px;

  &:focus,
  &:focus-visible,
  &:active {
    outline: ${(props) => props.theme.color.primaryBase} auto 1px;
    box-shadow: none;
  }
`;

interface FilterInputProps {
  name?: string;
  placeholder?: string;
  onChange?: (value: any) => void;
  value?: string | number | null;
  suffix?: string;
  prefix?: string;
  showPlaceHolder?: boolean;
}

const FilterInput = ({
  name,
  placeholder,
  onChange,
  value,
  suffix = '',
  prefix = '',
  showPlaceHolder,
}: FilterInputProps) => {
  const { t } = useTranslation();
  const prefixPadding = `${14 + (prefix?.toString()?.length || 1) * 8}px`;
  return (
    <StyledWrapper showPlaceHolder={showPlaceHolder || false}>
      <StyledInput
        name={name}
        onChange={onChange}
        value={value || ''}
        suffix={suffix}
        prefix={prefix}
        prefixPadding={prefixPadding}
        showPlaceHolder={showPlaceHolder || false}
      />
      {showPlaceHolder && <span className="floating-label">{placeholder}</span>}
      {suffix && <span className="floating-suffix">{t(suffix)}</span>}
      {prefix && <span className="floating-prefix">{prefix}</span>}
    </StyledWrapper>
  );
};

FilterInput.defaultProps = {
  name: 'filter-input',
  placeholder: '',
  onChange: () => {},
  value: 0,
  suffix: '',
  prefix: '',
  showPlaceHolder: false,
};

export default FilterInput;
