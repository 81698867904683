import React, { FC } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import HOW_TO_PURCHASES from '@source/pages/Home/components/HowToPurchase/constants';
import { HowToPurchaseContentProps } from '@source/pages/Home/components/HowToPurchase/HowToPurchaseProps';
import { TRegionIds } from '@source/constants/common';
import { useTranslation } from 'react-i18next';
import { TitleTypography } from '@design-system/components';
import ExclusiveLabelsBanner from '@source/pages/Home/components/ExclusiveLabelsBanner/ExclusiveLabelsBanner';

const StyledWrapper = styled.div`
  .text-transform-none {
    text-transform: none !important;
  }
`;

const StyledTitle = styled(TitleTypography)`
  font-weight: 700 !important;
  font-size: 18px !important;
  text-transform: capitalize;
  line-height: 140% !important;
  color: #212121; //use color from figma design
  margin-bottom: 24px !important;
  ${(props) => props.theme.typo.familyGoogle.bold};
`;

const StyledItemLists = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0 38px;
  overflow-x: auto;

  @media screen and (max-width: 992px) {
    gap: 0 24px;
  }
`;

const StyledItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1920px) {
    flex-direction: row;
  }

  @media screen and (max-width: 992px) {
    max-width: 280px;
  }
`;

const StyledImage = styled.div`
  margin: 0 auto;
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 15px;
`;

const StyledNumber = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  color: ${(props) => props.theme.color.primaryBase};
  ${(props) => props.theme.typo.familyGoogle.regular};
`;

const StyledDescription = styled.div`
  width: 228px;

  .title {
    font-weight: 600;
    font-size: 16px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    line-height: 120%;
    text-transform: capitalize;
    color: ${(props) => props.theme.color.primaryBase};
    margin-bottom: 9px;
  }

  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #212121;
    ${(props) => props.theme.typo.familyGoogle.regular};
  }
`;

export interface HowToPurchaseProps {
  countryCode: string;
}

const HowToPurchase: FC<HowToPurchaseProps> = ({ countryCode }) => {
  const { title, content } = HOW_TO_PURCHASES[countryCode as keyof TRegionIds];
  const { t } = useTranslation();
  const changeTitleOnCountry = 'Carro';

  if (countryCode === 'sg') {
    return <ExclusiveLabelsBanner />;
  }

  const renderItem = ({ name, image, description }: HowToPurchaseContentProps, index: number) => (
    <StyledItem key={name}>
      <StyledImage>{image && <Image src={image} alt={name} width={176} height={106} />}</StyledImage>
      <StyledContent>
        <StyledNumber>0{index + 1}.</StyledNumber>
        <StyledDescription>
          <TitleTypography
            variant="sHeader"
            className={countryCode === 'my' ? 'title text-transform-none' : 'title'}
            level={3}
          >
            {name && t(name, { title: changeTitleOnCountry })}
          </TitleTypography>
          <div className="desc">{description && t(description, { title: changeTitleOnCountry })}</div>
        </StyledDescription>
      </StyledContent>
    </StyledItem>
  );

  const renderItemLists = () =>
    content?.map((item: HowToPurchaseContentProps, index: number) => renderItem(item, index));

  return (
    <StyledWrapper>
      <div>
        <StyledTitle variant="mHeader" level={2}>
          {title && t(title, { title: changeTitleOnCountry })}
        </StyledTitle>
      </div>
      <StyledItemLists>{renderItemLists()}</StyledItemLists>
    </StyledWrapper>
  );
};

export default HowToPurchase;
