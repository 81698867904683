import React from 'react';

const MinusIcon = ({ width, height, className }: { width?: number; height?: number; className?: string }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.4287 0.322266H0.571568C0.492997 0.322266 0.428711 0.386551 0.428711 0.465123V1.53655C0.428711 1.61512 0.492997 1.67941 0.571568 1.67941H13.4287C13.5073 1.67941 13.5716 1.61512 13.5716 1.53655V0.465123C13.5716 0.386551 13.5073 0.322266 13.4287 0.322266Z" />
  </svg>
);
MinusIcon.defaultProps = {
  width: 14,
  height: 2,
  className: '',
};
export default MinusIcon;
