import React from 'react';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';

const StyleCardWrapper = styled.div`
  /* min-height: 415px;

  @media (min-width: 1280px) {
    min-height: 450px;
  } */
  height: 100%;
`;

const LazyRenderCard: React.FC<{
  children: React.ReactNode;
  defaultRender?: boolean;
}> = ({ children, defaultRender }) => (
  <InView triggerOnce rootMargin="200px 0px 0px 0px">
    {({ ref, inView }) => <StyleCardWrapper ref={ref as any}>{(defaultRender || inView) && children}</StyleCardWrapper>}
  </InView>
);

export default LazyRenderCard;
