/* eslint-disable arrow-body-style */
import React, { PropsWithChildren, useState, FC, useMemo, useEffect } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Button, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';

import useMobileView from '@design-system/hooks/useMobileView';
import { Typography } from '@design-system/components/Typography';
import { TRegionIds } from '@source/constants/common';

// Icons
import SortIcon from '@design-system/assets/sortIcon/sort-icon.svg';
import MobileDrawer from '@design-system/components/MobileDrawer/MobileDrawer';

import SORT_LIST from '@source/pages/Home/components/SortV1.0/constant';
import { TSortBy, TSortOptionItemSelected } from '@source/pages/Home/components/SortV1.0/types';
import { getActiveSortOption, getSelectedOption } from '@source/pages/Home/components/SortV1.0/utils';

const { useBreakpoint } = Grid;
interface SortButtonProps {
  $hasShadow: boolean | undefined;
  $hasBorder: boolean | undefined;
}

const StyledSortsWrapper = styled.div`
  height: 100%;
  padding: 24px;
  margin-bottom: 88px;
  @media screen and (min-width: 992px) and (max-width: 1279px) {
    padding: 0 20px;
  }
`;

const StyledSortButton = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.typo.familyGoogle.regular};
  @media screen and (max-width: 1279px) {
    width: 50%;
    button {
      width: 100% !important;
    }
  }
`;

const StyledSelectTitle = styled.div`
  display: inline-block;
  margin: 5px 16px;
  font-weight: 400;
  white-space: nowrap;
`;

const StyledButton = styled(Button)<SortButtonProps>`
  align-items: center;
  background-color: ${(props) => props.theme.color.background}!important;
  border-radius: 8px;
  border: ${(props) =>
    props.$hasBorder ? `1px solid ${props.theme.color.onBackgroundLowEmphasis}` : 'none'}!important;
  box-shadow: ${(props) => (props.$hasShadow ? props.theme.color.boxShadow.defaultBoxshadow : 'none')}!important;
  color: ${(props) => props.theme.color.onSurface}!important;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.typo.familyGoogle.regular};
  ${(props) => props.theme.typo.style.smallButtonOrLink};
  width: 100%;
  gap: 10px;

  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
    width: unset;
    height: 42px;
    border-radius: 12px;
    gap: 8px;
  }
`;

const StyledSortGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
  &:not(:first-child) {
    padding-top: 20px;
  }

  .groupName {
    padding-top: 10px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: 14px;
    font-weight: 600;
  }

  .index {
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0;
    cursor: pointer;
  }

  .active-index {
    color: ${(props) => props.theme.color.primaryBase};
    position: relative;
    font-weight: 600;
    font-size: 14px;

    .active-icon {
      position: absolute;
      right: -22px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }

  .indexes-container {
    padding-right: 22px;
    text-align: right;
    font-size: 14px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }
`;

const StyledCheckOutlined = styled(CheckOutlined)`
  font-size: 18px;
`;

export interface SortByProps extends PropsWithChildren {
  countryCode: string;
  currentSortBy: TSortBy | null;
  hasBorder?: boolean;
  hasShadow?: boolean;
  content?: {
    title: string;
    buttonLabel: string;
    confirmButtonLabel: string;
  };
  onConfirm: (selectedOption: TSortOptionItemSelected | null) => void;
}

const SortByMobileContainer: FC<SortByProps> = ({
  hasBorder,
  hasShadow,
  countryCode,
  currentSortBy,
  content,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const sortList = useMemo(() => SORT_LIST[countryCode as keyof TRegionIds], [countryCode]);

  const isBelow1280 = useMobileView(1280);

  const [selectedOption, setSelectedOption] = useState<TSortOptionItemSelected | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemSelected, setItemSelected] = useState<TSortOptionItemSelected | null>(null);

  useEffect(() => {
    if (currentSortBy) {
      const activeSortBy = getActiveSortOption(currentSortBy, countryCode);
      setSelectedOption(activeSortBy);
      setItemSelected(activeSortBy);
    }
  }, [countryCode, currentSortBy]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const handleSelect = (sortItem: TSortOptionItemSelected) => {
    setItemSelected(sortItem);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    const sortItem = sortList.find((item) => item.name === itemSelected?.name) || null;

    if (sortItem && itemSelected) {
      const newSelectedOption = getSelectedOption(sortItem, itemSelected);
      setSelectedOption(newSelectedOption);
    }

    onConfirm(itemSelected);
    closeModal();
  };

  const renderButtonTitle = () => {
    if (selectedOption && selectedOption.title.includes(':')) {
      const titles = selectedOption.title.split(':');
      return (
        <Typography variant={isBelow1280 ? 'smallButtonOrLink' : 'mainButtonOrLink'}>
          {screens.xl && !isBelow1280 ? `${t(titles[0] || '')}: ${t(titles[1] || '')}` : t(content?.buttonLabel || '')}
        </Typography>
      );
    }
    return (
      <Typography variant={isBelow1280 ? 'smallButtonOrLink' : 'mainButtonOrLink'}>
        {screens.xl && !isBelow1280 ? t(selectedOption?.title || '') : t(content?.buttonLabel || '')}
      </Typography>
    );
  };

  const renderSortList = () =>
    sortList &&
    sortList.map((sort) => (
      <StyledSortGroup key={sort.name}>
        <div className="groupName">{t(sort.title)}</div>
        <div className="indexes-container">
          {sort.sortOptions.map((option) => {
            const isSelected = itemSelected?.name === sort.name && itemSelected?.value === option.value;
            return (
              <div
                key={option.value}
                className={`index ${isSelected ? 'active-index' : ''}`}
                onClick={() => handleSelect({ ...option, name: sort.name })}
                onKeyDown={() => handleSelect({ ...option, name: sort.name })}
                role="presentation"
              >
                {t(option.title)}
                <span className="active-icon">{isSelected && <StyledCheckOutlined />}</span>
              </div>
            );
          })}
        </div>
      </StyledSortGroup>
    ));

  return (
    <>
      <StyledSortButton>
        {screens.xl && !isBelow1280 && (
          <StyledSelectTitle className="sort-select-title">{t(content?.title || '')}</StyledSelectTitle>
        )}
        <StyledButton shape="round" onClick={openModal} $hasBorder={hasBorder} $hasShadow={hasShadow}>
          <Image alt="sort" layout="intrinsic" src={SortIcon} />
          {renderButtonTitle()}
        </StyledButton>
      </StyledSortButton>
      <MobileDrawer
        width={isBelow1280 ? '100%' : '576px'}
        placement={isBelow1280 ? 'bottom' : 'right'}
        visible={isModalVisible}
        title={t(content?.title || '')}
        confirmBtnLabel={t(content?.confirmButtonLabel || '')}
        onConfirm={handleConfirm}
        onClose={closeModal}
      >
        <StyledSortsWrapper>{renderSortList()}</StyledSortsWrapper>
      </MobileDrawer>
    </>
  );
};

SortByMobileContainer.defaultProps = {
  hasBorder: false,
  hasShadow: true,
  content: {
    title: 'home.sort.title',
    buttonLabel: 'home.sort.buttonLabel',
    confirmButtonLabel: 'home.sort.confirmButtonLabel',
  },
};

export default SortByMobileContainer;
