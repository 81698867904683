/* eslint-disable react/require-default-props */
import React, { Fragment, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import set from 'lodash/set';
import upperCase from 'lodash/upperCase';
import pick from 'lodash/pick';

import useBrowseServices from '@source/hooks/useBrowseServices';
import useRegionConfig from '@source/hooks/useRegionConfig';
import FILTER_CONFIGS from '@source/pages/Home/components/Filters/configs';
import FILTER_CONFIGS_SG from '@source/pages/Home/components/Filters/configs/sg';
import { FilterTag } from '@source/pages/Home/components/FilterTag';

import type {
  ContentProps,
  DefaultOptionProps,
  FilterProps,
  SettingProps,
} from '@design-system/components/FiltersV1/FilterProps';
import {
  fmMakeOptionsFromStringArray,
  fmStringArrayFromMakeOptions,
  getNewTagLabel,
} from '@design-system/utils/filters';
import { deepCopy } from '@design-system/utils/utils';

const StyledSelectedFiltersBar = styled(Space)`
  display: flex !important;
  width: 100%;
  white-space: nowrap;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 8px;
`;

const StyledClearBtn = styled(Button)`
  font-weight: 700;
  background-color: transparent;
  color: ${(props) => props.theme.color.primaryBase};
  border: none;
  box-shadow: none;
  height: unset;
  justify-self: flex-start;
  padding: 0;
  margin-right: 20px;

  & > span {
    text-transform: capitalize;
    text-decoration: underline;
    text-underline-offset: 1px;
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.primaryHover};
  }
`;

export interface FilterActiveBarProps extends PropsWithChildren {
  isMobile?: boolean;
  content?: ContentProps;
  settings?: SettingProps[];
  initSettings?: SettingProps[];
  onFormSave?: (
    payloads: Record<string, SettingProps>,
    isSaveAction: boolean,
    type: string,
    isFetchHitCount: boolean,
    isCloseActiveFilter?: boolean,
  ) => void;
  onFormChange?: (payloads: Record<string, SettingProps>, path: string) => void;
  onClearAllFilters?: () => void;
}

// children = string / title
const FilterActiveBar = ({
  content,
  settings,
  initSettings,
  isMobile,
  onFormSave,
  onFormChange,
  onClearAllFilters,
}: FilterActiveBarProps) => {
  const [payloads, setPayloads] = useState(settings && keyBy(settings, 'name'));
  // const [initPayload] = useState(initSettings && initSettings?.reduce(transformFiltersToMap, {}));
  const { t } = useTranslation();
  const { clearAllText } = content || {};

  const {
    countryConfig: { country },
  } = useRegionConfig();

  const { browseState, setSelectedSearchFilters, setCurrentFilterSettings, setCurrentQuery } = useBrowseServices();
  const { selectedSearchFilters } = browseState;

  useEffect(() => {
    setPayloads(settings && keyBy(settings, 'name'));
  }, [settings]);

  const getNewFilter = (type: string, selectedOptions: DefaultOptionProps[], filter: FilterProps) => {
    let newFilter = {
      ...filter,
      selectedOptions: selectedOptions?.length ? selectedOptions : null,
    };
    switch (type) {
      case 'number':
        newFilter = {
          ...newFilter,
          selectedNumber: null,
        };
        break;
      case 'range_input':
        newFilter = {
          ...newFilter,
          selectedMaxInput: null,
          selectedMinInput: null,
        };
        break;
      default:
        break;
    }

    return newFilter;
  };

  const deleteOtherKey = (data: Record<string, SettingProps>, keepKey: string) =>
    Object.keys(data).forEach((key: string) => {
      if (key !== keepKey) {
        // eslint-disable-next-line no-param-reassign
        delete data[key];
      }
    });

  const handleClickFilterItem = (
    value: string | number | boolean | null | undefined,
    path: string,
    parentName: string,
    filter: FilterProps,
    filterIndex: number,
  ) => {
    const { displayType, type } = filter;

    // Get selectedOptions from filter outside
    // instead of filter inside each make option in makeModelOptions
    const realFilter = get(payloads, `${parentName}.filters.[${filterIndex}]`) as FilterProps;
    const { selectedOptions } = realFilter || {};

    const newPayloads = deepCopy(payloads);
    const newSelectedOptions = selectedOptions?.filter((item: DefaultOptionProps) => item.value !== value) || [];
    const newFilter = getNewFilter(type || '', newSelectedOptions, filter);

    // if its make => clear relate selectedOptions in make_model too
    if (parentName === 'make') {
      let selectedMake = fmStringArrayFromMakeOptions(
        get(payloads, 'make.filters.[0].selectedOptions') as DefaultOptionProps[],
      ) as string[];
      selectedMake = selectedMake || get(payloads, 'make_model.filters.[0].selectedMake');
      let newMakeModelOptions = get(payloads, 'make_model.filters.[0].selectedOptions') as DefaultOptionProps[];
      newMakeModelOptions = newMakeModelOptions?.filter(
        (m) => m.make !== value || upperCase(`${m.value}`).indexOf(upperCase(`${value}`)) < 0,
      );
      const newMake = selectedMake.filter((m: string) => upperCase(m) !== upperCase(`${value}`));
      set(newPayloads, 'make_model.filters.[0].selectedOptions', newMakeModelOptions);
      set(newPayloads, 'make_model.filters.[0].selectedMake', newMake);
      set(newPayloads, 'make.filters.[0].selectedOptions', fmMakeOptionsFromStringArray(newMake));
    } else {
      set(newPayloads || {}, path, newFilter);
    }

    const isSaveAction = !!newSelectedOptions?.length;
    type nameType = keyof typeof payloads;

    if (setSelectedSearchFilters && !isMobile) {
      if (parentName === 'make') {
        setSelectedSearchFilters({
          ...selectedSearchFilters,
          make: selectedSearchFilters.make.filter((make) => map(newSelectedOptions, 'value').includes(make)),
        });
      }

      if (parentName === 'body_button') {
        setSelectedSearchFilters({
          ...selectedSearchFilters,
          body_type: selectedSearchFilters.body_type.filter((bodyType) =>
            map(newSelectedOptions, 'value').includes(bodyType.value),
          ),
        });
      }

      if (parentName === 'make_model') {
        setSelectedSearchFilters({
          ...selectedSearchFilters,
          make_model: selectedSearchFilters.make_model.filter((makeModel) =>
            map(newSelectedOptions, 'value').includes(makeModel),
          ),
        });
      }

      if (parentName === 'category') {
        setSelectedSearchFilters({
          ...selectedSearchFilters,
          category: selectedSearchFilters.category.filter((category) =>
            map(newSelectedOptions, 'value').includes(category.value),
          ),
        });
      }
    }

    if (isMobile) {
      if (onFormChange) onFormChange(newPayloads?.[parentName as nameType]?.filters?.[filterIndex], path);
    } else if (onFormSave) {
      const formSavePayloads = ['make_model', 'make'].includes(parentName)
        ? pick(newPayloads, ['make', 'make_model'])
        : { [parentName]: newPayloads?.[parentName as nameType] };
      onFormSave(formSavePayloads, isSaveAction, displayType || 'popover', false, true);
    }
  };

  const handleClickBooleanFilterItem = (filter: SettingProps, path: string, parentName: string) => {
    let newPayloads = deepCopy(payloads);
    const { filters } = filter;
    const newFilters = filters?.map((item: FilterProps) => ({
      ...item,
      selectedBoolean: null,
    }));

    newPayloads = set(newPayloads || {}, path, newFilters);

    deleteOtherKey(newPayloads, parentName);

    if (onFormSave) onFormSave(newPayloads, true, 'popover', false);
  };

  const renderActiveFilter = (filter: FilterProps, parentName: string, filterIndex: number) => {
    const path = `${parentName}.filters.[${filterIndex}]`;
    return filter?.selectedOptions?.map((item: DefaultOptionProps, index: number) => {
      const newLabel = getNewTagLabel(filter, item, t);
      return (
        <FilterTag
          key={`${item?.name}-${item?.value}` || index}
          label={newLabel}
          onClick={() => handleClickFilterItem(item.value, path, parentName, filter, filterIndex)}
        />
      );
    });
  };

  const renderActiveFilters = (setting: SettingProps) =>
    setting?.filters?.map((item: FilterProps, index: number) => (
      <Fragment key={item?.name}>{renderActiveFilter(item, setting.name || '', index)}</Fragment>
    ));

  const renderActiveBooleanFilter = (filter: SettingProps, parentName: string) => {
    const path = `${parentName}.filters`;
    return (
      <FilterTag
        label={t(filter.header || '')}
        onClick={() => handleClickBooleanFilterItem(filter, path, parentName)}
      />
    );
  };

  // New data for rendering filter active tag only.
  const [newPayloadForRendered, setNewPayloadForRendered] = useState<Record<string, SettingProps> | undefined>(
    payloads,
  );

  useEffect(() => {
    // Create new payload to render active filters
    // Original payloads will be used to generate data for filter algolia
    const newPayloads = deepCopy(payloads) as Record<string, SettingProps>;
    // For brand&model filter,
    // if some makes selected, we remove options that belong to those makes from selectedOptions
    if (Object.keys(newPayloads).includes('make_model')) {
      const makeModelOptions = get(newPayloads, 'make_model.filters.[0].selectedOptions') as DefaultOptionProps[];
      const selectedMakeFromMakeModel = get(newPayloads, 'make_model.filters.[0].selectedMake') as string[];
      const selectedMakeFromMake = fmStringArrayFromMakeOptions(
        get(newPayloads, 'make.filters.[0].selectedOptions') as DefaultOptionProps[],
      );
      const newMakeModelOptions = makeModelOptions.filter((makeModel) => {
        const comparedMakes = selectedMakeFromMakeModel || selectedMakeFromMake;
        if (makeModel?.make) {
          return !comparedMakes?.includes(makeModel.make);
        }

        return !comparedMakes?.some((m) => `${makeModel?.value}`?.indexOf(m) === 0);
      });
      set(newPayloads, 'make_model.filters.[0].selectedOptions', newMakeModelOptions);
    }

    setNewPayloadForRendered(newPayloads);
  }, [payloads]);

  const renderSelectedFilters: ReactNode = Object.values(newPayloadForRendered || {})?.map((setting: SettingProps) => (
    <Fragment key={setting.name}>
      {setting.isBooleanFilter
        ? renderActiveBooleanFilter(setting, setting.name || 'fake_path')
        : renderActiveFilters(setting)}
    </Fragment>
  ));

  const handleClearAll = () => {
    setCurrentQuery('');
    if (setSelectedSearchFilters) {
      setSelectedSearchFilters({ make: [], make_model: [], body_type: [], category: [] });
    }

    if (setCurrentFilterSettings) {
      type countryType = keyof typeof FILTER_CONFIGS;
      const currentFilterSetting = (FILTER_CONFIGS?.[country as countryType] || FILTER_CONFIGS_SG)
        .initialFilterSettings;
      setCurrentFilterSettings(initSettings || currentFilterSetting);
    }

    if (onClearAllFilters) onClearAllFilters();
  };

  const isShowActiveFilter = true;

  return isShowActiveFilter && settings?.length ? (
    <StyledSelectedFiltersBar size={8}>
      {!isMobile && <StyledClearBtn onClick={() => handleClearAll()}>{t(clearAllText || '')}</StyledClearBtn>}
      {renderSelectedFilters}
    </StyledSelectedFiltersBar>
  ) : null;
};

FilterActiveBar.defaultProps = {
  isMobile: false,
  settings: [],
  // initSettings: [],
  content: {
    clearAllText: 'Clear All',
  },

  onFormSave: () => {},
  onFormChange: () => {},
};

export default FilterActiveBar;
