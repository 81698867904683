import { TSortItem } from './types';

const SORT_LIST_SG: TSortItem[] = [
  {
    title: 'home.sort.group.latest.title',
    sortOptions: [{ title: 'home.sort.group.latest.asc', value: 'asc' }],
    name: 'latest',
    titleOnly: true,
  },
  {
    title: 'home.sort.group.price.title',
    sortOptions: [
      { title: 'home.sort.group.price.asc', value: 'asc' },
      { title: 'home.sort.group.price.desc', value: 'desc' },
    ],
    name: 'price',
  },
  {
    title: 'home.sort.group.depreciation.title',
    sortOptions: [
      { title: 'home.sort.group.depreciation.asc', value: 'asc' },
      { title: 'home.sort.group.depreciation.desc', value: 'desc' },
    ],
    name: 'depreciation',
  },
  {
    title: 'home.sort.group.mileage.title',
    sortOptions: [
      { title: 'home.sort.group.mileage.asc', value: 'asc' },
      { title: 'home.sort.group.mileage.desc', value: 'desc' },
    ],
    name: 'mileage',
  },
  {
    title: 'home.sort.group.instalment.title',
    sortOptions: [
      { title: 'home.sort.group.instalment.asc', value: 'asc' },
      { title: 'home.sort.group.instalment.desc', value: 'desc' },
    ],
    name: 'instalment',
  },
  {
    title: 'home.sort.group.regYear.title',
    sortOptions: [
      { title: 'home.sort.group.regYear.desc', value: 'desc' },
      { title: 'home.sort.group.regYear.asc', value: 'asc' },
    ],
    name: 'reg_year',
  },
];

const SORT_LIST_MY: TSortItem[] = [
  {
    title: 'home.sort.group.latest.title',
    sortOptions: [{ title: 'home.sort.group.latest.asc', value: 'asc' }],
    name: 'latest',
    titleOnly: true,
  },
  {
    title: 'home.sort.group.price.title',
    sortOptions: [
      { title: 'home.sort.group.price.asc', value: 'asc' },
      { title: 'home.sort.group.price.desc', value: 'desc' },
    ],
    name: 'price',
  },
  {
    title: 'home.sort.group.mileage.title',
    sortOptions: [
      { title: 'home.sort.group.mileage.asc', value: 'asc' },
      { title: 'home.sort.group.mileage.desc', value: 'desc' },
    ],
    name: 'mileage',
  },
  {
    title: 'home.sort.group.instalment.title',
    sortOptions: [
      { title: 'home.sort.group.instalment.asc', value: 'asc' },
      { title: 'home.sort.group.instalment.desc', value: 'desc' },
    ],
    name: 'instalment',
  },
  {
    title: 'home.sort.group.manufactureYear.title',
    sortOptions: [
      { title: 'home.sort.group.manufactureYear.desc', value: 'desc' },
      { title: 'home.sort.group.manufactureYear.asc', value: 'asc' },
    ],
    name: 'reg_year',
  },
];

const SORT_LIST_ID: TSortItem[] = [
  {
    title: 'home.sort.group.latest.title',
    sortOptions: [{ title: 'home.sort.group.latest.asc', value: 'asc' }],
    name: 'latest',
    titleOnly: true,
  },
  {
    title: 'home.sort.group.price.title',
    sortOptions: [
      { title: 'home.sort.group.price.asc', value: 'asc' },
      { title: 'home.sort.group.price.desc', value: 'desc' },
    ],
    name: 'price',
  },
  {
    title: 'home.sort.group.mileage.title',
    sortOptions: [{ title: 'home.sort.group.mileage.asc', value: 'asc' }],
    name: 'mileage',
  },
  {
    title: 'home.sort.group.instalment.title',
    sortOptions: [{ title: 'home.sort.group.instalment.asc', value: 'asc' }],
    name: 'instalment',
  },
  {
    title: 'home.sort.group.manufactureYear.title',
    sortOptions: [
      { title: 'home.sort.group.manufactureYear.desc', value: 'desc' },
      { title: 'home.sort.group.manufactureYear.asc', value: 'asc' },
    ],
    name: 'reg_year',
  },
];

const SORT_LIST_TH: TSortItem[] = [
  {
    title: 'home.sort.group.latest.title',
    sortOptions: [{ title: 'home.sort.group.latest.asc', value: 'asc' }],
    name: 'latest',
    titleOnly: true,
  },
  {
    title: 'home.sort.group.price.title',
    sortOptions: [
      { title: 'home.sort.group.price.asc', value: 'asc' },
      { title: 'home.sort.group.price.desc', value: 'desc' },
    ],
    name: 'price',
  },
  {
    title: 'home.sort.group.manufactureYear.title',
    sortOptions: [
      { title: 'home.sort.group.manufactureYear.desc', value: 'desc' },
      { title: 'home.sort.group.manufactureYear.asc', value: 'asc' },
    ],
    name: 'reg_year',
  },
  {
    title: 'home.sort.group.mileage.title',
    sortOptions: [{ title: 'home.sort.group.mileage.asc', value: 'asc' }],
    name: 'mileage',
  },
];

const SORT_LIST_JP: TSortItem[] = [
  {
    title: 'home.sort.group.latest.title',
    titleOnly: true,
    sortOptions: [{ title: 'home.sort.group.latest.asc', value: 'asc' }],
    name: 'latest',
  },
  {
    title: 'home.sort.group.price.title',
    sortOptions: [
      { title: 'home.sort.group.price.asc', value: 'asc' },
      { title: 'home.sort.group.price.desc', value: 'desc' },
    ],
    name: 'price',
  },
  {
    title: 'home.sort.group.mileage.title',
    sortOptions: [{ title: 'home.sort.group.mileage.asc', value: 'asc' }],
    name: 'mileage',
  },
  {
    title: 'home.sort.group.manufactureYear.title',
    sortOptions: [
      { title: 'home.sort.group.manufactureYear.desc', value: 'desc' },
      { title: 'home.sort.group.manufactureYear.asc', value: 'asc' },
    ],
    name: 'reg_year',
  },
];

const SORT_LIST_HK: TSortItem[] = [
  {
    title: 'home.sort.group.latest.title',
    sortOptions: [{ title: 'home.sort.group.latest.asc', value: 'asc' }],
    name: 'latest',
    titleOnly: true,
  },
  {
    title: 'home.sort.group.price.title',
    sortOptions: [
      { title: 'home.sort.group.price.asc', value: 'asc' },
      { title: 'home.sort.group.price.desc', value: 'desc' },
    ],
    name: 'price',
  },
  {
    title: 'home.sort.group.mileage.title',
    sortOptions: [
      { title: 'home.sort.group.mileage.asc', value: 'asc' },
      { title: 'home.sort.group.mileage.desc', value: 'desc' },
    ],
    name: 'mileage',
  },
  {
    title: 'home.sort.group.regYear.title',
    sortOptions: [
      { title: 'home.sort.group.regYear.desc', value: 'desc' },
      { title: 'home.sort.group.regYear.asc', value: 'asc' },
    ],
    name: 'reg_year',
  },
  {
    title: 'home.sort.group.manufactureYear.title',
    sortOptions: [
      { title: 'home.sort.group.manufactureYear.desc', value: 'desc' },
      { title: 'home.sort.group.manufactureYear.asc', value: 'asc' },
    ],
    name: 'manufacture_year',
  },
];

const SORT_LIST = {
  sg: SORT_LIST_SG,
  my: SORT_LIST_MY,
  id: SORT_LIST_ID,
  th: SORT_LIST_TH,
  jp: SORT_LIST_JP,
  hk: SORT_LIST_HK,
};

export default SORT_LIST;
