import React, { useState, useEffect } from 'react';
import { Checkbox, Collapse, CollapseProps } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import intersectionBy from 'lodash/intersectionBy';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import map from 'lodash/map';
import upperCase from 'lodash/upperCase';

import { fetchMakeModelOptions, numberOfSelectedOptions, useBrandModelUtil } from '@design-system/utils/filters';
import { DefaultOptionProps, FilterProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import FilterButton from '@design-system/components/FiltersV1/elements/FilterButton';
import { deepCopy } from '@design-system/utils/utils';

import useBrowseServices from '@source/hooks/useBrowseServices';

const StyledFilterWrapper = styled.div`
  .header-text {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    ${(props) => props.theme.typo.style.mainButtonOrLink};
    margin-bottom: 15px;
  }
`;

const StyledCollapse = styled(Collapse)<CollapseProps>`
  max-height: none;
  overflow: auto;
  border: none !important;
  padding-bottom: 0px !important;
  background-color: transparent;

  .ant-collapse-item {
    background-color: transparent;
    border-bottom: 0.5px solid ${(props) => props.theme.color.onBackgroundLowEmphasis} !important;
    padding: 16px 0 !important;

    &:last-child {
      border-bottom: none !important;
    }

    .ant-collapse-header {
      font-size: 14px;
      line-height: 140%;
      padding: 0 !important;
      color: ${(props) => props.theme.color.onSurface}; // #212121
      background-color: transparent;

      .ant-collapse-arrow {
        right: 0 !important;
        margin-right: 0 !important;
        font-size: 14px;
        color: ${(props) => props.theme.color.onBackgroundHighEmphasis}; // #bababa;
      }
    }

    .ant-collapse-content {
      padding-top: 24px;
      border-top: none !important;
      padding: 0 !important;
      background-color: transparent;

      .ant-collapse-content-box {
        box-shadow: none !important;
        padding: 16px 0 !important;
        overflow: hidden;
      }
    }
  }

  .collapse-panel-header {
    display: flex;
    align-items: center;

    .ant-checkbox-checked {
      &::after {
        border: 0;
      }
      .ant-checkbox-inner {
        border-color: ${(props) => props.theme.color.primaryBase};
        background-color: ${(props) => props.theme.color.primaryBase};
      }
      .ant-checkbox-inner::after {
        border: 2.5px solid ${(props) => props.theme.color.background};
        border-top: 0;
        border-left: 0;
      }
    }

    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis}; // #bababa;
      border-radius: 4px;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: ${(props) => props.theme.color.primaryBase};
      border-color: ${(props) => props.theme.color.primaryBase};

      &::after {
        width: 10px;
        height: 2.5px;
        background-color: ${(props) => props.theme.color.background};
      }
    }

    .collapse-panel-header-text {
      margin-left: 12px;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }
  }

  @media screen and (min-width: 1200px) {
    max-height: 300px;
  }
`;

const { Panel } = Collapse;

interface FilterMakeModelProps {
  label: string;
  payload: FilterProps;
  filter: FilterProps;
  onSelect: (payload: any, path?: string) => void;
  onChange: (payload: any, path?: string) => void;
  isMobile: boolean;
}

// Todo_Son: Refactor later.
const FilterMakeModel = (props: FilterMakeModelProps) => {
  const { fetchMakesModels } = useBrandModelUtil();
  const [makeModelOptions, setMakeModelOptions] = useState<any[]>([]);
  const [activeKey, setActiveKey] = useState<number | string | string[]>(0);
  const { t: translation } = useTranslation();
  const { browseState } = useBrowseServices();
  const { currentFilterSettings } = browseState;
  const makeSetting = currentFilterSettings.find((f) => f.name === 'make') as SettingProps;

  const { label, filter, payload, onSelect, isMobile } = props;
  const { selectedOptions = [] } = payload || {};

  const { makeOptions } = filter || {};

  // Add make to current make_model options
  const addMakeToSelectedMakeModelOptions = (options: DefaultOptionProps[], make: string) =>
    options.map((selectOpt: DefaultOptionProps) => ({ ...selectOpt, make }));

  const formatMakeModelList = (options: string[]) =>
    options?.map((opt: string) => {
      // hacky way to get selectedOptions in store
      // check with the make_model substring
      let newSelectedOptions =
        selectedOptions?.filter((selectedQuery: any) => includes(toUpper(selectedQuery.value), toUpper(opt))) || [];
      newSelectedOptions = addMakeToSelectedMakeModelOptions(newSelectedOptions, opt);

      return {
        make: opt,
        models: [],
        props: {
          ...props,
          filter: {
            // eslint-disable-next-line react/destructuring-assignment
            ...props.filter,
            make: opt,
            type: 'button',
            options: [],
            selectedOptions: newSelectedOptions,
          },
          payload: {
            // eslint-disable-next-line react/destructuring-assignment
            ...props.payload,
            make: opt,
            type: 'button',
            options: [],
            selectedOptions: newSelectedOptions,
          },
        },
      };
    }) || [];

  // Fetch makes data
  const handleFetchMakes = async () => {
    const results = await fetchMakesModels({
      query: '*',
      facetsToRetrieve: ['inventory.make'],
    });

    // initialize mapping for make model
    // props are clone with custom type for FilterButton
    type inventoryMakeType = keyof typeof results;
    const makesModels = orderBy(results?.['inventory.make' as inventoryMakeType], 'make') as string[];
    const formattedMakeModelList = uniqBy(
      [...formatMakeModelList(makeOptions || []), ...formatMakeModelList(makesModels)],
      'make',
    );
    setMakeModelOptions(formattedMakeModelList);
  };

  const resetSelectedOptions = () => {
    const resetMakeModelOptions = makeModelOptions.map((makeModelOption) => ({
      ...makeModelOption,
      props: {
        ...makeModelOption.props,
        payload: { ...makeModelOption.props.payload, selectedOptions: [] },
      },
    }));

    setMakeModelOptions([...resetMakeModelOptions]);
  };

  useEffect(() => {
    if (makeModelOptions.length === 0) {
      handleFetchMakes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedOptions || selectedOptions?.length === 0) {
      // reset all makeModelOptions selectedOptions
      // to cater for Clear All onClick
      resetSelectedOptions();
      setActiveKey(0);
    }

    // if selectedOptions is updated,
    // check makeModelOptions for corresponding make and update with new selectedOptions
    const newMakeModelOptions = makeModelOptions.map((opt) => {
      const newOption = { ...opt };
      if (opt.models.length > 0) {
        // uppercase value for comparison due to some make_model are not standardize
        const formattedSelectedQueries = selectedOptions?.map((selectedQuery: any) => ({
          ...selectedQuery,
          value: toUpper(selectedQuery.value),
        }));
        let newSelectedOptions = intersectionBy(opt.models, formattedSelectedQueries || [], 'value');
        newSelectedOptions = newSelectedOptions.map((selectOpt: any) => ({ ...selectOpt, make: opt.make }));
        newOption.props.payload.selectedOptions = newSelectedOptions;
      } else {
        // check selectedOptions with make if selected
        let newSelectedOptions =
          selectedOptions?.filter((selectedQuery: any) => includes(toUpper(selectedQuery.value), toUpper(opt.make))) ||
          [];

        newSelectedOptions = newSelectedOptions.map((selectOpt: any) => ({ ...selectOpt, make: opt.make }));
        newOption.props.payload.selectedOptions = newSelectedOptions;
      }

      return newOption;
    });

    setMakeModelOptions(newMakeModelOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const getAllSelectedOptions = () => {
    const selectedOptionsData = makeModelOptions.map((op) => op?.props?.payload?.selectedOptions || []);
    return uniqBy(flatten(selectedOptionsData), 'value');
  };

  const handleFetchModels = async (make: string | string[]) => {
    const newMakeModelOptions = [...makeModelOptions];
    const makeModelOptionsIndex = newMakeModelOptions.findIndex((makeModelOption) => makeModelOption.make === make);

    // only fetch if no models recorded
    if (make && makeModelOptions[makeModelOptionsIndex]?.models.length === 0) {
      const formattedModelOptions = await fetchMakeModelOptions(typeof make === 'string' ? make : '', fetchMakesModels); // Temp forcing using make as string

      // if in desktop or other filers
      // check if already selected on search bar
      // if in mobile
      const selectedMakeModelOptions = (isMobile ? selectedOptions : filter?.selectedOptions) as DefaultOptionProps[];

      const formattedSelectedOptions =
        selectedMakeModelOptions?.map((selectedOption: any) => ({
          ...selectedOption,
          value: toUpper(selectedOption.value),
        })) || [];

      const newSelectedOptions = intersectionBy(formattedModelOptions, formattedSelectedOptions, 'value');
      // format make model options for make model mapping
      newMakeModelOptions[makeModelOptionsIndex] = {
        ...newMakeModelOptions[makeModelOptionsIndex],
        models: formattedModelOptions,
        props: {
          ...props,
          filter: {
            ...newMakeModelOptions[makeModelOptionsIndex].props.filter,
            options: formattedModelOptions,
            selectedOptions: newSelectedOptions,
          },
          payload: {
            ...newMakeModelOptions[makeModelOptionsIndex].props.payload,
            options: formattedModelOptions,
            selectedOptions: newSelectedOptions,
          },
        },
      };

      setMakeModelOptions([...newMakeModelOptions]);
    }

    setActiveKey(make);
  };

  // custom onChange/onSelect for FilterButton
  const handleFilterChange = (value: any) => {
    const { make, options } = value;
    // update local state for new mapping
    const newMakeModelOptions = deepCopy(makeModelOptions);
    const makeModelOptionsIndex = newMakeModelOptions.findIndex(
      (makeModelOption: any) => makeModelOption.make === make,
    );
    newMakeModelOptions[makeModelOptionsIndex].props.payload = value;
    newMakeModelOptions[makeModelOptionsIndex].props.filter = value;
    setMakeModelOptions(newMakeModelOptions);

    // remove all selectedOptions from same make as payload
    // an expensive way to ensure that selectedOptions doesn't have duplicated or unwanted selections

    const allDiffMakeSelectedOptions = getAllSelectedOptions().filter(
      (allSelectedOption) => allSelectedOption.make !== make,
    );

    const newAllSelectedOptions = [...allDiffMakeSelectedOptions, ...value.selectedOptions];

    // add/remove make option whether all models of filter has selected
    const isAllMakeOptionChecked =
      newAllSelectedOptions.filter((option) => option.make === make).length === options.length;

    // Get selected make from payload or from make filter
    const newMakeSettings = deepCopy(makeSetting);
    let selectedMake = get(payload, 'selectedMake') as string[];
    if (!selectedMake)
      selectedMake = map(
        get(newMakeSettings, 'filters.[0].selectedOptions') as DefaultOptionProps[],
        'value',
      ) as string[];

    if (isAllMakeOptionChecked) {
      onSelect(
        {
          ...payload,
          selectedOptions: newAllSelectedOptions,
          selectedMake: selectedMake ? uniqBy([...(selectedMake || []), make], (m) => upperCase(m)) : null,
        },
        'make_model.filters.[0]',
      );
    } else {
      onSelect(
        {
          ...payload,
          selectedOptions: newAllSelectedOptions,
          selectedMake: selectedMake
            ? uniqBy(
                selectedMake?.filter((m) => m !== make),
                (m) => upperCase(m),
              )
            : null,
        },
        'make_model.filters.[0]',
      );
    }
  };

  const handleCheckAllChange = async (e: any, data: Record<string, any>) => {
    const { checked } = e.target;
    const { make, options } = data || {};

    const remainingSelectedOptions = getAllSelectedOptions().filter(
      (allSelectedOption) => allSelectedOption.make !== make,
    );

    let modalOptions: DefaultOptionProps[] = [];

    const newMakeModelOptions = cloneDeep(makeModelOptions);
    const makeModelOptionsIndex = newMakeModelOptions?.findIndex((opt) => toUpper(opt.make) === toUpper(make));
    const currentMakeModelOption = newMakeModelOptions?.[makeModelOptionsIndex];

    // Get selected make from payload or from make filter
    const newMakeSettings = deepCopy(makeSetting);
    let selectedMake = get(payload, 'selectedMake') as string[];
    if (!selectedMake)
      selectedMake = map(
        get(newMakeSettings, 'filters.[0].selectedOptions') as DefaultOptionProps[],
        'value',
      ) as string[];

    if (checked) {
      if (options?.length > 0) {
        modalOptions = options;
      } else {
        modalOptions = await fetchMakeModelOptions(make, fetchMakesModels);
      }

      modalOptions = addMakeToSelectedMakeModelOptions(modalOptions, make);

      // update local state for new mapping
      currentMakeModelOption.models = modalOptions;
      currentMakeModelOption.props.payload.selectedOptions = modalOptions;
      currentMakeModelOption.props.payload.options = modalOptions;
      currentMakeModelOption.props.filter.selectedOptions = modalOptions;
      currentMakeModelOption.props.filter.options = modalOptions;

      selectedMake = uniqBy([...selectedMake, make], (m) => upperCase(m));
    } else {
      currentMakeModelOption.props.payload.selectedOptions = [];
      currentMakeModelOption.props.filter.selectedOptions = [];
      selectedMake = uniqBy(
        selectedMake.filter((m: string) => m !== make),
        (m) => upperCase(m),
      );
    }

    newMakeModelOptions[makeModelOptionsIndex] = currentMakeModelOption;
    setMakeModelOptions(newMakeModelOptions);

    const newSelectedOptions = checked ? [...remainingSelectedOptions, ...modalOptions] : [...remainingSelectedOptions];

    onSelect(
      {
        ...payload,
        selectedOptions: newSelectedOptions,
        selectedMake,
      },
      'make_model.filters.[0]',
    );
  };

  const renderPanel = () =>
    makeModelOptions?.map(({ make, props: modalProps }) => {
      const buttonProps = {
        ...modalProps,
        onChange: handleFilterChange,
        onSelect: handleFilterChange,
        filter: { ...modalProps.filter, isShowHeader: false }, // Hide header in each make panel
      };

      const numberSelectedFilters = numberOfSelectedOptions(modalProps.payload);
      const isCheckedAll = modalProps.payload.selectedOptions.some(
        (o: DefaultOptionProps) => toUpper(o.make) === toUpper(make),
      );

      const isIndeterminate =
        modalProps.payload.options?.length &&
        numberSelectedFilters &&
        modalProps.payload.options?.length !== numberSelectedFilters;
      return (
        <Panel
          header={
            <div className="collapse-panel-header">
              <div
                className="collapse-panel-header-checkbox"
                onClick={(e) => e.stopPropagation()}
                onKeyUp={() => {}}
                role="link"
                aria-hidden
              >
                <Checkbox
                  name={make}
                  indeterminate={isIndeterminate}
                  checked={isCheckedAll}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleCheckAllChange(e, modalProps?.payload);
                  }}
                />
              </div>
              <div className="collapse-panel-header-text">{make}</div>
            </div>
          }
          key={make}
        >
          <FilterButton {...buttonProps} type="secondary" />
        </Panel>
      );
    });

  const renderExpandIcon = (panelProps: any) => {
    if (panelProps.isActive) {
      return <UpOutlined />;
    }
    return <DownOutlined />;
  };

  return (
    <StyledFilterWrapper>
      <div className="filter-header">
        <div className="filter-header-text">{translation(label as string)}</div>
      </div>
      <StyledCollapse
        accordion
        expandIcon={renderExpandIcon}
        expandIconPosition="right"
        onChange={handleFetchModels}
        activeKey={activeKey}
      >
        {renderPanel()}
      </StyledCollapse>
    </StyledFilterWrapper>
  );
};

export default React.memo(FilterMakeModel);
