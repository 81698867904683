import React, {
  Dispatch,
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Button, Tabs } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import type {
  ContentProps,
  DefaultOptionProps,
  FilterProps,
  GroupSettingProps,
  GroupSettingsProps,
  SettingProps,
} from '@design-system/components/FiltersV1/FilterProps';

import FilterNumber from '@design-system/components/FiltersV1//elements/FilterNumber';
import FilterCheckbox from '@design-system/components/FiltersV1/elements/FilterCheckbox';
import FilterRangeInput from '@design-system/components/FiltersV1/elements/FilterRangeInput';
import FilterButton from '@design-system/components/FiltersV1/elements/FilterButton';
import FilterMakeModel from '@design-system/components/FiltersV1/elements/FilterMakeModel';
import FilterBoolean from '@design-system/components/FiltersV1/elements/FilterBoolean';
import FilterActiveBar from '@design-system/components/FiltersV1/FilterActiveBar';
import FilterCustomButton from '@design-system/components/FiltersV1/FilterCustomButton';
import { deepCopy, hasFilterOptions } from '@design-system/utils/utils';
import {
  convertObjToArray,
  convertPayloadHasOptionToArray,
  fmMakeOptionsFromStringArray,
  fmStringArrayFromMakeOptions,
} from '@design-system/utils/filters';
import useMobileView from '@design-system/hooks/useMobileView';

import { SelectedSearchFilterType } from '@source/types';
import useBrowseServices from '@source/hooks/useBrowseServices';

const { TabPane } = Tabs;

const StyledFilterForm = styled.div`
  ${(props) => props.theme.typo.style.body};
  ${(props) => props.theme.typo.familyGoogle.regular};
  height: 100%;

  &.filter-form-popover {
    .content {
      width: 350px;
      max-width: 350px;
      padding: 20px 10px;
    }
  }

  &.filter-form-tabs {
    .content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .filter-header {
    margin-bottom: 10px;

    .filter-header-text {
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
      ${(props) => props.theme.typo.style.mainButtonOrLink};
      color: ${(props) => props.theme.color.onSurface};
    }
    .filter-header-desc {
      ${(props) => props.theme.typo.familyGoogle.regular};
      ${(props) => props.theme.typo.style.captionRegular};
      color: ${(props) => props.theme.color.onSurface};
    }
  }
`;

const StyledTabsHeader = styled.div`
  width: 100%;
  box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.1);
  padding: 22px;

  .header-title {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;

    .clear-btn {
      font-weight: 700;
      font-size: 14px;
      color: ${(props) => props.theme.color.primaryBase};
      border: none;
      box-shadow: none;
      text-transform: capitalize;
      height: unset;
      justify-self: flex-start;
      padding: 0;
    }

    .modal-title-text {
      grid-column-start: 2;
    }
  }
`;

const StyledTabsFooterContainer = styled.div<{ isScreenSmallerThan1279: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
  position: sticky;
  bottom: 0px;
  box-shadow: 0px -6px 6px rgba(161, 161, 161, 0.25);

  .clear-btn {
    margin-left: 30px;
    ${(props) => props.theme.typo.style.mainButtonOrLink};
    color: ${(props) => props.theme.color.primaryBase};
    border: none;
    box-shadow: none;
    text-transform: capitalize;
    height: unset;
    justify-self: flex-start;
    padding: 0;
  }

  .filter-btn {
    width: ${(props) => (props?.isScreenSmallerThan1279 ? '50%' : '100%')};
    box-shadow: 0px 4px 4px rgba(161, 161, 161, 0.25);
  }
`;

const StyledTabText = styled.div`
  display: flex;
  align-items: baseline;
  ${(props) => props.theme.typo.style.smallButtonOrLink};
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  color: ${(props) => props.theme.color.onSurface};

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: transparent;
  }

  &.active {
    &:before {
      background-color: ${(props) => props.theme.color.primaryBase};
    }
  }
`;

const StyledPopFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  border-top: ${(props) => `0.5px solid ${props.theme.color.onBackgroundLowEmphasis}`};
`;

const StyledTabs = styled(Tabs)<{ isShowSelectedBar: boolean }>`
  --tabs-height: ${(props) => (props?.isShowSelectedBar ? '200px' : '140px')};
  height: calc(100% - var(--tabs-height));

  .ant-tabs-nav-operations {
    .ant-tabs-nav-more {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    width: 129px;
    background-color: ${(props) => props.theme.color.background};
    border-right: ${(props) => `0.5px solid ${props.theme.color.onBackgroundLowEmphasis}`};

    .ant-tabs-tab {
      height: 70px;
      font-size: 12px;
      font-weight: 600;
      ${(props) => props.theme.typo.familyGoogle.regular};
      padding: 23px 11px;
      margin: 0;

      .ant-tabs-tab-btn {
        color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
        text-align: left;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        text-shadow: none;
        margin-left: 0;

        @media only screen and (min-width: 768px) {
          margin-left: 10px;
        }
      }

      &.ant-tabs-tab-active {
        background: ${(props) => props.theme.color.primarySubtle};

        .ant-tabs-tab-btn {
          color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
        }
      }
    }

    @media only screen and (min-width: 576px) {
      width: 164px;

      .ant-tabs-tab {
        padding: 23px 17px;
      }
    }

    @media only screen and (min-width: 768px) {
      width: 258px;
    }

    @media only screen and (min-width: 992px) {
      width: 164px;
    }
  }

  .ant-tabs-content {
    height: 100%;
  }

  &.ant-tabs-left {
    > .ant-tabs-nav {
      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
      }

      .ant-tabs-ink-bar {
        left: 0;
        width: 6px;
        background: ${(props) => props.theme.color.primaryBase};
      }
    }
  }

  .ant-tabs-tabpane {
    height: 100%;
    overflow-y: auto;
    padding: 0 24px;

    .ant-divider {
      border-color: ${(props) => props.theme.greyBlurColor};
      margin: 31px 0 16px 0;
    }

    .content + .content {
      border-top: ${(props) => `2px solid ${props.theme.color.onBackgroundLowEmphasis}`};
    }
  }
`;

const StyledCloseIcon = styled(CloseCircleOutlined)`
  font-size: 21px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;

  &:hover {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const FullWidthButtonStyles = css`
  width: 100%;
  margin: 0;
`;

const ClearButtonStyles = css`
  border: 1px solid transparent;
  text-decoration: none;
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};

  &:hover,
  &:focus {
    text-decoration: underline;
    border: 1px solid transparent;
    box-shadow: none;
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  }
`;

// type FilterFormContent = {
//   clearText?: string;
//   saveText?: string;
// };

interface FilterFormProps extends PropsWithChildren {
  title?: ReactNode | undefined;
  settingName?: string;
  settingHeader?: string;
  isDirectFilter?: boolean;
  filters?: SettingProps[];
  initSettings?: SettingProps[];
  onFormSave?: (data: {
    payloads: Record<string, SettingProps>;
    isSaveAction?: boolean;
    isFetchHitCount?: boolean;
    isShowDrawer?: boolean;
  }) => void;
  onClose?: () => void;
  setSelectedSearchFilters?: Dispatch<SetStateAction<SelectedSearchFilterType>>;
  content?: ContentProps;
  hasTabHeader?: boolean;
  hasTabFooter?: boolean;
  hasPopoverFooter?: boolean;
  type?: string;
  isShowActiveBar?: boolean;
  isMobile?: boolean;
  isBooleanFilter?: boolean;
}

const FilterForm: FC<FilterFormProps> = ({
  content,
  filters,
  initSettings,
  type,
  onClose,
  hasTabHeader,
  hasTabFooter,
  hasPopoverFooter,
  isShowActiveBar,
  onFormSave,
  isMobile,
  ...otherProps
}) => {
  const { isBooleanFilter } = otherProps;
  const childRef = useRef<{ clearSelectValue: () => void }>();
  const isTabsType = type === 'tabs';
  const isScreenSmallerThan1279 = useMobileView(1279);
  const { browseState } = useBrowseServices();
  const [clickedFormSave, setClickedFormSave] = useState(false);

  const { currentFilterSettings } = browseState;
  const makeSettingFromFilter = deepCopy(currentFilterSettings.find((f) => f.name === 'make') as SettingProps);

  const { filterText, clearAllText, filterConfirmText, clearText, showHitsCarsText } = content || {};

  const [payloads, setPayloads] = useState(filters && keyBy(filters, 'name'));
  const [initPayload] = useState(initSettings && keyBy(initSettings, 'name'));
  const [groupedSettings, setGroupedSettings] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (isTabsType) {
      const isDisabled = payloads ? Object.values(payloads)?.some((p) => p.filters?.some((f) => f.isError)) : false;
      if (onFormSave && !isDisabled && !clickedFormSave) {
        const newPayloads = { ...payloads };

        onFormSave({
          payloads: (newPayloads as Record<string, SettingProps>) || null,
          isFetchHitCount: true,
          isShowDrawer: true,
        });
      }
    }
  }, [payloads, isTabsType]);

  useEffect(() => {
    setPayloads(filters && keyBy(filters, 'name'));
  }, [filters]);

  useEffect(() => {
    const updateGroupedSettings = () => {
      const newGroupedSettings: GroupSettingsProps = {};
      filters?.forEach((setting) => {
        const isActive = isScreenSmallerThan1279 ? setting.activeMobile && !setting.hideInFilters : setting.active;

        if (!isActive) {
          return;
        }

        const groupName = isScreenSmallerThan1279 ? setting.groupMobile : setting.group;
        const groupTitle = isScreenSmallerThan1279
          ? setting.groupHeaderMobile || setting.headerMobile
          : setting.groupHeader;
        const group = groupName || setting.name;
        const groupHeader = groupTitle || setting.header;

        if (group) {
          if (get(newGroupedSettings, group)) {
            const groupItems = get(newGroupedSettings, `${group}.groupItems`, []) as string[] | undefined[];
            const groupSettings = get(newGroupedSettings, `${group}.settings`, []) as string[] | undefined[];
            set(newGroupedSettings, `${group}.groupItems`, [...groupItems, setting.name]);
            set(newGroupedSettings, `${group}.settings`, [...groupSettings, setting]);
          } else {
            set(newGroupedSettings, group, {
              header: groupHeader,
              name: group,
              groupItems: [setting.name],
              settings: [setting],
            });
          }
        }
      });

      setGroupedSettings(pickBy(newGroupedSettings, hasFilterOptions));
    };

    updateGroupedSettings();
  }, [filters, isScreenSmallerThan1279]);

  // Singular atomic filter payload change
  const handleFilterChange = (payload: FilterProps | FilterProps[], path?: string) => {
    let newPayloads = deepCopy(payloads);
    newPayloads = path ? set(newPayloads || {}, path, payload) : newPayloads;

    // If touching brand & model filter
    // add `make` into the payload which has selectedOptions = selectedMake from 'make_model` filter
    if (path?.includes('make_model')) {
      const makeSetting = deepCopy(get(payloads, 'make') || makeSettingFromFilter);
      const selectedMake = get(payload, 'selectedMake', []) as string[];
      const selectedMakeOptions = get(makeSetting, 'filters.[0].selectedOptions') as DefaultOptionProps[];
      const selectedOptions = selectedMake ? fmMakeOptionsFromStringArray(selectedMake) : selectedMakeOptions;

      set(makeSetting, 'filters.[0].selectedOptions', selectedOptions);
      set(newPayloads, 'make', makeSetting);
    } else if (path?.includes('make')) {
      const selectedMakeOptions = get(payload, 'selectedOptions', []) as DefaultOptionProps[];
      const selectedMake = fmStringArrayFromMakeOptions(selectedMakeOptions);
      let newSelectedMakeModelOptions = get(newPayloads, 'make_model.filters.[0].selectedOptions');
      newSelectedMakeModelOptions = newSelectedMakeModelOptions.filter((makeModel: DefaultOptionProps) => {
        if (makeModel?.make) {
          return selectedMake?.includes(makeModel.make);
        }

        return selectedMake?.some((m) => `${makeModel?.value}`?.indexOf(`${m}`) === 0);
      });

      set(newPayloads, 'make_model.filters.[0].selectedOptions', newSelectedMakeModelOptions);
    }

    setPayloads(newPayloads);

    if (isBooleanFilter && onFormSave) {
      onFormSave({ payloads: newPayloads });
    }
  };

  const handleSavePayloads = (isClickedSave?: boolean) => {
    if (onFormSave && payloads) {
      setClickedFormSave(!!isClickedSave);
      onFormSave({ payloads });
    }
  };

  const handleOnClear = () => {
    const newPayloads = deepCopy(initPayload);

    // When clear in brand & model filter, clear `make` filter too.
    if (Object.keys(newPayloads).includes('make_model')) {
      const newMakeSetting = deepCopy(makeSettingFromFilter);
      set(newMakeSetting, 'filters.[0].selectedOptions', null);
      set(newPayloads, 'make', newMakeSetting);
    }

    setPayloads(deepCopy(initPayload));

    if (onFormSave && initPayload) onFormSave({ payloads: newPayloads, isSaveAction: false });
    childRef?.current?.clearSelectValue();
  };

  const renderFilter = ({
    filter,
    parentName,
    filterIndex,
  }: {
    filter: FilterProps;
    parentName: string;
    filterIndex: number;
  }) => {
    const {
      name,
      facetStats,
      header,
      headerMobile,
      // headerFormat
    } = filter || {};
    type nameType = keyof typeof payloads;

    const props = {
      key: name,
      name,
      onChange: handleFilterChange,
      onSelect: handleFilterChange,
      filter,
      path: `${parentName}.filters.[${filterIndex}]`,
      label: isScreenSmallerThan1279 ? headerMobile : header || '', // headerFormat ? getFormattedString(header, facetStats) : header,
      payload: payloads && payloads?.[parentName as nameType]?.filters?.[filterIndex],
      isMobile,
      ...facetStats,
    };

    switch (filter?.type) {
      case 'button':
        return <FilterButton type={filter.buttonsType || 'secondary'} {...props} />;
      case 'range_input':
        return <FilterRangeInput {...props} hasDivider={!isTabsType} />;
      case 'checkbox':
        return <FilterCheckbox {...props} childRef={childRef} />;
      case 'number':
        return <FilterNumber {...props} />;
      case 'make_model':
        return <FilterMakeModel {...props} />;
      default:
        return null;
    }
  };

  if (isBooleanFilter) {
    const parentName = Object.keys(payloads || {})[0];
    const path = `${parentName}.filters`;
    return (
      <FilterBoolean
        filter={filters?.[0]}
        payload={payloads?.[parentName]?.filters}
        onSelect={handleFilterChange}
        path={path}
      />
    );
  }

  if (isTabsType) {
    const activeSettings = convertPayloadHasOptionToArray(payloads || {});
    const isShowSelectedBar = isShowActiveBar && activeSettings?.length > 0;

    const renderSelectedFiltersBar = () => (
      <FilterActiveBar settings={activeSettings} onFormChange={handleFilterChange} isMobile />
    );

    const renderTabsHeader = () => (
      <StyledTabsHeader>
        <div className="header-title">
          {isScreenSmallerThan1279 && (
            <Button className="clear-btn" onClick={() => handleOnClear()}>
              {t(clearAllText || '')}
            </Button>
          )}
          <span className="modal-title-text">{t(filterText || '')}</span>
          <StyledCloseIcon onClick={onClose} />
        </div>
        {isShowActiveBar && renderSelectedFiltersBar()}
      </StyledTabsHeader>
    );

    const renderTabsFooter = () => {
      const isDisabled = payloads ? Object.values(payloads)?.some((p) => p.filters?.some((f) => f.isError)) : false;
      return (
        <StyledTabsFooterContainer isScreenSmallerThan1279={!isScreenSmallerThan1279}>
          {!isScreenSmallerThan1279 && (
            <Button className="clear-btn" onClick={() => handleOnClear()}>
              {t(clearAllText || '')}
            </Button>
          )}
          <FilterCustomButton
            className="filter-btn"
            disabled={isDisabled}
            isActive={!isDisabled}
            onClick={() => handleSavePayloads(true)}
            customStyles={FullWidthButtonStyles}
          >
            {/* {getConfirmBtnText?.(0) || ''} */}
            {showHitsCarsText}
          </FilterCustomButton>
        </StyledTabsFooterContainer>
      );
    };

    const renderTabText = (setting: GroupSettingProps) => {
      const isActivePanel = (settings: SettingProps[]) =>
        settings?.some((item: SettingProps) => item?.filters?.some((i: FilterProps) => i?.selectedOptions?.length));

      const groupedPayloads = setting?.groupItems
        ?.map((s: string) => convertObjToArray(payloads || {}).find((f) => f.name === s) || {})
        ?.filter((i: SettingProps) => !isEmpty(i));

      const hasSelectedOptions = groupedPayloads ? isActivePanel(groupedPayloads) : false;
      return (
        <StyledTabText className={hasSelectedOptions ? 'active' : ''}>
          <span>{t(setting?.header || '')}</span>
        </StyledTabText>
      );
    };

    return (
      <StyledFilterForm className="filter-form-tabs">
        {hasTabHeader && renderTabsHeader()}
        <StyledTabs
          defaultActiveKey="1"
          tabPosition="left"
          isShowSelectedBar={isShowSelectedBar || false}
          destroyInactiveTabPane
        >
          {convertObjToArray(groupedSettings)?.map((group) => (
            <TabPane key={`${group.name}`} tab={renderTabText(group)}>
              <Fragment key={group?.name}>
                {'settings' in group &&
                  group?.settings?.map((setting: any) =>
                    setting?.filters?.map((item: any, index: number) => (
                      <div className="content" key={item?.name}>
                        {renderFilter({ filter: item, parentName: setting.name, filterIndex: index })}
                      </div>
                    )),
                  )}
              </Fragment>
            </TabPane>
          ))}
        </StyledTabs>
        {hasTabFooter && renderTabsFooter()}
      </StyledFilterForm>
    );
  }

  const renderPopoverFooter = (group: any) => {
    const currentPayload = payloads?.['settings' in group && group?.settings?.[0]?.name];
    const isDisabled = currentPayload?.filters?.some((f) => f.isError);
    return (
      <StyledPopFooterContainer>
        <FilterCustomButton type="text" onClick={() => handleOnClear()} customStyles={ClearButtonStyles}>
          {t(clearText || '')}
        </FilterCustomButton>
        <FilterCustomButton disabled={isDisabled} isActive={!isDisabled} onClick={() => handleSavePayloads()}>
          {t(filterConfirmText || '')}
        </FilterCustomButton>
      </StyledPopFooterContainer>
    );
  };

  return (
    <StyledFilterForm className="filter-form-popover">
      {groupedSettings &&
        convertObjToArray(groupedSettings).map((group) => (
          <>
            <Fragment key={group?.name}>
              {'settings' in group &&
                group?.settings?.map((setting: any) =>
                  setting?.filters?.map((item: any, index: number) => (
                    <div className="content" key={item?.name}>
                      {renderFilter({ filter: item, parentName: setting.name, filterIndex: index })}
                    </div>
                  )),
                )}
            </Fragment>
            {hasPopoverFooter && !isBooleanFilter && renderPopoverFooter(group)}
          </>
        ))}
    </StyledFilterForm>
  );
};

FilterForm.defaultProps = {
  title: '',
  filters: [],
  initSettings: [],
  isDirectFilter: false,
  settingName: '',
  settingHeader: '',
  onFormSave: () => {},
  onClose: () => {},
  setSelectedSearchFilters: () => {},
  content: {
    clearText: 'Clear',
    saveText: 'Save',
  },
  hasTabHeader: true,
  hasTabFooter: true,
  hasPopoverFooter: true,
  isShowActiveBar: false,
  type: '',
  isMobile: false,
  isBooleanFilter: false,
};

export default FilterForm;
