import { Image } from 'antd';
import { padStart } from 'lodash';
import React, { useState, useEffect } from 'react';
import styled, { CSSObject, css } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(utc);

interface TimeCounterProps {
  counterBg: string;
  endTime?: string;
  customStyle?: CSSObject;
}

interface TimeLeft {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const StyledTimeCounterContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  font-size: 60px;

  .time-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .number {
      ${(props) => props.theme.typo.familyGoogle.poppinsBold};
      text-align: center;
      width: 20%;
    }
  }

  ${(props: { customStyle?: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}
`;

const calculateTimeLeft = (startTime?: string | Date | Dayjs, endTime?: string | Date | Dayjs): TimeLeft => {
  const start = dayjs.utc(startTime);
  const end = dayjs.utc(endTime);
  const diff = end.diff(start);

  if (!start.isValid() || !end.isValid() || diff < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const timeLeft = dayjs.duration(diff);

  return {
    days: timeLeft.days(),
    hours: timeLeft.hours(),
    minutes: timeLeft.minutes(),
    seconds: timeLeft.seconds(),
  };
};

const TimeCounter: React.FC<TimeCounterProps> = ({ counterBg, endTime, customStyle }) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(dayjs(), endTime));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(dayjs(), endTime));
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <StyledTimeCounterContainer customStyle={customStyle}>
      <Image src={counterBg} />
      <div className="time-box">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="number">
            {padStart(value.toString(), 2, '0')}
          </div>
        ))}
      </div>
    </StyledTimeCounterContainer>
  );
};

export default TimeCounter;
