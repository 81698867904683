import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import type { RequestOptions } from '@algolia/transporter';
import { useTranslation } from 'react-i18next';

import {
  DefaultOptionProps,
  FilterProps,
  QSFilterProps,
  SettingProps,
} from '@design-system/components/FiltersV1/FilterProps';
import FilterPopoverType from '@design-system/components/FiltersV1/FilterPopoverType';
import FilterTabType from '@design-system/components/FiltersV1/FilterTabType';
import FilterActiveBar from '@design-system/components/FiltersV1/FilterActiveBar';

import formatNumber, { deepCopy, getCustomLabel, getFormattedString } from '@design-system/utils/utils';
import {
  fetchMakeModelOptions,
  fetchOptionsFromAlgolia,
  fillMissingMakeModelOptions,
  getPayloads,
  mapFilterFromQSToCurrentFilterSettings,
  mapSettingData,
  syncCarroExclusiveIntoFilters,
  syncFacetsToFilterSettings,
  useBrandModelUtil,
} from '@design-system/utils/filters';

import { buildAlgoliaFilter, buildAlgoliaFilterCarsForSale } from '@source/services/Algolia';
import useAlgoliaService from '@source/hooks/useAlgoliaService';

import FILTER_CONFIGS from '@source/pages/Home/components/Filters/configs';
import FILTER_CONFIGS_SG from '@source/pages/Home/components/Filters/configs/sg';

import { CONTENT } from '@source/pages/Home/components/Filters/configs/constants';
import useBrowseServices from '@source/hooks/useBrowseServices';
import DataQuery from '@source/services/DataQuery';

const StyledFilters = styled.div`
  display: grid;
  place-content: center;
  grid-auto-flow: column;
  align-items: center;
`;

type FiltersDesktopContainerProps = {
  language: string;
  countryCode: string;
  onFilterSave: (params: RequestOptions, filterSettings?: SettingProps[], isFilterComponent?: boolean) => Promise<void>;
  onClearAllFilters: () => void;
};

const FiltersDesktopContainer: FC<FiltersDesktopContainerProps> = ({
  language,
  countryCode,
  onFilterSave,
  onClearAllFilters,
}: FiltersDesktopContainerProps) => {
  const router = useRouter();
  const [payloadsHit, setPayloadsHit] = useState(0);
  const [promoLabel, setPromoLabel] = useState(undefined);
  const { fetchAlgoliaData } = useAlgoliaService();
  const { browseState, setCurrentFilterSettings, setSyncUpUrlToFilter, setInitialFilterSettings } = useBrowseServices();
  const { currentFilterSettings, syncUpUrlToFilter, initialFilterSettings } = browseState;

  const { t } = useTranslation();

  const currentFilterConfig = FILTER_CONFIGS[countryCode] || FILTER_CONFIGS_SG;

  const [content, setContent] = useState(CONTENT);

  const { fetchMakesModels } = useBrandModelUtil();

  useEffect(() => {
    (async () => {
      const { facets } = await fetchOptionsFromAlgolia(currentFilterSettings, currentFilterConfig, fetchAlgoliaData);
      if (facets.promotion_name) {
        setPromoLabel(facets.promotion_name);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // Router is ready to use
      if (router.isReady && router.asPath && syncUpUrlToFilter) {
        // convert query string into first draft of filters
        const payloads = getPayloads(router.query, router.query.bodyType, countryCode);

        const { data: carroExclusiveData } = await DataQuery.getCarroExclusiveFilters(countryCode, {
          locale: language,
        });
        // map above into current filter setting
        let settings = mapFilterFromQSToCurrentFilterSettings(currentFilterSettings, payloads);

        let defaultModels;
        if (router.query.brand && router.query.model) {
          defaultModels = await fetchMakeModelOptions(router.query.brand as string, fetchMakesModels);
        }
        // add some options from algolia into filters which need to be fetched
        const { newFilterSettings, facetStats, facets } = await fetchOptionsFromAlgolia(
          settings,
          currentFilterConfig,
          fetchAlgoliaData,
          defaultModels,
        );

        const newFilterSettingsWithCarroExclusives = syncCarroExclusiveIntoFilters(
          newFilterSettings,
          carroExclusiveData.data,
          countryCode,
        );

        settings = mapFilterFromQSToCurrentFilterSettings(newFilterSettingsWithCarroExclusives, payloads);

        // pass facets to init filter data too.
        const newInitialFilterSettings = syncFacetsToFilterSettings(
          isEmpty(initialFilterSettings) ? currentFilterConfig?.initialFilterSettings : initialFilterSettings,
          facets,
          facetStats,
        );

        const newInitialFilterSettingsWithCarroExclusives = syncCarroExclusiveIntoFilters(
          newInitialFilterSettings,
          carroExclusiveData.data,
          countryCode,
        );

        setInitialFilterSettings(newInitialFilterSettingsWithCarroExclusives);

        const payloadSettingList = payloads?.map((p: QSFilterProps) => p.settingName);

        let newSettings =
          payloads?.length > 0
            ? newInitialFilterSettings.map((initFilter) => {
                if (payloadSettingList?.includes(initFilter.name)) {
                  return settings.find((s) => s.name === initFilter.name) || initFilter;
                }

                return initFilter;
              })
            : newInitialFilterSettings;

        // add missing makeModelOptions if only make on router and no makeModelOptions declared
        newSettings = await fillMissingMakeModelOptions(newSettings, fetchMakesModels);

        setCurrentFilterSettings(newSettings);

        const parsedFilter = buildAlgoliaFilter(newSettings);

        // Todo_Son: Refactor
        const algoliaParams = {
          page: 0, // since algolia Page numbers are zero-based
          facets: ['*'],
          filters: parsedFilter,
          hitsPerPage: currentFilterConfig.hitsPerPage,
          attributesToRetrieve: currentFilterConfig.attributesToRetrieve,
        };

        setSyncUpUrlToFilter(false);
        onFilterSave(algoliaParams, newSettings, true);
      }
    })();
  }, [router.asPath, router.isReady, countryCode]);

  const {
    otherFilters,
    desktopFilters,
  }: {
    otherFilters?: string[];
    desktopFilters?: string[];
  } = currentFilterConfig;

  const newDesktopFilters = useMemo(
    () => (promoLabel && desktopFilters ? ['promotions', ...desktopFilters] : desktopFilters),
    [promoLabel],
  );

  useEffect(() => {
    setContent({
      ...content,
      showHitsCarsText: getFormattedString(t(content.showHitsCars), {
        total: payloadsHit.toString(),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadsHit, language]);

  const hasOtherFilters = otherFilters && otherFilters.length > 0;

  const mainFilterSettings = useMemo(
    () => mapSettingData(newDesktopFilters, currentFilterSettings, true),
    [currentFilterSettings, newDesktopFilters],
  );

  const initMainFilterSettings = useMemo(
    () => mapSettingData(newDesktopFilters, initialFilterSettings, true),
    [initialFilterSettings, newDesktopFilters],
  );

  const otherFilterSettings =
    useMemo(
      () =>
        hasOtherFilters
          ? otherFilters.map((m) => currentFilterSettings.find((f) => f.name === m) || {}).filter((i) => !isEmpty(i))
          : [],
      [currentFilterSettings, otherFilters, hasOtherFilters],
    ) || []; // eslint-disable-line

  const initOtherFilterSettings =
    useMemo(
      () =>
        hasOtherFilters
          ? otherFilters
              .map((m) => initialFilterSettings.find((f: SettingProps) => f.name === m) || {})
              .filter((i) => !isEmpty(i))
          : [],
      [initialFilterSettings, otherFilters, hasOtherFilters],
    ) || [];

  const activeFilterSetting = useMemo(
    () =>
      currentFilterSettings?.filter((setting: SettingProps) =>
        setting?.filters?.some(
          (item: FilterProps) => (item?.selectedOptions && item?.selectedOptions?.length) || item?.selectedBoolean,
        ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilterSettings, language],
  );

  const { moreFilterText } = content;

  const fetchPayloadHitFromAlgolia = async (params: RequestOptions) => {
    // eslint-disable-next-line camelcase
    const filtersToGetCarsForSale = [params.filters, buildAlgoliaFilterCarsForSale()]
      .filter((i) => !isEmpty(i))
      .join(' AND ');

    const customParamsCarsForSale = {
      ...params,
      filters: filtersToGetCarsForSale,
    };

    const { nbHits } = await fetchAlgoliaData(customParamsCarsForSale);

    setPayloadsHit(nbHits);
  };

  // Todo: Need to refactor
  const handleOnFilterSave = async (
    payloads: Record<string, SettingProps>,
    isSaveAction: boolean,
    type: string,
    isFetchHitCount: boolean,
    isCloseActiveFilter = false,
  ) => {
    const isPopoverType = type === 'popover';
    let newCurrentFilterSettings = deepCopy(currentFilterSettings);

    Object.keys(payloads)
      .filter((i) => i)
      .forEach((settingName: string) => {
        const filtersMap = payloads[settingName]?.filters;
        const newFiltersMap = filtersMap?.map((payload: FilterProps) => {
          const newPayload = { ...payload, displayType: type };
          if (newPayload.type === 'range_input') {
            const { facetStats, selectedMinInput, selectedMaxInput, name } = payload;
            if ((selectedMinInput !== null || selectedMaxInput !== null) && facetStats) {
              newPayload.selectedMinInput = parseFloat(
                (selectedMinInput === null ? facetStats.min : selectedMinInput)?.toString() || '',
              );
              newPayload.selectedMaxInput = parseFloat(
                (selectedMaxInput === null ? facetStats.max : selectedMaxInput)?.toString() || '',
              );
            }
          }
          if (newPayload.type === 'button' && newPayload?.selectedCustomLabel) {
            const { selectedCustomLabel, selectedOptions } = newPayload;
            newPayload.selectedOptions = selectedOptions?.map((newOpt: DefaultOptionProps) => ({
              ...newOpt,
              customLabel: getCustomLabel(t(selectedCustomLabel || ''), newOpt),
            }));
          }

          if (isPopoverType) {
            if (newPayload.type === 'number') {
              const { defaultValue, selectedNumber, name, selectedCustomLabel } = payload;
              if (defaultValue && selectedNumber === null) {
                newPayload.selectedNumber = isSaveAction ? defaultValue : null;
              }

              if (
                countryCode === 'hk' &&
                !isCloseActiveFilter &&
                name?.includes('inventory.number_of_owners') &&
                defaultValue === 0 &&
                selectedNumber === null
              ) {
                newPayload.selectedNumber = 0;
              }

              newPayload.selectedOptions = isSaveAction
                ? [
                    {
                      label: selectedNumber || defaultValue,
                      value: selectedNumber || defaultValue,
                      name,
                      customLabel: getCustomLabel(t(selectedCustomLabel || '') || '', newPayload),
                    },
                  ]
                : null;
            }
            if (newPayload.type === 'range_input') {
              const {
                facetStats,
                selectedMinInput,
                selectedMaxInput,
                name,
                selectedCustomLabel,
                requireCommaFormat,
                displayMultiplier,
              } = payload;

              const newSelectedMinInput = parseFloat(
                (selectedMinInput === null ? facetStats?.min : selectedMinInput)?.toString() || '',
              );
              const newSelectedMaxInput = parseFloat(
                (selectedMaxInput === null ? facetStats?.max : selectedMaxInput)?.toString() || '',
              );

              if (selectedMinInput === null && selectedMaxInput === null && facetStats) {
                newPayload.selectedMinInput = isSaveAction ? newSelectedMinInput : null;
                newPayload.selectedMaxInput = isSaveAction ? newSelectedMaxInput : null;
              }
              newPayload.selectedOptions = isSaveAction
                ? [
                    {
                      name,
                      label: `${newSelectedMinInput} - ${newSelectedMaxInput}`,
                      customLabel: getCustomLabel(t(selectedCustomLabel || '') || '', {
                        ...newPayload,
                        selectedMinInput: formatNumber(newSelectedMinInput, requireCommaFormat, displayMultiplier),
                        selectedMaxInput: formatNumber(newSelectedMaxInput, requireCommaFormat, displayMultiplier),
                      }),
                    },
                  ]
                : null;
            }
          }

          return { ...payload, ...newPayload };
        });

        newCurrentFilterSettings = newCurrentFilterSettings.map((setting: SettingProps) => {
          if (setting.name === settingName) {
            return { ...setting, filters: newFiltersMap };
          }

          return { ...setting };
        });
      });

    // Todo_Son: temp fix:
    if (!isSaveAction) {
      const params = {
        page: 0, // since algolia Page numbers are zero-based
        facets: ['*'],
        filters: '',
        hitsPerPage: currentFilterConfig.hitsPerPage,
        attributesToRetrieve: currentFilterConfig.attributesToRetrieve,
      };

      // eslint-disable-next-line camelcase
      const { facets = {}, facets_stats = {} } = await fetchAlgoliaData(params);
      newCurrentFilterSettings = syncFacetsToFilterSettings(
        newCurrentFilterSettings,
        facets || {},
        // eslint-disable-next-line camelcase
        facets_stats || {},
      );
    }

    if (!isFetchHitCount) {
      setCurrentFilterSettings(newCurrentFilterSettings);
    }

    const parsedFilter = buildAlgoliaFilter(newCurrentFilterSettings);

    // Todo_Son: Refactor
    const algoliaParams = {
      page: 0, // since algolia Page numbers are zero-based
      facets: ['*'],
      filters: parsedFilter,
      hitsPerPage: currentFilterConfig.hitsPerPage,
      attributesToRetrieve: currentFilterConfig.attributesToRetrieve,
    };

    if (isFetchHitCount) {
      fetchPayloadHitFromAlgolia(algoliaParams);
    } else {
      setSyncUpUrlToFilter(false);
      onFilterSave(algoliaParams, newCurrentFilterSettings, true);
    }
  };

  return (
    <>
      <StyledFilters>
        {mainFilterSettings?.map((setting: SettingProps) => (
          <FilterPopoverType
            key={setting?.name}
            content={content}
            setting={setting}
            disableSelectedFilterBtn
            initSettings={initMainFilterSettings.find((item: SettingProps) => item.name === setting.name)}
            onFormSave={handleOnFilterSave}
          >
            {t(setting?.header || '')}
          </FilterPopoverType>
        ))}
        {otherFilterSettings && otherFilterSettings.length > 0 && (
          <FilterTabType
            content={content}
            settings={otherFilterSettings || []}
            initSettings={initOtherFilterSettings}
            onFormSave={handleOnFilterSave}
          >
            {t(moreFilterText)}
          </FilterTabType>
        )}
      </StyledFilters>
      <FilterActiveBar
        content={content}
        settings={activeFilterSetting}
        onFormSave={handleOnFilterSave}
        initSettings={initialFilterSettings}
        onClearAllFilters={onClearAllFilters}
      />
    </>
  );
};

export default React.memo(FiltersDesktopContainer);
