import { getStaticCDN } from '@design-system/utils/utils';
import { HowToPurchaseProps } from '@source/pages/Home/components/HowToPurchase/HowToPurchaseProps';

export const PURCHASE_POSITION: { [key: string]: number } = {
  xs: 3,
  sm: 3,
  md: 6,
  lg: 6,
  xl: 6,
  xxl: 6,
};

export const HOW_TO_PURCHASE_SG: HowToPurchaseProps = {
  title: 'home.howToPurchase.title',
  content: [
    {
      name: 'home.howToPurchase.content1.name',
      image: getStaticCDN('/assets/shared/browse/find-for-me.svg'),
      description: 'home.howToPurchase.content1.description',
    },
    {
      name: 'home.howToPurchase.content2.name',
      image: getStaticCDN('/assets/shared/home/discover-your-car-v2.svg'),
      description: 'home.howToPurchase.content2.description',
    },
    {
      name: 'home.howToPurchase.content3.name',
      image: getStaticCDN('/assets/shared/buy-online-onboarding/car.svg'),
      description: 'home.howToPurchase.content3.description',
    },
  ],
};

export const HOW_TO_PURCHASE_MY: HowToPurchaseProps = {
  title: 'home.howToPurchase.title',
  content: [
    {
      name: 'home.howToPurchase.content1.name',
      image: getStaticCDN('/assets/shared/browse/find-for-me.svg'),
      description: 'home.howToPurchase.content1.description',
    },
    {
      name: 'home.howToPurchase.content2.name',
      image: getStaticCDN('/assets/shared/home/discover-your-car-v2.svg'),
      description: 'home.howToPurchase.content2.description',
    },
    {
      name: 'home.howToPurchase.content3.name',
      image: getStaticCDN('/assets/shared/buy-online-onboarding/car.svg'),
      description: 'home.howToPurchase.content3.description',
    },
  ],
};

export const HOW_TO_PURCHASE_ID: HowToPurchaseProps = {
  title: 'home.howToPurchase.title',
  content: [
    {
      name: 'home.howToPurchase.content1.name',
      image: getStaticCDN('/assets/shared/browse/find-for-me.svg'),
      description: 'home.howToPurchase.content1.description',
    },
    {
      name: 'home.howToPurchase.content2.name',
      image: getStaticCDN('/assets/shared/home/discover-your-car-v2.svg'),
      description: 'home.howToPurchase.content2.description',
    },
    {
      name: 'home.howToPurchase.content3.name',
      image: getStaticCDN('/assets/shared/buy-online-onboarding/car.svg'),
      description: 'home.howToPurchase.content3.description',
    },
  ],
};

export const HOW_TO_PURCHASE_TH: HowToPurchaseProps = {
  title: 'home.howToPurchase.title',
  content: [
    {
      name: 'home.howToPurchase.content1.name',
      image: getStaticCDN('/assets/shared/browse/find-for-me.svg'),
      description: 'home.howToPurchase.content1.description',
    },
    {
      name: 'home.howToPurchase.content2.name',
      image: getStaticCDN('/assets/shared/home/discover-your-car-v2.svg'),
      description: 'home.howToPurchase.content2.description',
    },
    {
      name: 'home.howToPurchase.content3.name',
      image: getStaticCDN('/assets/shared/buy-online-onboarding/car.svg'),
      description: 'home.howToPurchase.content3.description',
    },
  ],
};

export const HOW_TO_PURCHASE_TW: HowToPurchaseProps = {
  title: 'home.howToPurchase.title',
  content: [
    {
      name: 'home.howToPurchase.content1.name',
      image: getStaticCDN('/assets/shared/browse/find-for-me.svg'),
      description: 'home.howToPurchase.content1.description',
    },
    {
      name: 'home.howToPurchase.content2.name',
      image: getStaticCDN('/assets/shared/home/discover-your-car-v2.svg'),
      description: 'home.howToPurchase.content2.description',
    },
    {
      name: 'home.howToPurchase.content3.name',
      image: getStaticCDN('/assets/shared/buy-online-onboarding/car.svg'),
      description: 'home.howToPurchase.content3.description',
    },
  ],
};

export const HOW_TO_PURCHASE_HK: HowToPurchaseProps = {
  title: 'home.howToPurchaseHK.title',
  content: [
    {
      name: 'home.howToPurchaseHK.content1.name',
      image: getStaticCDN('/assets/shared/browse/find-for-me.svg'),
      description: 'home.howToPurchaseHK.content1.description',
    },
    {
      name: 'home.howToPurchaseHK.content2.name',
      image: getStaticCDN('/assets/shared/home/discover-your-car-v2.svg'),
      description: 'home.howToPurchaseHK.content2.description',
    },
    {
      name: 'home.howToPurchaseHK.content3.name',
      image: getStaticCDN('/assets/shared/buy-online-onboarding/car.svg'),
      description: 'home.howToPurchaseHK.content3.description',
    },
  ],
};

const HOW_TO_PURCHASES = {
  sg: HOW_TO_PURCHASE_SG,
  my: HOW_TO_PURCHASE_MY,
  id: HOW_TO_PURCHASE_ID,
  th: HOW_TO_PURCHASE_TH,
  jp: HOW_TO_PURCHASE_TW,
  hk: HOW_TO_PURCHASE_HK,
};

export default HOW_TO_PURCHASES;
