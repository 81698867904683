import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Slider, Tooltip } from 'antd';
import { SliderRangeProps } from 'antd/lib/slider';
import { useTranslation } from 'react-i18next';

import FilterInput from '@design-system/components/FiltersV1/elements/FilterInput';
import type { FilterProps } from '@design-system/components/FiltersV1/FilterProps';
import formatNumber, { getCustomLabel, getFormattedString } from '@design-system/utils/utils';
import { THEME } from '@source/constants/theme';

const StyledFilterWrapper = styled.div`
  .header-text {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }
`;

const StyledRangedInputsWrapper = styled.div.withConfig({
  componentId: 'rangedInputsWrapper', // pass unique componentId
})<{ hasDivider?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.hasDivider ? '4.5fr 1fr 4.5fr' : '4.5fr 4.5fr')};
  column-gap: ${(props) => !props.hasDivider && '8px'};
  justify-items: center;
  align-items: center;
`;

const StyledDivider = styled.span.withConfig({
  componentId: 'rangedInputsDividerWrapper', // pass unique componentId
})`
  height: 2px;
  background-color: ${(props) => props.theme.color.onBackgroundLowEmphasis}; // #d4d4d4;
  width: 8px;
`;

const StyledSlider = styled(Slider)<SliderRangeProps>`
  margin-top: 31px !important;

  .ant-slider-track {
    background-color: ${(props) => props.theme.color.primaryBase}!important;
  }

  .ant-slider-handle {
    border: 3px solid ${(props) => props.theme.color.primaryBase}!important;
    width: 20px;
    height: 20px;
    margin-top: -7px;
    &:focus {
      box-shadow: 0 0 0 5px rgb(255 87 34 / 12%);
    }
  }
`;

type FilterRangeInputProps = {
  label?: string;
  path?: string;
  payload?: FilterProps;
  filter?: FilterProps;
  min?: number | null;
  max?: number | null;
  hasDivider?: boolean;
  hasSlider?: boolean;
  onChange: (payload: FilterProps, path: string) => void;
};

const FilterRangeInput = ({
  label,
  path,
  payload,
  filter,
  min,
  max,
  hasDivider,
  hasSlider,
  onChange,
}: FilterRangeInputProps) => {
  const { selectedMinInput, selectedMaxInput, placeholderMin, placeholderMax, suffix, prefix } = payload || {};
  const {
    requireCommaFormat,
    displayMultiplier,
    requireFormatValue = true,
    selectedCustomLabel,
    isShowHeader = true,
  } = filter || {};
  const { t } = useTranslation();
  const minValue =
    selectedMinInput === 0 || Number.isNaN(selectedMinInput)
      ? 0
      : parseFloat((selectedMinInput || min || 0).toString());
  const maxValue =
    selectedMaxInput === 0 || Number.isNaN(selectedMaxInput)
      ? 0
      : parseFloat((selectedMaxInput || max || 0).toString());
  const [minMaxArray, setMinMaxArray] = useState([minValue, maxValue] as [number, number]);

  useEffect(() => {
    setMinMaxArray([minValue, maxValue]);
  }, [minValue, maxValue]);

  useEffect(() => {
    if (payload?.selectedMinInput === null && payload?.selectedMaxInput === null) {
      return;
    }

    const {
      selectedMinInput: filterSelectedMinInput,
      selectedMaxInput: filterSelectedMaxInput,
      name: filterName,
    } = payload || {};

    const formattedCustomLabel = getCustomLabel(t(selectedCustomLabel || ''), {
      ...filter,
      selectedMinInput: formatNumber(filterSelectedMinInput, requireCommaFormat, displayMultiplier),
      selectedMaxInput: formatNumber(filterSelectedMaxInput, requireCommaFormat, displayMultiplier),
    });

    const newSelectedOption = {
      name: filterName,
      label: `${minValue} - ${maxValue}`,
      customLabel: formattedCustomLabel,
      value: `${minValue} - ${maxValue}`,
    };

    const newPayload = {
      ...payload,
      selectedMinInput: minValue,
      selectedMaxInput: maxValue,
      selectedOptions: [newSelectedOption],
    };

    if (onChange) onChange(newPayload, path || 'fake_path');

    setMinMaxArray([minValue, maxValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.selectedMinInput, payload?.selectedMaxInput]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value: targetVal } = e.target;

    if (/[a-z]/gi.test(targetVal)) {
      // if has alphabets, don't persist
      return;
    }

    const targetValue = requireCommaFormat ? targetVal.replaceAll(/[,.]/g, '') : targetVal; // replace commas and dots (delimiters)
    let value = parseInt(`${targetValue} || 0`, 10); // In case of 2 digits, not to include '0' as suffix, eg 02 - 2

    if (displayMultiplier) {
      value /= displayMultiplier;
    }

    let newPayload = {
      ...payload,
    };

    const [minVal, maxVal] = minMaxArray || [0, 0];

    if (name === 'selectedMinInput') {
      newPayload = { ...newPayload, isError: value > maxVal };
    }

    if (name === 'selectedMaxInput') {
      newPayload = { ...newPayload, isError: value < minVal };
    }

    newPayload = {
      ...newPayload,
      [name]: value,
    };

    onChange(newPayload, path || 'fake_path');

    setMinMaxArray([minValue, maxValue]);
  };

  const handleRangeChange = (rangeValues: [number, number], isAfter?: boolean) => {
    const [selectedMinRangeInput, selectedMaxRangeInput] = rangeValues || [0, 0];
    if (isAfter) {
      const newPayload = {
        ...payload,
        selectedMinInput: selectedMinRangeInput,
        selectedMaxInput: selectedMaxRangeInput,
        selectedOptions: [
          {
            label: `${selectedMinRangeInput} - ${selectedMaxRangeInput}`,
            value: `${selectedMinRangeInput} - ${selectedMaxRangeInput}`,
            name: payload?.name,
            customLabel: getCustomLabel(t(selectedCustomLabel || '') || '', {
              ...filter,
              selectedMaxInput: selectedMinRangeInput,
              selectedMinInput: selectedMinRangeInput,
            }),
          },
        ],
      };
      onChange(newPayload, path || 'fake_path');
    }

    setMinMaxArray([selectedMinRangeInput, selectedMaxRangeInput]);
  };

  const sliderTipFormatter = (value?: number) => formatNumber(value, requireCommaFormat, displayMultiplier);

  const newMinValue = requireFormatValue ? formatNumber(minValue, requireCommaFormat, displayMultiplier) : minValue;
  const newMaxValue = requireFormatValue ? formatNumber(maxValue, requireCommaFormat, displayMultiplier) : maxValue;

  const { detailsText, facetStats } = filter || {};

  const averageValue = requireFormatValue
    ? formatNumber(facetStats?.avg, requireCommaFormat, displayMultiplier)
    : facetStats?.avg;

  return (
    <StyledFilterWrapper>
      <>
        {isShowHeader && (
          <div className="filter-header">
            <div className="filter-header-text">{t(label || '')}</div>
            {detailsText && (
              <div className="filter-header-desc">
                {getFormattedString(t(detailsText.average), {
                  avg: averageValue,
                })}
              </div>
            )}
          </div>
        )}
        {hasSlider && (
          <StyledSlider
            range
            min={min || 0}
            max={max || 0}
            value={minMaxArray || [0, 0]}
            onChange={handleRangeChange}
            onAfterChange={(value: [number, number]) => handleRangeChange(value, true)}
            tipFormatter={sliderTipFormatter}
          />
        )}
        <Tooltip
          destroyTooltipOnHide
          title={t('home.filters.error.validRange')}
          color={THEME.color.errorHighEmphasis}
          open={payload?.isError || false}
          placement="top"
          getPopupContainer={(trigger) => trigger}
        >
          <StyledRangedInputsWrapper hasDivider={hasDivider}>
            <FilterInput
              name="selectedMinInput"
              placeholder={placeholderMin}
              onChange={handleChange}
              value={newMinValue?.toString()}
              suffix={suffix}
              prefix={prefix}
            />
            {hasDivider && <StyledDivider />}
            <FilterInput
              name="selectedMaxInput"
              placeholder={placeholderMax}
              onChange={handleChange}
              value={newMaxValue?.toString()}
              suffix={suffix}
              prefix={prefix}
            />
          </StyledRangedInputsWrapper>
        </Tooltip>
      </>
    </StyledFilterWrapper>
  );
};

FilterRangeInput.defaultProps = {
  payload: {},
  filter: {},
  label: '',
  path: '',
  min: 0,
  max: 0,
  hasDivider: true,
  hasSlider: true,
};

export default FilterRangeInput;
