import { TitleTypography } from '@design-system/components';
import React from 'react';
import styled from 'styled-components';

const StyledTitleTypography = styled(TitleTypography)`
  margin-bottom: 0 !important;
`;

// eslint-disable-next-line import/prefer-default-export
export const HIT_CAR_RESULT_CONTENT: Record<string, any> = {
  en: {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total} Used Cars</span> <span className="text-base">For Sale</span>
      </StyledTitleTypography>
    ),
  },
  id: {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total} Mobil</span> <span className="text-base">bekas untuk dijual</span>
      </StyledTitleTypography>
    ),
  },
  th: {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">ค้นพบรถมือสอง {total} คัน</span>
      </StyledTitleTypography>
    ),
  },
  ms: {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total} kereta terpakai</span> <span className="text-base">untuk dijual</span>
      </StyledTitleTypography>
    ),
  },
  'zh-TW': {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total}</span> <span className="text-base"> 台中古車 找到</span>
      </StyledTitleTypography>
    ),
  },
  'zh-SG': {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total}</span> <span className="text-base"> 二手车出售</span>
      </StyledTitleTypography>
    ),
  },
  'zh-MY': {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total}</span> <span className="text-base"> 二手车出售</span>
      </StyledTitleTypography>
    ),
  },
  ja: {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total}</span> <span className="text-base"> 台がヒットしました</span>
      </StyledTitleTypography>
    ),
  },
  'ms-SG': {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-bold">{total} Kereta Terpakai </span> <span className="text-base">Untuk Dijual</span>
      </StyledTitleTypography>
    ),
  },
  'zh-Hans-HK': {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-base"> </span> <span className="text-bold">{total} 辆二手车出售</span>
      </StyledTitleTypography>
    ),
  },
  'zh-Hant-HK': {
    showResultText: '',
    getResultText: (total: any) => (
      <StyledTitleTypography className="mainButtonOrLink">
        <span className="text-base"> </span> <span className="text-bold">{total} 輛二手車出售</span>
      </StyledTitleTypography>
    ),
  },
};
