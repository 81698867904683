import React, { FC } from 'react';
import styled, { CSSObject } from 'styled-components';
import AwesomeButton from '../AwesomeButton';

const StyledFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  box-shadow: 0px 0px 13px -6px rgba(0, 0, 0, 0.75);
  border-radius: 20px;

  @media screen and (max-width: 767px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
export interface FormFooterProps {
  onConfirm?: () => void;
  onClear?: () => void;
  confirmText?: string;
  clearText?: string;
  customButtonCss?: CSSObject;
  confirmButtonType?: string;
  confirmButtonDisabled?: boolean;
}

const FormFooter: FC<FormFooterProps> = ({
  onConfirm,
  onClear,
  confirmText,
  clearText,
  customButtonCss,
  confirmButtonType = 'active',
  confirmButtonDisabled = false,
}) => (
  <StyledFooterContainer>
    {clearText && (
      <AwesomeButton customStyles={customButtonCss} type="clear" onClick={onClear} buttonText={clearText} />
    )}
    <AwesomeButton
      customStyles={customButtonCss}
      type={confirmButtonType}
      disabled={confirmButtonDisabled}
      onClick={onConfirm}
      buttonText={confirmText}
    />
  </StyledFooterContainer>
);

FormFooter.defaultProps = {
  confirmButtonType: 'active',
  confirmButtonDisabled: false,
  onConfirm: undefined,
  onClear: undefined,
  confirmText: '',
  clearText: '',
  customButtonCss: undefined,
};

export default FormFooter;
