import React, { FC, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import LessAwesomeButton from '../LessAwesomeButton';

export interface LocationOptionProps {
  value: string;
  label: string;
  isAllBtn?: boolean;
}

export interface FilterProps {
  selectedOptions: Array<LocationOptionProps>;
  options: Array<LocationOptionProps>;
}

export interface FilterButtonProps {
  filter?: FilterProps;
  colResponsive?: { [key: string]: number | object };
  onSelect: (payload: LocationOptionProps[]) => void;
  countryCode: string;
  getTranslateText: (label: string) => string;
}

const FilterButton: FC<FilterButtonProps> = ({ filter, colResponsive, onSelect, countryCode, getTranslateText }) => {
  const { t } = useTranslation();
  const selectedOptions = filter?.selectedOptions || [];
  const options = filter?.options || [];
  const [isAllBtnSelected, setIsAllBtnSelected] = useState(!selectedOptions?.length);

  const buttonAllItem = useMemo(
    () => ({
      label: '',
      value: '',
      isAllBtn: true,
    }),
    [],
  );
  const onButtonClick = (item: LocationOptionProps) => {
    let newSelectedOptions = selectedOptions?.find((opt) => opt.value === item.value)
      ? selectedOptions.filter((opt) => opt.value !== item.value)
      : selectedOptions.concat(item);

    if (item.isAllBtn || !newSelectedOptions.length) {
      newSelectedOptions = [];
      setIsAllBtnSelected(true);
    } else {
      setIsAllBtnSelected(false);
    }
    onSelect(newSelectedOptions);
  };

  return (
    <Row gutter={[15, 16]}>
      {options.map((item) => {
        const isSelected = !!selectedOptions?.find((opt) => opt.value === item.value);
        return (
          <Col
            key={item.value}
            xs={colResponsive?.xs}
            sm={colResponsive?.sm}
            md={colResponsive?.md}
            lg={colResponsive?.lg}
            xl={colResponsive?.xl}
            xxl={colResponsive?.xxl}
          >
            <LessAwesomeButton
              size="large"
              type={isSelected ? 'active' : 'primary'}
              shape="round"
              customStyles={{
                width: '100%',
                // In Text Overflow case, concat with dot[.]
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                margin: 0,
              }}
              onClick={() => onButtonClick(item)}
              variant="outline"
            >
              <div className="text-one-line">{getTranslateText(countryCode === 'th' ? item.value : item.label)}</div>
            </LessAwesomeButton>
          </Col>
        );
      })}
      <Col
        xs={colResponsive?.xs}
        sm={colResponsive?.sm}
        md={colResponsive?.md}
        lg={colResponsive?.lg}
        xl={colResponsive?.xl}
        xxl={colResponsive?.xxl}
      >
        <LessAwesomeButton
          size="large"
          type={isAllBtnSelected ? 'active' : 'primary'}
          shape="round"
          customStyles={{
            width: '100%',
            // In Text Overflow case, concat with dot[.]
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            margin: 0,
          }}
          onClick={() => onButtonClick(buttonAllItem)}
          variant="outline"
        >
          <div className="text-one-line">{t('home.location_filter.allLocation')}</div>
        </LessAwesomeButton>
      </Col>
    </Row>
  );
};

FilterButton.defaultProps = {
  filter: undefined,
  colResponsive: {
    span: 24,
  },
};

export default FilterButton;
