import CustomButton from '@source/components/CustomButton/CustomButton';
import React, { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

export interface AwesomeButtonProps {
  type?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  customStyles?: CSSObject;
  onClick?: () => void;
  buttonText?: string;
  size?: 'large' | 'small';
}

const StyledButton = styled(CustomButton)<AwesomeButtonProps>`
  font-size: 14px;
  outline: none;
  color: white;

  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &.awesome-primary,
  &.awesome-outline {
    background-color: ${(props) => props.theme.color.background};
    color: ${(props) => props.theme.color.gray};
    border: 1px solid ${(props) => props.theme.color.gray};
    box-shadow: none;
    ${(props) => props.theme.typo.familyGoogle.regular};

    &:hover,
    &:active,
    &:focus {
      border: 1px solid black;
      color: black;
      transition: all 0.4s ease;
    }
  }

  &.awesome-outline-active {
    border: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
    background-color: ${(props) => props.theme.color.background};
  }

  &.awesome-active {
    background: ${(props) => props.theme.color.primaryBase};
    box-shadow: none
    color: white;
    border: 1px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }

  &.awesome-clear {
    background-color: ${(props) => props.theme.color.background};
    text-decoration: underline;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.semiBold};

    &:hover {
      background-color: #f0f0f0;
      color: black;
      transition: all 0.4s ease;
    }
  }

  &.awesome-inactive {
    background-color: #d4d4d4;
    color: white;
    box-shadow: none;
    border: none;
    ${(props) => props.theme.typo.familyGoogle.semiBold};

    &:hover {
      background-color: #c7c7c7;
      transition: all 0.4s ease;
    }
  }

  ${(props) => props.customStyles}
`;

const AwesomeButton: FC<AwesomeButtonProps> = ({ type, disabled, style, customStyles, onClick, buttonText, size }) => (
  <StyledButton
    size={size}
    style={style}
    disabled={disabled}
    customStyles={customStyles}
    className={`awesome-${type}`}
    onClick={onClick}
  >
    {buttonText}
  </StyledButton>
);

AwesomeButton.defaultProps = {
  buttonText: '',
  type: '',
  disabled: false,
  style: undefined,
  customStyles: undefined,
  onClick: undefined,
  size: 'large',
};

export default AwesomeButton;
