/* eslint-disable import/prefer-default-export */
import { getStaticCDN } from '@design-system/utils/utils';

export const EXCLUSIVE_LABELS = {
  title: 'home.exclusiveLabels.title',
  subTitle: 'home.exclusiveLabels.subTitle',
  tooltip: 'home.exclusiveLabels.tooltip',
  mainImage: getStaticCDN('/assets/shared/home/car_star.svg'),
  content: [
    {
      name: 'home.exclusiveLabels.agentServiced.name',
      image: getStaticCDN('/assets/shared/exclusive-labels/agent.svg'),
      description: 'home.exclusiveLabels.agentServiced.description',
    },
    {
      name: 'home.exclusiveLabels.agentWarranty.name',
      image: getStaticCDN('/assets/shared/exclusive-labels/agent.svg'),
      description: 'home.exclusiveLabels.agentWarranty.description',
    },
    {
      name: 'home.exclusiveLabels.noPHVHistory.name',
      image: getStaticCDN('/assets/shared/exclusive-labels/no_PHV_history.svg'),
      description: 'home.exclusiveLabels.noPHVHistory.description',
    },
    {
      name: 'home.exclusiveLabels.mileageVerified.name',
      image: getStaticCDN('/assets/shared/exclusive-labels/mileage_verified.svg'),
      description: 'home.exclusiveLabels.mileageVerified.description',
    },
    {
      name: 'home.exclusiveLabels.carroTestOwnReady.name',
      image: getStaticCDN('/assets/shared/exclusive-labels/carro_test_own_ready.svg'),
      description: 'home.exclusiveLabels.carroTestOwnReady.description',
    },
    {
      name: 'home.exclusiveLabels.accidentHistoryReport.name',
      image: getStaticCDN('/assets/shared/exclusive-labels/accident_history_report.svg'),
      description: 'home.exclusiveLabels.accidentHistoryReport.description',
    },
  ],
};
