import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledHistoryItemWrapper = styled.span`
  display: flex;
  margin-bottom: 10px;
  flex-flow: wrap;
`;

const StyledHistoryItem = styled.div`
  ${(props) => props.theme.typo.style.smallButtonOrLink}

  background: #E7E7E7;
  border-radius: 8px;
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 0px;
`;

const StyledSeeMoreBtnWrapper = styled.div``;

const StyledSeeMoreBtn = styled(Button)`
  font-size: 14px;
  ${(props) => props.theme.typo.familyGoogle.semiBold}
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.theme.color.background};
  border: none;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
  border-radius: 12px;
  padding: 10px 20px;
  height: 42px;

  &:hover {
    border-color: ${(props) => props.theme.color.primaryHover};
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

type HomeSearchHistoryItems = {
  options: string[];
  onSelected: (item: string) => void;
  numberOfShownItems?: number;
  header?: string;
  onClear?: () => void;
};

// Todo: Refactor later.
const HomeSearchHistoryItems: FC<HomeSearchHistoryItems> = ({
  header,
  onClear,
  options = [],
  onSelected,
  numberOfShownItems = 10,
}) => {
  const { t } = useTranslation();
  const [shownItems, setShownItems] = useState<string[]>([]);
  const [isShowSeeMore, setIsShowSeeMore] = useState<boolean>(
    !!(numberOfShownItems && numberOfShownItems > 0 && numberOfShownItems < options.length),
  );
  useEffect(() => {
    if (numberOfShownItems && numberOfShownItems < options.length) {
      setShownItems(options.slice(0, numberOfShownItems));
      setIsShowSeeMore(true);
    } else {
      setShownItems(options);
    }
    // Only care about number of options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length]);

  const handleClickSeeMore = () => {
    setShownItems(options);
    setIsShowSeeMore(false);
  };

  const renderOptions = () => (
    <StyledHistoryItemWrapper>
      {shownItems.map((item) => (
        <StyledHistoryItem key={item} onClick={() => onSelected(item)}>
          {item}
        </StyledHistoryItem>
      ))}
    </StyledHistoryItemWrapper>
  );

  const renderClearBtn = () => <DeleteOutlined onClick={onClear} />;

  const renderHeader = () => (
    <div className="search-overlay-title">
      <span style={{ marginRight: '5px' }}>{header}</span>
      <span>{onClear && renderClearBtn()}</span>
    </div>
  );

  return (
    <>
      {renderHeader()}
      <div className="search-overlay-filter-wrapper popular-search-item-wrapper">{renderOptions()}</div>
      {isShowSeeMore && (
        <StyledSeeMoreBtnWrapper>
          <StyledSeeMoreBtn onClick={handleClickSeeMore}>{t('home.search.seeMore')}</StyledSeeMoreBtn>
        </StyledSeeMoreBtnWrapper>
      )}
    </>
  );
};

HomeSearchHistoryItems.defaultProps = {
  numberOfShownItems: 10,
  header: undefined,
  onClear: () => {},
};

export default HomeSearchHistoryItems;
