/* eslint-disable @typescript-eslint/no-empty-function */
import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from '@design-system/components';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.color.linkLowEmphasis};
  border-radius: 8px;

  cursor: pointer;
  display: flex;
  padding: 5px;
  padding: 6px 8px;
  height: 32px;
`;

const StyledTypography = styled(Typography)`
  ${(props) => props.theme.color.smallButtonOrLink};
  color: ${(props) => props.theme.color.linkHighEmphasis};
  ${(props) => props.theme.typo.familyGoogle.semiBold};
`;

const StyledCloseIcon = styled(CloseCircleOutlined)`
  font-size: 12px;
  cursor: pointer;
  color: ${(props) => props.theme.color.linkHighEmphasis};
  margin-left: 11px;
`;

export interface FilterTagProps {
  label: string | ReactNode;
  onClick?: () => void;
}

const FilterTag: FC<FilterTagProps> = ({ label, onClick }) => (
  <StyledWrapper onClick={onClick}>
    <StyledTypography>{label}</StyledTypography>
    <StyledCloseIcon />
  </StyledWrapper>
);

FilterTag.defaultProps = {
  onClick: () => {},
};

export default FilterTag;
