import { Input } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div<{ isSearchWindowOpen: boolean }>`
  position: relative;
  z-index: 6;
`;

export const StyledInput = styled(Input)`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
  max-width: 100%;
  padding: 10px 12px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  height: 42px;

  &::placeholder {
    font-size: 14px;
  }

  .ant-dropdown {
    width: 100%;
  }

  input {
    min-width: 200px;
  }

  .ant-input-prefix {
    margin-right: 8px;
  }

  .anticon-search {
    svg {
      fill: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    }
  }

  .clear-all-btn-in-search {
    color: ${(props) => props.theme.color.onBackgroundLowEmphasis};
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    }
  }
`;

export const StyledOverlay = styled.div<{ isMobileView: boolean }>`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  height: 100%;
  max-height: ${(props) => (props.isMobileView ? 'initial' : '500px')};
  overflow-y: ${(props) => (props.isMobileView ? 'initial' : 'auto')};
  padding: ${(props) => (props.isMobileView ? '0' : '24px')};
  width: 100%;
  background-color: ${(props) => props.theme.color.background};
  box-shadow: ${(props) =>
    props.isMobileView
      ? 'initial'
      : '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)'};

  .search-overlay-section {
    position: relative;

    .search-overlay-title {
      ${(props) => props.theme.typo.style.smallButtonOrLink}
      font-size: 12px;
      ${(props) => props.theme.typo.familyGoogle.semiBold}
      color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
      margin-bottom: 16px;
    }

    .search-overlay-filter-wrapper {
      font-size: 12px;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }

    .search-suggestion {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .popular-car-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }

    .popular-search {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .popular-search-item-wrapper {
      ${(props) => props.theme.typo.familyGoogle.regular};
      ${(props) => props.theme.typo.style.smallButtonOrLink};
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .swiper {
    overflow: ${(props) => (props.isMobileView ? 'visible' : 'hidden')};
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      font-size: 24px;
      color: ${(props) => props.theme.color.primaryBase};
    }
  }

  .swiper-button-prev {
    left: -6px;
  }

  .swiper-button-next {
    right: -6px;
  }
`;

export const StyledPopularSearch = styled.span`
  ${(props) => props.theme.typo.style.smallButtonOrLink}

  background-color: ${(props) => props.theme.color.surfaceLight};
  border-radius: 8px;
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  cursor: pointer;
  padding: 0 8px;
`;

export const StyledMobileWrapper = styled.div`
  .anticon-close-circle {
    svg {
      fill: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
      font-size: 14px;
    }
  }
`;

export const StyledMobileDrawerContent = styled.div`
  padding: 16px;

  .back-to-home {
    font-size: 12px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    box-shadow: none;
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    margin-bottom: 8px;
    padding: 0;

    .anticon-arrow-left {
      position: relative;
      top: 2px;
    }
  }

  .mobile-search-bar-wrapper {
    font-size: 14px;
    ${(props) => props.theme.typo.familyGoogle.regular};
    align-items: center;
    display: flex;
    gap: 8px;

    .mobile-search-bar-button {
      &:disabled {
        background: ${(props) => props.theme.color.onBackgroundLowEmphasis};
      }
    }

    .ant-btn {
      align-items: center;
      border-radius: 12px;
      background: ${(props) => props.theme.color.primaryBase};
      box-shadow: ${(props) => props.theme.color.boxShadow.primaryFocus};
      display: flex;
      height: 44px;
      justify-content: center;
      width: 44px;

      svg {
        color: ${(props) => props.theme.color.onPrimary};
        font-size: 18px;
      }
    }
  }

  .clear-all {
    display: flex;
    margin-block: 24px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};

    .ant-typography {
      font-size: 12px;
      ${(props) => props.theme.typo.familyGoogle.regular};
      color: #bababa;
    }
    .ant-btn {
      font-size: 12px;
    }
  }

  .mobile-filter-tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;
  }
`;
